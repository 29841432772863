import React from 'react';
import { Form, Input, Select, FormInstance, Divider, Button } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ISTTValues } from '../types';
import { getGCPKeyfile, normalizeFile } from '../../../../../utils/files';
import { UploadButton } from '../../../../../components/UploadButton';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { azureLocations, sttProviderOptions } from '../../../../../constants';
import { LocaleSelector } from '../../../../../components/LocaleSelector';
import { InteractionSettings } from './InteractionSettings';
import { ReactComponent as Trash } from '../../../../../assets/icons/trash.svg';

interface Props {
  initialValues: ISTTValues;
  onSubmit: (
    values: ISTTValues,
    setFields: FormInstance['setFields'],
    resetFields: FormInstance['resetFields'],
  ) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
  isModal?: boolean;
  closeModal?: () => void;
}

const cleanUpFormData = (data: ISTTValues) => {
  if (data.stt_source_type === 'SttSourceAzure') {
    delete data.stt_source_attributes.gcp_keyfile;
  }
  if (data.stt_source_type === 'SttSourceGoogle') {
    delete data.stt_source_attributes.access_key;
    delete data.stt_source_attributes.region;
  }
};

export const SpeechToTextForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
  isModal,
  closeModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal: closeDiscardModal } = useModal(false);
  const { getFieldsValue } = form;

  const handleCancel = () => {
    if (isModal) {
      closeModal();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue())) {
        openModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = async () => {
    const formData = getFieldsValue();
    cleanUpFormData(formData);
    if (formData.stt_source_attributes.gcp_keyfile) {
      formData.stt_source_attributes.gcp_keyfile = await getGCPKeyfile(
        formData.stt_source_attributes.gcp_keyfile,
      );
    } else if (initialValues.stt_source_attributes.gcp_keyfile) {
      formData.stt_source_attributes.gcp_keyfile = initialValues.stt_source_attributes.gcp_keyfile;
    }

    onSubmit(formData, form.setFields, form.resetFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      name="stt-form"
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('profile_name')} />
      </Form.Item>
      <Form.Item
        name="locale"
        label={t('locale')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <LocaleSelector placeholder={t('select_locale')} platform={'Humanbot'} />
      </Form.Item>
      <Form.Item
        name="stt_source_type"
        label={t('provider')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select placeholder={t('select_provider')} options={sttProviderOptions} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.stt_source_type !== currentValues.stt_source_type ||
          prevValues.stt_source_attributes.gcp_keyfile !==
            currentValues.stt_source_attributes.gcp_keyfile
        }
      >
        {({ getFieldValue }) => {
          const provider = getFieldValue('stt_source_type');
          const gcpKeyfile = getFieldValue(['stt_source_attributes', 'gcp_keyfile']);

          if (
            (provider === 'SttSourceGoogle' &&
              initialValues.stt_source_type === 'SttSourceAzure' &&
              isEditing) ||
            provider === 'SttSourceGoogle'
          ) {
            return gcpKeyfile && gcpKeyfile === initialValues.stt_source_attributes.gcp_keyfile ? (
              <Form.Item label={t('credentials_file')} className={formItemClass.base} required>
                <Button
                  type="default"
                  icon={<Trash width={13} height={14} />}
                  onClick={() => form.setFieldValue(['stt_source_attributes', 'gcp_keyfile'], null)}
                >
                  {t('remove_file', { ns: 'ai' })}
                </Button>
              </Form.Item>
            ) : (
              <Form.Item
                name={['stt_source_attributes', 'gcp_keyfile']}
                label={t('credentials_file')}
                className={formItemClass.base}
                valuePropName="fileList"
                getValueFromEvent={normalizeFile}
                rules={[{ required: true }]}
              >
                <UploadButton accept=".json" />
              </Form.Item>
            );
          }

          if (provider === 'SttSourceAzure') {
            return (
              <>
                <Form.Item
                  name={['stt_source_attributes', 'auth_resource']}
                  label={t('resource')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('enter_resource')} />
                </Form.Item>
                <Space>
                  <Form.Item
                    name={['stt_source_attributes', 'access_key']}
                    label={t('access_key')}
                    className={formItemClass.base}
                    rules={[{ required: true }]}
                  >
                    <PasswordInput placeholder={t('enter_access_key')} />
                  </Form.Item>
                  <Form.Item
                    name={['stt_source_attributes', 'region']}
                    label={t('cloud_region')}
                    className={formItemClass.base}
                    rules={[{ required: true }]}
                  >
                    <Select placeholder={t('select_cloud_region')} options={azureLocations} />
                  </Form.Item>
                </Space>
              </>
            );
          }
        }}
      </Form.Item>
      <InteractionSettings />
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeDiscardModal} />
        </>
      )}
    </Form>
  );
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { AutomationProfilesForm } from '../AutomationProfilesForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IAutomationProfileValues, IAutomationProfile } from '../types';
import { AutomationProfileService } from '../../../../../services/ai/automation-profile';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { extractChannels, extractProfileAttributes, updateValues } from '../utils';

export const AutomationProfilesEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IAutomationProfile>(
    [AutomationProfileService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      AutomationProfileService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [AutomationProfileService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    AutomationProfileService.update,
  );

  const onSubmit = async (
    values: IAutomationProfileValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    updateValues(values, data);
    aiIntegration &&
      updateItem(
        { id, apiData: aiIntegration, data: values },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('automation_profile_updated_notify', {
                    name: response.automation_profile_config.name,
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const errors = err.response.data.errors;
              const hasChannelError = errors.some(
                (error: any) => 'automation_profile.inbound_phone_numbers.phone_number' in error,
              );
              const hasProfileNameError = errors.some(
                (error: any) => 'automation_profile.name' in error,
              );
              if (hasChannelError) notification.error({ message: t('channel_error') });
              if (hasProfileNameError)
                setTimeout(
                  () => setFields(getFormErrors({ name: errors[0]['automation_profile.name'] })),
                  100,
                );
              else {
                const formattedErrors = formatApiErrors(
                  Object.assign({}, ...err.response.data.errors),
                );
                setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
              }
            } else notification.error(unexpectedError);
          },
        },
      );
  };

  if (!data) return <Content imgBg={false} />;

  const { name, automation_profile_type } = data;
  const initialValues: IAutomationProfileValues = {
    name,
    automation_profile_type,
    automation_profile_attributes: {
      ...extractProfileAttributes(data, automation_profile_type),
      inbound_phone_numbers_attributes: extractChannels(data, automation_profile_type),
    },
  };

  return (
    <Content imgBg={false}>
      <AutomationProfilesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        isEditing
      />
    </Content>
  );
};

import React from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { SpeechToTextForm } from '../SpeechToTextForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { SpeechToTextService } from '../../../../../services/ai/speech-to-text';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { ISpeechToTextConfig, ISTTValues } from '../types';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { Paths } from '../../../../../types/paths';

export const SpeechToTextEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<ISpeechToTextConfig>(
    [SpeechToTextService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      SpeechToTextService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading } = useUpdate(
    [SpeechToTextService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    SpeechToTextService.update,
  );

  const onSubmit = async (
    data: ISTTValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { apiData: aiIntegration, data, id },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('stt_profile_updated_notify', { name: response.speech_to_text_config.name })}
                </Trans>
              ),
            });
            navigate(Paths.aiSpeechToText());
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues = {
    name: data.name,
    locale: data.locale,
    stt_source_type: data.stt_source_type,
    stt_source_attributes: {
      bargein: data.stt_source.bargein,
      complete_timeout: data.stt_source.complete_timeout,
      timeout: data.stt_source.timeout,
      interdigit_timeout: data.stt_source.interdigit_timeout,
      maxspeech_timeout: data.stt_source.maxspeech_timeout,
      access_key: data.stt_source.access_key,
      region: data.stt_source.region,
      auth_resource: data.stt_source.auth_resource,
      gcp_keyfile: data.stt_source.gcp_keyfile,
    },
  };

  return (
    <Content imgBg={false}>
      <SpeechToTextForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
        isEditing
      />
    </Content>
  );
};

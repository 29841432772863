import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { SpeechToTextService } from '../../../../../services/ai/speech-to-text';
import { ISpeechToTextConfig } from '../types';
import { Paths } from '../../../../../types/paths';
import { sttProviderOptions } from '../../../../../constants';

export const SpeechToTextList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiSpeechToTextEdit({ id })),
  });

  const columns: ColumnProps<ISpeechToTextConfig>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('provider'),
      onCell: onCellHandler,
      dataIndex: 'stt_source_type',
      sorter: (a, b) => a.stt_source_type.localeCompare(b.stt_source_type),
      render: record =>
        sttProviderOptions.find(provider => provider.value === record)?.label || '-',
      width: '30%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<ISpeechToTextConfig>
      service={SpeechToTextService}
      columns={columns}
      getCreatePath={Paths.aiSpeechToTextCreate}
      deleteConfirmationTitle={t('delete_profile')}
      getDisplayName={record => record.name}
    />
  );
};

import React, { useEffect, useState, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip } from 'antd';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-circle.svg';
import { StyledHeader } from './Header.styles';
import { Projects } from './Projects';
import { Agents } from './Agents';
import { titleMap } from './header.constants';
import { Search } from './Search';
import { ProfileMenu } from './ProfileMenu';
import { isPartPathname } from './utils';
import { Settings } from './Settings';
import { Accounts } from './Accounts';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { headerSearch, profileMenu, agents } = useFlags();
  const [title, setTitle] = useState<ReactNode>('');
  const [introPath, setIntroPath] = useState<string>('');

  useEffect(() => {
    const timeoutId = setTimeout(updateTitleInfo, 50);
    return () => clearTimeout(timeoutId);
  }, [pathname]);

  const updateTitleInfo = () => {
    const curTitle = titleMap.find(
      item => item.path === pathname || isPartPathname(pathname, item.path),
    );

    setTitle(curTitle?.title || '');
    setIntroPath(curTitle?.introPath && pathname !== curTitle.introPath ? curTitle.introPath : '');
  };

  const handleClick = () => {
    navigate(introPath, { replace: true });
  };

  return (
    <StyledHeader.Root>
      <StyledHeader.Title>
        <h1>{title ? title : ''}</h1>
        {introPath && (
          <StyledHeader.IntroIcon onClick={handleClick}>
            <Tooltip placement="right" title={t('introduction')}>
              <QuestionIcon width={14} height={14} />
            </Tooltip>
          </StyledHeader.IntroIcon>
        )}
      </StyledHeader.Title>
      <StyledHeader.Controls>
        {headerSearch && <Search />}
        <Accounts />
        <Projects />
        {agents && <Agents />}
        <Settings />
        {profileMenu && <ProfileMenu />}
      </StyledHeader.Controls>
    </StyledHeader.Root>
  );
};

import { createRef } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Button, Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { KNOWLEDGE_INTRO } from '../../../constants';
import { ReactComponent as NextIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as LastSlideIcon } from '../../../assets/icons/arrow-right.svg';
import singleSourceTruthImg from '../../../assets/images/single-source.png';
import brandManagementImg from '../../../assets/images/brands-management.png';
import contentManagementImg from '../../../assets/images/real-time-insights.png';
import { useModal } from '../../../hooks/useModal';
import { StyledIntroModal } from './IntroModal.styles';

const data = [
  {
    img: singleSourceTruthImg,
    title: 'introduction.single_source_truth_title',
    text: 'introduction.single_source_truth_text',
  },
  {
    img: brandManagementImg,
    title: 'introduction.brand_management_title',
    text: 'introduction.brand_management_text',
  },
  {
    img: contentManagementImg,
    title: 'introduction.content_management_title',
    text: 'introduction.content_management_text',
  },
];

export const IntroModal: React.FC = () => {
  const { t } = useTranslation('knowledge');
  const { visible, closeModal } = useModal(!localStorage.getItem(KNOWLEDGE_INTRO));
  const carouselRef = createRef<CarouselRef>();

  const handleCancel = () => {
    localStorage.setItem(KNOWLEDGE_INTRO, 'false');
    closeModal();
  };

  return (
    <StyledIntroModal.Modal
      open={visible}
      onCancel={handleCancel}
      centered
      footer={null}
      destroyOnClose={true}
      width="80%"
      styles={{ body: { padding: 0 } }}
    >
      <Carousel dots={{ className: 'circle-dots' }} ref={carouselRef}>
        {data.map((item, index) => (
          <StyledIntroModal.Card
            key={item.title}
            bordered={false}
            cover={
              <StyledIntroModal.Image>
                <img alt={t(item.title)} src={item.img} />
              </StyledIntroModal.Image>
            }
            bodyStyle={{ flex: '1 0', padding: '30px 40px' }}
          >
            <StyledIntroModal.Content>
              <StyledIntroModal.Subtitle>{t('introduction.subtitle')}</StyledIntroModal.Subtitle>
              <StyledIntroModal.Title>{t(item.title)}</StyledIntroModal.Title>
              <StyledIntroModal.Text>{t(item.text)}</StyledIntroModal.Text>
              <StyledIntroModal.Navigation>
                {index > 0 && (
                  <Button
                    onClick={() => {
                      carouselRef.current?.prev();
                    }}
                    icon={<BackIcon height={14} />}
                  >
                    <span>
                      <Translation ns={'translation'}>{t => t('back')}</Translation>
                    </span>
                  </Button>
                )}
                {index === data.length - 1 ? (
                  <Button type="primary" onClick={handleCancel}>
                    {t('introduction.get_started')}
                    <LastSlideIcon height={14} />
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      carouselRef.current?.next();
                    }}
                  >
                    <span>
                      <Translation ns={'translation'}>{t => t('next')}</Translation>
                    </span>
                    <NextIcon height={14} />
                  </Button>
                )}
              </StyledIntroModal.Navigation>
              <StyledIntroModal.SkipButton type="link" onClick={handleCancel}>
                <Translation ns={'translation'}>{t => t('skip')}</Translation>
              </StyledIntroModal.SkipButton>
            </StyledIntroModal.Content>
          </StyledIntroModal.Card>
        ))}
      </Carousel>
    </StyledIntroModal.Modal>
  );
};

import { useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { ConversationProfilesSelector } from '../../../../../../components/ConversationProfilesSelector';
import { DLPTemplateSelector } from '../../../../../../components/DLPTemplateSelector';
import { ContextsSelector } from '../../../../../../components/ContextsSelector';
import { FallbackBehaviourSelector } from '../../../../../../components/FallbackBehaviourSelector';
import { NLUAgentSelector } from '../../../../../../components/NLUAgentSelector';
import { ContextModal } from '../../../Contexts/ContextModal';
import { FallbackBehaviourModal } from '../../../../Configuration/FallbackBehaviour/FallbackBehaviourModal';
import { NLUProvidersModal } from '../../../NLUProviders/NLUProvidersModal';
import { IntegrationType } from '../../types';
import { DFAgentType, INLUAgent } from '../../../NLUProviders/types';
import { normalizeToNull } from '../../../../../../utils/ai';
import { hasUUID } from '../../../NLUProviders/utils';

interface Props {
  type: IntegrationType;
  platform: DFAgentType;
  selectedLocales: { ES?: string; CX?: string };
}

export const AutomationConfig = ({ type, platform, selectedLocales }: Props): JSX.Element => {
  const { t } = useTranslation(['ai', 'project_settings']);
  const [isVisibleContextModal, setIsVisibleContextModal] = useState<boolean>(false);
  const [isVisibleBehaviourModal, setIsVisibleBehaviourModal] = useState<boolean>(false);
  const [isVisibleNLUModal, setIsVisibleNLUModal] = useState<boolean>(false);
  const { getFieldValue, setFieldValue, setFieldsValue } = Form.useFormInstance();

  const setNewFallbackBehaviour = (id: number) => {
    setFieldValue(['automation_profile_attributes', 'fallback_config_id'], id);
  };

  const setNewContext = (id: number) => {
    const contexts = getFieldValue(['automation_profile_attributes', 'context_ids']) || [];
    contexts.push(id);
    setFieldValue(['automation_profile_attributes', 'context_ids'], contexts);
  };

  const setNewNLU = (nlu: INLUAgent) => {
    setFieldValue(['automation_profile_attributes', 'dialogflow_agent_id'], nlu.agent_source?.id);
    if (nlu.agent_source_type === 'DialogflowAgent') {
      setFieldsValue({
        automation_profile_attributes: {
          platform_edition: hasUUID(nlu.agent_source) ? 'CX' : 'ES',
          locale: selectedLocales[hasUUID(nlu.agent_source) ? 'CX' : 'ES'],
        },
      });
    }
  };

  return (
    <>
      <Space>
        {type === 'standard' && (
          <>
            <Form.Item
              name={['automation_profile_attributes', 'dialogflow_agent_id']}
              label={t('nlu_provider', { ns: 'project_settings' })}
              className={formItemClass.base}
              rules={[{ required: true }]}
            >
              <NLUAgentSelector
                openModal={() => setIsVisibleNLUModal(true)}
                placeholder={t('select_provider')}
                platformEdition={platform}
              />
            </Form.Item>
            <NLUProvidersModal
              isVisible={isVisibleNLUModal}
              setIsVisible={setIsVisibleNLUModal}
              setNewNLU={setNewNLU}
              platformEdition={platform}
            />
          </>
        )}
        {type === 'ccai' && (
          <Form.Item
            name={['automation_profile_attributes', 'dialogflow_conversation_profile_id']}
            label={t('conversation_profile')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <ConversationProfilesSelector placeholder={t('select_conversation_profile')} />
          </Form.Item>
        )}
        <Form.Item
          name={['automation_profile_attributes', 'dlp_config_id']}
          label={t('dlp_template')}
          className={formItemClass.base}
          normalize={normalizeToNull}
        >
          <DLPTemplateSelector placeholder={t('select_dlp_template')} allowClear={true} />
        </Form.Item>
      </Space>
      <Space>
        {platform === 'ES' && (
          <>
            <Form.Item
              name={['automation_profile_attributes', 'context_ids']}
              label={t('contexts')}
              className={formItemClass.base}
            >
              <ContextsSelector
                placeholder={t('select_context')}
                openModal={() => setIsVisibleContextModal(true)}
              />
            </Form.Item>
            <ContextModal
              isVisible={isVisibleContextModal}
              setIsVisible={setIsVisibleContextModal}
              setNewContext={setNewContext}
            />
          </>
        )}
        <Form.Item
          name={['automation_profile_attributes', 'fallback_config_id']}
          label={t('fallback_behav')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <FallbackBehaviourSelector
            placeholder={t('select_fallback_behaviour')}
            openModal={() => setIsVisibleBehaviourModal(true)}
          />
        </Form.Item>
        <FallbackBehaviourModal
          isVisible={isVisibleBehaviourModal}
          setIsVisible={setIsVisibleBehaviourModal}
          setNewFallbackBehaviour={setNewFallbackBehaviour}
        />
        {platform === 'CX' && (
          <Form.Item
            name={['automation_profile_attributes', 'dialogflow_environment_uuid']}
            label={t('df_env_id')}
            className={formItemClass.base}
          >
            <Input placeholder={t('enter_id')} />
          </Form.Item>
        )}
      </Space>
    </>
  );
};

import React from 'react';
import { Form, Input, Divider, InputNumber, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IContextParameter, IContextValues } from '../types';
import { TitleWithToolTip } from '../../../../../components/TitleWithToolTip';
import { AddField } from '../../../../../components/AddField';
import { validateMessages } from '../../../../../utils/validation';
import { InputWithRemove } from '../../../../../components/InputWithRemove';
import { isFieldNotUnique, formatParamName, getDuplicateIndexes } from '../../../../../utils/ai';

interface Props {
  initialValues: IContextValues;
  onSubmit: (values: IContextValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isModal?: boolean;
  closeModal?: () => void;
}

export const ContextForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isModal,
  closeModal = () => null,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal: openDiscardModal, closeModal: closeDiscardModal } = useModal(false);

  const handleParamNameChange = async (index: number) => {
    const parameters: IContextParameter[] = form.getFieldValue('parameters');

    try {
      await form.validateFields([['parameters', index, 'name']]);
      const updatedFields = parameters.map((_, i) => ({
        name: ['parameters', i, 'name'],
        errors: [],
      }));
      getDuplicateIndexes(parameters, 'name').forEach(indexes => {
        indexes.forEach(i => {
          updatedFields.push({
            name: ['parameters', i, 'name'],
            errors: [t('name_unique')],
          });
        });
      });
      form.setFields(updatedFields);
    } catch (e) {}
  };

  const handleCancel = () => {
    if (isModal) {
      closeModal();
    } else {
      if (!equals(initialValues, form.getFieldsValue(true))) {
        openDiscardModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = () => {
    const data = form.getFieldsValue(true);
    onSubmit(data, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      validateMessages={validateMessages}
      onFinish={onFinish}
      name="context-form"
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('context_name')} />
        </Form.Item>
        <Form.Item
          name="duration"
          label={t('lifespan')}
          className={formItemClass.base}
          rules={[{ required: true }]}
          tooltip={t('tooltip_lifespan')}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Space>
      <Divider />
      <Space direction="vertical" size={16}>
        <TitleWithToolTip title={t('context_params')} tooltip={t('tooltip_context_params')} large />
        <Form.List name="parameters">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <Space key={field.key}>
                  <Form.Item
                    name={[index, 'name']}
                    label={t('name')}
                    className={formItemClass.base}
                    normalize={formatParamName}
                    rules={[
                      { required: true },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          if (
                            isFieldNotUnique(form.getFieldValue('parameters'), 'name', value, index)
                          ) {
                            throw new Error(t('name_unique'));
                          }
                        },
                      },
                    ]}
                    {...(index === fields.length - 1 && { rootClassName: 'is-shifted-md' })}
                  >
                    <Input
                      onChange={() => handleParamNameChange(index)}
                      placeholder={t('context_param_name_ph')}
                    />
                  </Form.Item>
                  <InputWithRemove
                    name={[index, 'value']}
                    onClick={() => remove(index)}
                    placeholder={t('context_param_value_ph')}
                    label={t('value')}
                  />
                </Space>
              ))}
              <AddField onClick={add} buttonText={t('add_context_param')} />
            </div>
          )}
        </Form.List>
      </Space>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeDiscardModal} />
        </>
      )}
    </Form>
  );
};

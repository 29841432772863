import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../ReportsTable';
import { AnalyticsService } from '../../../../../services/analytics';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';
import { useAnalyticsData } from '../../../../../hooks/useAnalyticsData';

export const ExploreList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { analytics: { externalId: projectId } = {} } = useIntegrations();
  const { data, isLoading } = useAnalyticsData({
    queryKey: AnalyticsService.queryKeyExplores,
    fetchDataFn: AnalyticsService.getExplores,
  });

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('explore')}</UppercaseLinkBold>
      <ReportsTable loading={!!projectId && isLoading} reports={data || []} />
    </Content>
  );
};

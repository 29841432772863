import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { NLUProvidersForm } from '../NLUProvidersForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { AgentSourceType, INLUAgent, INLUAgentValues } from '../types';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { Space } from '../../../../../components/Space';
import { hasUUID, isDialogflowAgent, isHumanbotAgent, isLexAgent } from '../utils';

export const NLUProvidersEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<INLUAgent>(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      NLUProvidersService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    NLUProvidersService.update,
  );

  const onSubmit = async (
    payload: INLUAgentValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    payload.agent_source_attributes.id = data?.agent_source?.id;
    aiIntegration &&
      updateItem(
        { id, apiData: aiIntegration, data: payload },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('nlu_provider_updated_notify', { name: data.nlu_agent.name })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (error: any) => {
            if (error.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...error.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      );
  };

  const getSourceType = (sourceType: AgentSourceType, uuid?: string | null): AgentSourceType => {
    if (sourceType === 'DialogflowAgent') {
      return uuid ? 'CX' : 'ES';
    }
    return sourceType;
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: Omit<INLUAgent, 'id'> = {
    name: data.name,
    agent_source_type: getSourceType(
      data.agent_source_type,
      hasUUID(data.agent_source) ? data.agent_source.uuid : '',
    ),
    agent_source: {
      ...(isDialogflowAgent(data.agent_source) && {
        gcp_keyfile: data.agent_source.gcp_keyfile,
        display_name: data.agent_source.display_name,
        gcp_fulfillment_auth_keyfile: data.agent_source.gcp_fulfillment_auth_keyfile,
        gcp_project_name: data.agent_source.gcp_project_name,
        ccai_enabled: data.agent_source.ccai_enabled ?? false,
        uuid: data.agent_source.uuid,
        location: data.agent_source.location,
      }),
      ...(isLexAgent(data.agent_source) && {
        agent_alias: data.agent_source.agent_alias,
        access_key: data.agent_source.access_key,
        agent_uuid: data.agent_source.agent_uuid,
        secret_access_key: data.agent_source.secret_access_key,
        location: data.agent_source.location,
      }),
      ...(isHumanbotAgent(data.agent_source) && {
        api_key: data.agent_source.api_key,
        url: data.agent_source.url,
      }),
    },
  };

  return (
    <Content imgBg={false}>
      <Space direction="vertical" size="middle">
        <NLUProvidersForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
          isEditing
        />
      </Space>
    </Content>
  );
};

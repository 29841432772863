export const getFieldName = (
  fieldName: string,
  isProjectCreation: boolean,
  isNLUForm?: boolean,
): string => {
  if (isNLUForm) return `agent_source.${fieldName}`;
  return isProjectCreation ? `ai_n_a_attributes[${fieldName}]` : fieldName;
};

export const getFieldPath = (
  baseName: string,
  isProjectCreation: boolean,
  isNLUForm?: boolean,
): string[] => {
  if (isNLUForm) {
    return ['agent_source', baseName];
  }
  return isProjectCreation ? ['ai_n_a_attributes', baseName] : [baseName];
};

import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { PulseMenu } from './PulseMenu';
import { useIntegrations } from '../../state/integrations';
import { Paths } from '../../types/paths';
import { CenteredSpinner } from '../../components/Spinner';

export const Pulse: React.FC = () => {
  const { pulse, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const isIntroPath = pathname === Paths.pulseIntro();
  const isBasePath = pathname === Paths.pulse();

  useEffect(() => {
    if (isProject && !isFetching) {
      if (pulse?.url) {
        setIsEnabled(true);
        navigate(isIntroPath || isBasePath ? Paths.pulseInsights() : pathname, { replace: true });
      } else {
        setIsEnabled(false);
        navigate(Paths.pulseIntro(), { replace: true });
      }
    }
  }, [pulse?.url, isProject, isFetching]);

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject && !isIntroPath) return <NoProject />;

  return (
    <>
      {isEnabled && <PulseMenu />}
      <Outlet />
    </>
  );
};

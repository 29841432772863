import { Form, Slider, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../../../components/Space';
import { formItemClass } from '../../../../../../theme';

export const InteractionSettings: React.FC = () => {
  const { t } = useTranslation('ai');

  return (
    <>
      <Form.Item
        name={['stt_source_attributes', 'bargein']}
        valuePropName="checked"
        label={t('bargein')}
      >
        <Switch />
      </Form.Item>
      <Space>
        <Form.Item
          name={['stt_source_attributes', 'complete_timeout']}
          label={t('complete_timeout')}
          className={formItemClass.base}
        >
          <Slider
            min={0}
            max={10}
            step={0.1}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
        <Form.Item
          name={['stt_source_attributes', 'timeout']}
          label={t('timeout')}
          className={formItemClass.base}
        >
          <Slider
            min={1}
            max={10}
            step={0.5}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['stt_source_attributes', 'interdigit_timeout']}
          label={t('interdigit_timeout')}
          className={formItemClass.base}
        >
          <Slider
            min={1}
            max={10}
            step={0.5}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
        <Form.Item
          name={['stt_source_attributes', 'maxspeech_timeout']}
          label={t('maxspeech_timeout')}
          className={formItemClass.base}
        >
          <Slider
            min={5}
            max={60}
            step={1}
            tooltip={{
              open: true,
              getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
            }}
          />
        </Form.Item>
      </Space>
    </>
  );
};

import { useState, Dispatch, SetStateAction } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Form, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { LocaleSelector } from '../../../../../../components/LocaleSelector';
import { TextToSpeechSelector } from '../../../../../../components/TextToSpeechSelector';
import { TextToSpeechModal } from '../../../TextToSpeech/TextToSpeechModal';
import { speechModelOptions } from '../../../../../../constants';
import { SpeechToTextSelector } from '../../../../../../components/SpeechToTextSelector';
import { DFAgentType } from '../../../NLUProviders/types';
import { useAccount } from '../../../../../../state/account';
import { TextToSpeechList } from './TextToSpeechList';
import { ITTSConfig } from '../../../TextToSpeech/types';
import { SpeechToTextModal } from '../../../SpeechToText/SpeechToTextModal';
import { normalizeToNull } from '../../../../../../utils/ai';

const { Title } = Typography;

export const AudioConfig = ({
  platform,
  setSelectedLocales,
}: {
  platform?: DFAgentType;
  setSelectedLocales?: Dispatch<SetStateAction<{ ES?: string; CX?: string }>>;
}): JSX.Element => {
  const { t } = useTranslation('ai');
  const { audioConfig } = useFlags();
  const { isHumanbotProject } = useAccount();
  const { setFieldValue } = Form.useFormInstance();
  const [isVisibleTTSModal, setIsVisibleTTSModal] = useState<boolean>(false);
  const [isVisibleSTTModal, setIsVisibleSTTModal] = useState<boolean>(false);

  const setNewTTS = (tts: ITTSConfig) => {
    setFieldValue(['automation_profile_attributes', 'text_to_speech_config_id'], tts.id);
  };

  const setNewSTT = (id: number) => {
    setFieldValue(['automation_profile_attributes', 'speech_to_text_config_id'], id);
  };

  const handleLocaleChange = (value: string) => {
    if (platform) {
      setSelectedLocales?.(prev => ({
        ...prev,
        [platform]: value,
      }));
    }
  };

  return (
    <>
      <Title level={5} type="secondary">
        {t('audio_config')}
      </Title>
      {isHumanbotProject ? (
        <>
          <Form.Item
            name={['automation_profile_attributes', 'speech_to_text_config_id']}
            label={t('speech_to_text_profile')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <SpeechToTextSelector
              placeholder={t('select_profile')}
              openModal={() => setIsVisibleSTTModal(true)}
            />
          </Form.Item>
          <SpeechToTextModal
            isVisible={isVisibleSTTModal}
            setIsVisible={setIsVisibleSTTModal}
            setNewSTT={setNewSTT}
          />
          <TextToSpeechList />
        </>
      ) : (
        <Space>
          <Form.Item
            name={['automation_profile_attributes', 'locale']}
            label={t('locale')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <LocaleSelector
              placeholder={t('select_locale')}
              platform={platform}
              onChange={handleLocaleChange}
            />
          </Form.Item>
          <Form.Item
            name={['automation_profile_attributes', 'text_to_speech_config_id']}
            label={t('text_to_speech_profile')}
            className={formItemClass.base}
            normalize={normalizeToNull}
          >
            <TextToSpeechSelector
              placeholder={t('select_profile')}
              openModal={() => setIsVisibleTTSModal(true)}
              allowClear={true}
            />
          </Form.Item>
          <TextToSpeechModal
            isVisible={isVisibleTTSModal}
            setIsVisible={setIsVisibleTTSModal}
            setNewTTS={setNewTTS}
          />
        </Space>
      )}
      {audioConfig && !isHumanbotProject && (
        <Form.Item
          name={['automation_profile_attributes', 'audio_config_attributes', 'speech_model']}
          label={t('speech_model')}
          className={formItemClass.base}
          normalize={normalizeToNull}
        >
          <Select
            placeholder={t('select_speech_model')}
            options={speechModelOptions}
            allowClear={true}
          />
        </Form.Item>
      )}
    </>
  );
};

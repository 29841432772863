import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { KnowledgeMenu } from './KnowledgeMenu';
import { IntroModal } from './IntroModal';
import { useIntegrations } from '../../state/integrations';
import { Paths } from '../../types/paths';
import { CenteredSpinner } from '../../components/Spinner';

export const Knowledge: React.FC = () => {
  const { knowledge, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const isIntroPath = pathname === Paths.knowledgeIntro();
  const isBasePath = pathname === Paths.knowledge();

  useEffect(() => {
    if (isProject && !isFetching) {
      if (knowledge?.url) {
        setIsEnabled(true);
        navigate(isIntroPath || isBasePath ? Paths.knowledgePresentations() : pathname, {
          replace: true,
        });
      } else {
        setIsEnabled(false);
        navigate(Paths.knowledgeIntro(), { replace: true });
      }
    }
  }, [knowledge?.url, isProject, isFetching]);

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject && !isIntroPath) return <NoProject />;
  return (
    <>
      {isEnabled && <KnowledgeMenu />}
      <Outlet />
      <IntroModal />
    </>
  );
};

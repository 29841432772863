import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { AutomationProfilesForm } from '../AutomationProfilesForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { AutomationProfileTypes, IAutomationProfileValues } from '../types';
import { AutomationProfileService } from '../../../../../services/ai/automation-profile';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { useAccount } from '../../../../../state/account';
import useProjectLoading from '../../../../../hooks/useProjectLoading';
import { CenteredSpinner } from '../../../../../components/Spinner';

export const AutomationProfilesNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const { isHumanbotProject } = useAccount();
  const isProjectLoading = useProjectLoading();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [AutomationProfileService.queryKey],
    AutomationProfileService.create,
  );

  const onSubmit = async (
    data: IAutomationProfileValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: response => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('automation_profile_created_notify', {
                  name: response.automation_profile_config.name,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const errors = err.response.data.errors;
            const hasChannelsError = errors.some(
              (error: any) => 'automation_profile.inbound_phone_numbers.phone_number' in error,
            );
            const hasProfileNameError = errors.some(
              (error: any) => 'automation_profile.name' in error,
            );
            if (hasChannelsError) notification.error({ message: t('channel_error') });
            if (hasProfileNameError)
              setTimeout(
                () => setFields(getFormErrors({ name: errors[0]['automation_profile.name'] })),
                100,
              );
            else {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            }
          } else notification.error(unexpectedError);
        },
      },
    );
  };

  if (isProjectLoading) return <CenteredSpinner isOverlay={true} isVisible={isProjectLoading} />;

  const initialValues: IAutomationProfileValues = {
    name: '',
    automation_profile_type: isHumanbotProject
      ? AutomationProfileTypes.humanbot
      : AutomationProfileTypes.dialPlan,
    automation_profile_attributes: {
      integration_type: 'standard',
      platform_edition: 'ES',
      locale: 'en-GB',
      product: 'email',
      humanbot_tts_configs_attributes: [{ locale: undefined, text_to_speech_config_id: undefined }],
    },
  };

  return (
    <Content imgBg={false}>
      <AutomationProfilesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Form, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { AutomationProfileService } from '../../services/ai/automation-profile';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { ChannelType } from '../../pages/AI/Channels/Channel/types';
import { isSupportChannel } from '../../pages/AI/Automation/AutomationProfiles/utils';
import { IAutomationProfile } from '../../pages/AI/Automation/AutomationProfiles/types';

interface Props extends Omit<SelectProps<number>, 'onChange'> {
  channelType: ChannelType;
  onProfileSelect?: (
    profile?: Pick<IAutomationProfile, 'automation_profile_type' | 'automation_profile_id'>,
  ) => void;
}

type OptionType = DefaultOptionType &
  Pick<IAutomationProfile, 'automation_profile_type' | 'automation_profile_id'>;

export const AutomationProfileSelector: React.FC<Props> = ({
  channelType,
  onProfileSelect,
  value,
  ...props
}) => {
  const { aiIntegration } = useIntegrations();
  const { getFieldValue } = Form.useFormInstance();
  const { data } = useQuery(
    [AutomationProfileService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      AutomationProfileService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const filteredOptions = useMemo(() => {
    if (!data) return [];
    return data
      .filter(item => isSupportChannel(item.automation_profile_type, channelType))
      ?.map(item => ({
        label: item.name,
        value: item.id,
        automation_profile_id: item.automation_profile_id,
        automation_profile_type: item.automation_profile_type,
      }));
  }, [data, channelType]);

  const selectedValue = useMemo(() => {
    const profileType = getFieldValue('dial_plan_type');
    return filteredOptions?.find(
      item => item.automation_profile_type === profileType && item.automation_profile_id === value,
    )?.value;
  }, [filteredOptions, value]);

  const handleOnChange: SelectProps<number, OptionType>['onChange'] = (_, option) => {
    if (!Array.isArray(option) && onProfileSelect) {
      onProfileSelect({
        automation_profile_id: option.automation_profile_id,
        automation_profile_type: option.automation_profile_type,
      });
    }
  };

  return (
    <Select {...props} options={filteredOptions} value={selectedValue} onChange={handleOnChange} />
  );
};

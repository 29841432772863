import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { RuleType } from 'rc-field-form/lib/interface';
import {
  AutomationProfileType,
  AutomationProfileTypes,
} from '../../../../Automation/AutomationProfiles/types';
import { ChannelType } from '../../types';
import { useAccount } from '../../../../../../state/account';
import { channelIdentifiers } from '../../../../../../constants';
import { IncomingPhoneNumberSelector } from '../../../../../../components/IncomingPhoneNumberSelector';
import { formItemClass } from '../../../../../../theme';

export const IdentifierField: React.FC = () => {
  const { t } = useTranslation('ai');
  const { isHumanbotProject } = useAccount();
  const { getFieldValue } = Form.useFormInstance();
  const channelType: ChannelType = getFieldValue('channel_type');
  const profileType: AutomationProfileType = getFieldValue('dial_plan_type');

  return (
    <Form.Item
      name="phone_number"
      label={t('identifier')}
      tooltip={t(channelIdentifiers[channelType].tooltip)}
      className={formItemClass.base}
      rules={[
        { required: true },
        ...(channelType === 'email' ? [{ type: 'email' as RuleType }] : []),
      ]}
      validateTrigger="onBlur"
    >
      {channelType === 'web' ||
      channelType === 'email' ||
      channelType === 'facebook' ||
      (channelType === 'voice' && profileType !== AutomationProfileTypes.dialPlan) ||
      isHumanbotProject ? (
        <Input placeholder={t(channelIdentifiers[channelType].placeholder)} />
      ) : (
        <IncomingPhoneNumberSelector
          placeholder={t(channelIdentifiers[channelType].placeholder)}
          channelType={channelType}
        />
      )}
    </Form.Item>
  );
};

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { InboundPhoneNumbersService } from '../../../../../services/ai/inbound-phone-numbers';
import { IChannels, ChannelTypes } from '../types';
import { snakeToCapitalCase } from '../../../../../utils/string';
import { Paths } from '../../../../../types/paths';
import { useColumnSearchProps } from '../../../../../hooks/useColumnSearchProps';
import { getHighlightedText } from '../../../../../utils/formatText';
import { AutomationProfileTypes } from '../../../Automation/AutomationProfiles/types';

const getAutomationProfileName = (record: IChannels): string => {
  const { dial_plan_type, dial_plan, automation_profile_agentic, automation_profile_humanbot } =
    record;
  switch (dial_plan_type) {
    case AutomationProfileTypes.dialPlan:
      return dial_plan?.automation_profile_config?.name ?? '';
    case AutomationProfileTypes.agentic:
      return automation_profile_agentic?.automation_profile_config?.name ?? '';
    case AutomationProfileTypes.humanbot:
      return automation_profile_humanbot?.automation_profile_config?.name ?? '';
    default:
      return '';
  }
};

export const ChannelList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { channel } = useParams() as { channel: string };
  const [searchIdentifier, setSearchIdentifier] = useState('');

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiChannelEdit({ channel, id })),
  });

  const columns: ColumnProps<IChannels>[] = [
    {
      title: t('identifier'),
      onCell: onCellHandler,
      dataIndex: 'phone_number',
      ...useColumnSearchProps('phone_number', t('search_identifier'), setSearchIdentifier),
      sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
      render: text => (searchIdentifier ? getHighlightedText(text, searchIdentifier) : text),
      fixed: 'left',
      width: channel === ChannelTypes.all ? '52%' : '67%',
    },
    {
      title: t('automation_profile'),
      onCell: onCellHandler,
      sorter: (a, b) => getAutomationProfileName(a).localeCompare(getAutomationProfileName(b)),
      width: channel === ChannelTypes.all ? '25%' : '30%',
      render: (_, record) => getAutomationProfileName(record),
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  if (channel === ChannelTypes.all) {
    columns.splice(1, 0, {
      title: t('type'),
      onCell: onCellHandler,
      dataIndex: 'channel_type',
      sorter: (a, b) => a.channel_type.localeCompare(b.channel_type),
      width: '20%',
      render: record =>
        record === ChannelTypes.facebook
          ? snakeToCapitalCase(ChannelTypes.messenger)
          : snakeToCapitalCase(record),
    });
  }

  return (
    <AiFeatureList<IChannels>
      service={InboundPhoneNumbersService}
      columns={columns}
      getCreatePath={() => Paths.aiChannelCreate({ channel })}
      getDisplayName={record => record.phone_number}
      getChannelName={record => record.channel_type}
      channelType={channel}
    />
  );
};

import {
  AutomationProfileType,
  IAgentic,
  IDialPlan,
  IHumanBot,
} from '../../Automation/AutomationProfiles/types';

export type ChannelType = 'facebook' | 'sms' | 'voice' | 'web' | 'teams' | 'whatsapp' | 'email';

export interface IChannels {
  id: number;
  phone_number: string;
  channel_type: ChannelType;
  dial_plan_id: number;
  dial_plan_type: AutomationProfileType;
  dial_plan?: IDialPlan;
  automation_profile_agentic?: IAgentic;
  automation_profile_humanbot?: IHumanBot;
}

export interface IChannelsValues {
  id?: number;
  channel_type?: ChannelType;
  phone_number?: string;
  dial_plan_id?: number;
  dial_plan_type?: AutomationProfileType;
  _destroy?: boolean;
}

export enum ChannelTypes {
  facebook = 'facebook',
  messenger = 'messenger',
  sms = 'sms',
  voice = 'voice',
  teams = 'teams',
  web = 'web',
  whatsapp = 'whatsapp',
  email = 'email',
  all = 'all',
}

import React from 'react';
import { Form, Input, Switch, Select, FormInstance, Divider, Typography } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { IRouterValues, RoutingMethods } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { Space } from '../../../../../components/Space';
import { RoutingMethodSelector } from '../../../../../components/RoutingMethodSelector';
import { TeamSelector } from '../../../../../components/TeamSelector';
import { RouterProtolcolTypes } from '../../../../../constants';
import { objectToBase64 } from '../../../../../utils/base64';
import { GenesysConfig } from './GenesysConfig';
import { LivechatConfig } from './LivechatConfig';
import { useAccount } from '../../../../../state/account';

const { Title } = Typography;

interface Props {
  initialValues: IRouterValues;
  onSubmit: (values: IRouterValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const RoutersForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { isHumanbotProject } = useAccount();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const { avaya, direct_dial, genesys, livechat, task_router } = RoutingMethods;

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    if (formData.routing_method === genesys) {
      const { integration_id, client_id, client_secret, region } = formData;
      formData.config = objectToBase64({ integration_id, client_id, client_secret, region });
    } else if (formData.routing_method === avaya) {
      formData.protocol = 'sip';
    } else if (formData.routing_method === livechat) {
      const { client_id, organization_id, pat_token } = formData;
      formData.config = objectToBase64({ client_id, organization_id, pat_token });
    }
    if (formData.protocol === 'pstn') {
      formData.sip_refer_enabled = false;
    }
    if (formData.routing_method !== direct_dial && formData.routing_method !== avaya) {
      formData.protocol = null;
      formData.outbound_contact_uri = null;
    }
    if (formData.routing_method !== task_router) {
      formData.team_id = null;
    }
    if (formData.routing_method !== genesys && formData.routing_method !== livechat) {
      formData.config = null;
    }
    [
      'integration_id',
      'client_id',
      'client_secret',
      'region',
      'organization_id',
      'pat_token',
    ].forEach(field => {
      delete formData[field];
    });

    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item name="is_default" valuePropName="checked" label={t('default')}>
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item
        name="routing_method"
        label={t('routing_method')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <RoutingMethodSelector placeholder={t('select_routing_method')} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.routing_method !== currentValues.routing_method
        }
      >
        {({ getFieldValue }) => {
          const routingMethod = getFieldValue('routing_method');
          return (
            <>
              {routingMethod && (
                <>
                  <Divider className={formItemClass.full} />
                  <Title level={5} type="secondary">
                    {t('configuration')}
                  </Title>
                </>
              )}
              {routingMethod === task_router && (
                <Form.Item
                  name="team_id"
                  label={t('team')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                >
                  <TeamSelector placeholder={t('select_team')} />
                </Form.Item>
              )}
              {routingMethod === direct_dial && (
                <>
                  <Space>
                    <Form.Item
                      name="protocol"
                      label={t('protocol')}
                      className={formItemClass.base}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder={t('select_protocol')}
                        options={
                          !!isHumanbotProject
                            ? RouterProtolcolTypes.filter(item => item.label === 'sip')
                            : RouterProtolcolTypes
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="outbound_contact_uri"
                      label={t('outbound_contact_uri')}
                      className={formItemClass.base}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder={t('enter_outbound_contact_uri')} />
                    </Form.Item>
                  </Space>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.protocol !== currentValues.protocol
                    }
                  >
                    {({ getFieldValue }) => {
                      return (
                        <>
                          {getFieldValue('protocol') === 'sip' && (
                            <Form.Item
                              name="sip_refer_enabled"
                              valuePropName="checked"
                              label={t('use_refer')}
                            >
                              <Switch />
                            </Form.Item>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </>
              )}
              {routingMethod === genesys && <GenesysConfig />}
              {routingMethod === avaya && (
                <Form.Item
                  name="outbound_contact_uri"
                  label={t('sip_outb_contact_uri')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                  tooltip={t('tooltip_sip_outb_contact_uri')}
                >
                  <Input placeholder={t('enter_sip_outb_contact_uri')} />
                </Form.Item>
              )}
              {routingMethod === livechat && <LivechatConfig />}
            </>
          );
        }}
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};

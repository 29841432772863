import React from 'react';
import { Divider, Form, Input, FormInstance, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { AutomationProfileTypes, IAutomationProfileValues, ITTSAttributes } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { Channels } from './Channels';
import { autoProfileTypeOptions } from '../../../../../constants';
import { DialPlanConfig } from './DialPlanConfig';
import { HumanbotConfig } from './HumanbotConfig';
import { AgenticConfig } from './AgenticConfig';
import { cleanupProfileData } from '../utils';
import { useAccount } from '../../../../../state/account';

const { agentic, dialPlan, humanbot } = AutomationProfileTypes;

interface Props {
  initialValues: IAutomationProfileValues;
  onSubmit: (values: IAutomationProfileValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
}

export const AutomationProfilesForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { isHumanbotProject } = useAccount();
  const { agenticModels } = useFlags();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const {
    automation_profile_attributes: {
      integration_type,
      humanbot_tts_configs_attributes,
      locale,
      platform_edition,
    },
  } = initialValues;

  const profileTypeOptions = isHumanbotProject
    ? autoProfileTypeOptions.filter(option => option.value === AutomationProfileTypes.humanbot)
    : agenticModels
    ? autoProfileTypeOptions.filter(option => option.value !== AutomationProfileTypes.humanbot)
    : autoProfileTypeOptions.filter(option => option.value === AutomationProfileTypes.dialPlan);

  const handleProfileTypeChange = () => {
    form.setFields([
      { name: ['automation_profile_attributes', 'fallback_config_id'], value: undefined },
      { name: ['automation_profile_attributes', 'inbound_phone_numbers_attributes'], value: [] },
      {
        name: ['automation_profile_attributes', 'humanbot_tts_configs_attributes'],
        value: humanbot_tts_configs_attributes,
      },
      { name: ['automation_profile_attributes', 'text_to_speech_config_id'], value: undefined },
      { name: ['automation_profile_attributes', 'locale'], value: locale },
    ]);
  };

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const handleTextToSpeech = (ttsList: ITTSAttributes[]) =>
    ttsList.map(item => {
      if (item._destroy) {
        return { id: item.id, _destroy: true };
      } else if (item.id) {
        return { id: item.id, text_to_speech_config_id: item.text_to_speech_config_id };
      } else {
        return { text_to_speech_config_id: item.text_to_speech_config_id };
      }
    });

  const onFinish = () => {
    const formData = form.getFieldsValue();
    cleanupProfileData(formData);
    if (formData.automation_profile_type === dialPlan) {
      formData.automation_profile_attributes.name = formData.name;
    }
    if (formData.automation_profile_attributes.humanbot_tts_configs_attributes) {
      formData.automation_profile_attributes.humanbot_tts_configs_attributes = handleTextToSpeech(
        formData.automation_profile_attributes.humanbot_tts_configs_attributes,
      );
    }
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_name')} />
      </Form.Item>
      <Form.Item
        name="automation_profile_type"
        label={t('type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select
          options={profileTypeOptions}
          placeholder={t('select_type')}
          onChange={handleProfileTypeChange}
          disabled={isEditing}
        />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.automation_profile_type !== currentValues.automation_profile_type
        }
      >
        {({ getFieldValue }) => {
          const profileType: IAutomationProfileValues['automation_profile_type'] =
            getFieldValue('automation_profile_type');
          return (
            <>
              {profileType === dialPlan && (
                <DialPlanConfig
                  integrationType={integration_type ?? 'standard'}
                  platform={platform_edition ?? 'ES'}
                  locale={locale}
                />
              )}
              {profileType === agentic && <AgenticConfig />}
              {profileType === humanbot && <HumanbotConfig />}
              <Channels profileType={profileType} />
            </>
          );
        }}
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import workingExpectedImg from '../../../assets/images/working-expected.png';
import monitoringImg from '../../../assets/images/monitoring.png';
import alertingImg from '../../../assets/images/alerting.png';
import cxinsightsImg from '../../../assets/images/cx-insights.png';
import { Content } from '../../../components/Content';
import { StyledIntroCard } from '../../../components/StyledIntroCard';

export const PulseIntro: React.FC = () => {
  const { t } = useTranslation('pulse');

  return (
    <Content imgBg={false} header={false}>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, 20]}>
          <StyledIntroCard.MainSection
            img={workingExpectedImg}
            title={t('intro_working_title')}
            description={t('intro_working_description')}
            maxHeight={354}
          />
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[0, 20]}>
          <Col span={24}>
            <h3>{t('intro_monitoring_title')}</h3>
            <StyledIntroCard.Paragraph
              maxWidth={1018}
              description={t('intro_monitoring_description')}
            />
          </Col>
          <Col span={24}>
            <img alt={t('intro_monitoring_title')} src={monitoringImg} style={{ maxHeight: 382 }} />
          </Col>
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, { xs: 20, sm: 20, md: 0 }]}>
          <StyledIntroCard.SubSection
            left
            img={alertingImg}
            title={t('intro_alert_title')}
            description={t('intro_alert_description')}
            maxHeight={244}
          />
          <StyledIntroCard.SubSection
            right
            img={cxinsightsImg}
            title={t('intro_insights_title')}
            description={t('intro_insights_description')}
            maxHeight={288}
          />
        </Row>
      </StyledIntroCard.Section>
    </Content>
  );
};

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { ContextsService } from '../../services/ai/contexts';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { SelectWithFooter } from '../SelectWithFooter';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface Props extends SelectProps {
  openModal: () => void;
}

export const ContextsSelector: React.FC<Props> = ({ openModal, ...other }) => {
  const { aiIntegration } = useIntegrations();
  const { t } = useTranslation('ai');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data } = useQuery(
    [ContextsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => ContextsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleNewContext = () => {
    setIsOpen(false);
    openModal();
  };

  const options = data?.map(item => ({
    label: item.name,
    value: item.id,
  }));

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewContext}>
        <PlusIcon width={10} height={10} /> {t('new_context')}
      </SelectWithFooter.Footer>
    </>
  );

  return (
    <Select
      open={isOpen}
      mode="multiple"
      showSearch
      optionFilterProp="label"
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      options={options}
      {...other}
    ></Select>
  );
};

import { omit, pipe } from 'ramda';
import { channelTypeOptions } from '../../../../constants';
import { ChannelType } from '../../Channels/Channel/types';
import {
  AutomationProfileType,
  AutomationProfileTypes,
  IAutomationProfile,
  IAutomationProfileValues,
} from './types';

export const extractProfileAttributes = (data: IAutomationProfile, type: AutomationProfileType) => {
  const { dial_plan, automation_profile_agentic, automation_profile_humanbot } = data;
  const attributesMap = {
    DialPlan: {
      integration_type: dial_plan?.dialogflow_conversation_profile_id ? 'ccai' : 'standard',
      platform_edition: dial_plan?.dialogflow_agent?.platform_edition || 'ES',
      dialogflow_conversation_profile_id: dial_plan?.dialogflow_conversation_profile_id,
      analyze_query_text_sentiment: dial_plan?.analyze_query_text_sentiment,
      dialogflow_agent_id: dial_plan?.dialogflow_agent?.id,
      dlp_config_id: dial_plan?.dlp_config?.id,
      context_ids: dial_plan?.contexts?.map(ctx => ctx.id),
      text_to_speech_config_id: dial_plan?.text_to_speech_config?.id,
      fallback_config_id: dial_plan?.fallback_config?.id,
      locale: dial_plan?.locale,
      audio_config_attributes: {
        speech_model: dial_plan?.audio_config?.speech_model,
      },
      analytic_openai_summary_enabled: dial_plan?.analytic_openai_summary_enabled,
      openai_summary_enabled: dial_plan?.openai_summary_enabled,
      ccai_summary_enabled: dial_plan?.ccai_summary_enabled,
      voice_recording_config_attributes: {
        voice_recording_enabled: dial_plan?.voice_recording_config?.voice_recording_enabled,
        stop_on_escalation: dial_plan?.voice_recording_config?.stop_on_escalation,
      },
      dialogflow_environment_uuid: dial_plan?.dialogflow_environment_uuid,
    },
    AutomationProfileAgentic: {
      agentic_model_id: automation_profile_agentic?.agentic_model?.id,
      product: automation_profile_agentic?.product || 'email',
      ...JSON.parse(automation_profile_agentic?.config || '{}'),
    },
    AutomationProfileHumanbot: {
      humanbot_tts_configs_attributes: automation_profile_humanbot?.humanbot_tts_configs?.map(
        config => ({
          id: config.id,
          locale: automation_profile_humanbot?.text_to_speech_configs?.find(
            item => item.text_to_speech_config.id === config.text_to_speech_config_id,
          )?.locale,
          text_to_speech_config_id: config.text_to_speech_config_id,
        }),
      ) || [{ locale: undefined, text_to_speech_config_id: undefined }],
      speech_to_text_config_id: automation_profile_humanbot?.speech_to_text_config?.id,
      fallback_config_id: automation_profile_humanbot?.fallback_config?.id,
      nlu_agent_id: automation_profile_humanbot?.nlu_agent?.id,
    },
  };
  return attributesMap[type] || {};
};

export const extractChannels = (data: IAutomationProfile, type: AutomationProfileType) => {
  const channelsMap = {
    DialPlan: data.dial_plan?.inbound_phone_numbers,
    AutomationProfileAgentic: data.automation_profile_agentic?.inbound_phone_numbers,
    AutomationProfileHumanbot: data.automation_profile_humanbot?.inbound_phone_numbers,
  };

  return channelsMap[type]?.map(({ id, channel_type, phone_number }) => ({
    id,
    channel_type,
    phone_number,
  }));
};

const prepareAgenticConfig = (
  attributes: IAutomationProfileValues['automation_profile_attributes'],
) => {
  const { company, priority, categories, branding, personalisation } = attributes;
  return {
    ...attributes,
    config: JSON.stringify({
      company: company || '',
      priority: priority || '',
      categories: categories || '',
      branding: branding || '',
      personalisation: personalisation || '',
    }),
  };
};

const cleanupDialPlanAttributes = omit([
  'analyze_query_text_sentiment',
  'dialogflow_agent_id',
  'dialogflow_conversation_profile_id',
  'dlp_config_id',
  'context_ids',
  'dialogflow_environment_uuid',
  'analytic_openai_summary_enabled',
  'openai_summary_enabled',
  'ccai_summary_enabled',
  'locale',
  'audio_config_attributes',
  'voice_recording_config_attributes',
  'text_to_speech_config_id',
  'locale',
]);

const cleanupCommonFields = omit([
  'platform_edition',
  'integration_type',
  'company',
  'categories',
  'priority',
  'branding',
  'personalisation',
]);

export const cleanupProfileData = (formData: IAutomationProfileValues): void => {
  const { agentic, dialPlan, humanbot } = AutomationProfileTypes;
  const { automation_profile_type, automation_profile_attributes } = formData;
  if (!automation_profile_attributes) return;

  let cleanedAttributes = { ...automation_profile_attributes };

  if (cleanedAttributes.integration_type === 'standard') {
    cleanedAttributes.dialogflow_conversation_profile_id = null;
  } else {
    cleanedAttributes.dialogflow_agent_id = null;
  }

  if (automation_profile_type === agentic) {
    cleanedAttributes = pipe(
      prepareAgenticConfig,
      omit(['text_to_speech_config_id', 'fallback_config_id']),
    )(cleanedAttributes);
  } else {
    cleanedAttributes = omit(['agentic_model_id', 'product'], cleanedAttributes);
  }

  if (automation_profile_type !== dialPlan) {
    cleanedAttributes = cleanupDialPlanAttributes(cleanedAttributes);
  }

  if (automation_profile_type !== humanbot) {
    cleanedAttributes = omit(
      ['humanbot_tts_configs_attributes', 'speech_to_text_config_id'],
      cleanedAttributes,
    );
  }

  cleanedAttributes = cleanupCommonFields(cleanedAttributes);

  formData.automation_profile_attributes = cleanedAttributes;
};

export const isSupportChannel = (
  profileType: AutomationProfileType,
  channelType: ChannelType,
): boolean => {
  const channelTypesByProfileType: Record<AutomationProfileTypes, ChannelType[]> = {
    [AutomationProfileTypes.dialPlan]: ['web', 'voice', 'facebook', 'sms', 'teams', 'whatsapp'],
    [AutomationProfileTypes.agentic]: ['email'],
    [AutomationProfileTypes.humanbot]: ['voice'],
  };
  return channelTypesByProfileType[profileType]?.includes(channelType);
};

export const updateValues = (values: IAutomationProfileValues, data?: IAutomationProfile): void => {
  const { dialPlan, agentic, humanbot } = AutomationProfileTypes;
  const { automation_profile_attributes, automation_profile_type } = values;
  switch (automation_profile_type) {
    case dialPlan:
      automation_profile_attributes.id = data?.dial_plan?.id;
      break;

    case agentic:
      automation_profile_attributes.id = data?.automation_profile_agentic?.id;
      break;

    case humanbot:
      automation_profile_attributes.id = data?.automation_profile_humanbot?.id;
      break;
  }
};

export const getChannelOptionsByProfile = (
  profileType: AutomationProfileType,
): { label: JSX.Element; value: ChannelType }[] => {
  switch (profileType) {
    case AutomationProfileTypes.agentic:
      return channelTypeOptions.filter(item => item.value === 'email');
    case AutomationProfileTypes.humanbot:
      return channelTypeOptions.filter(item => item.value === 'voice');
    case AutomationProfileTypes.dialPlan:
      return channelTypeOptions.filter(item => item.value !== 'email');
    default:
      return [];
  }
};

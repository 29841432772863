import { Paths } from '../../types/paths';
import { OldKnowledge } from './index';
import { KnowledgeIntro } from '../Knowledge/Intro';

export const oldKnowledgeRoutes = () => ({
  path: Paths.oldKnowledge(),
  element: <OldKnowledge />,
  breadcrumb: null,
  children: [
    {
      path: Paths.oldKnowledgeIntro(),
      element: <KnowledgeIntro />,
    },
  ],
});

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { SchedulesForm } from '../SchedulesForm';
import { useUpdate } from '../../../../hooks/useUpdate';
import { useGetItem } from '../../../../hooks/useGetItem';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { unexpectedError } from '../../../../utils/notification';
import { prepareDataForEdit, prepareSchedulePayload } from '../../../../utils/pulse';
import { PulseService } from '../../../../services/pulse';
import { IPulseData, useIntegrations } from '../../../../state/integrations';
import { ISchedule, ISchedulePayload, IScheduleValues } from '../../types';
import { Paths } from '../../../../types/paths';

export const SchedulesEdit: React.FC = () => {
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const { pulse } = useIntegrations();
  const { id } = useParams() as { id: string };
  const { notification } = App.useApp();

  const { data } = useGetItem<ISchedulePayload>(
    [PulseService.queryKey, id],
    ({ signal }) => PulseService.find({ id, apiData: pulse as IPulseData, signal }),
    { enabled: !!pulse?.name },
  );

  const { mutate: updateItem, isLoading } = useUpdate<{
    id: string;
    values: Omit<ISchedule, 'id'>;
    apiData: IPulseData;
  }>([PulseService.queryKey, id], PulseService.update);

  const onSubmit = (values: IScheduleValues) => {
    const data = prepareSchedulePayload(values);
    pulse &&
      updateItem(
        { id, values: data, apiData: pulse as IPulseData },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('schedule_updated_notify', {
                    name: response.name,
                  })}
                </Trans>
              ),
            });
            navigate(Paths.pulseSchedules());
          },
          onError: () => notification.error(unexpectedError),
        },
      );
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: Partial<IScheduleValues> = prepareDataForEdit(data);

  return (
    <Content imgBg={false}>
      <p>{t('form_description')}</p>
      <SchedulesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};

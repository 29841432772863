import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { ISchedule } from '../pages/Pulse/types';
import { pulseQuery } from './api';
import { IPulseData } from '../state/integrations';

const queryKey = 'pulse';

const get = async (
  apiData: IPulseData,
  signal: QueryFunctionContext['signal'],
): Promise<ISchedule[]> =>
  await pulseQuery(`projects/${apiData.name}/schedules`, { signal, apiData });

const create = async ({
  values,
  apiData,
}: {
  values: Omit<ISchedule, 'id'>;
  apiData: IPulseData;
}): Promise<any> =>
  await pulseQuery(`projects/${apiData.name}/schedules`, {
    data: values,
    method: 'POST',
    apiData,
  });

const find = async ({
  id,
  apiData,
  signal,
}: {
  id: string;
  apiData: IPulseData;
  signal: QueryFunctionContext['signal'];
}): Promise<ISchedule> =>
  await pulseQuery(`projects/${apiData.name}/schedules/${id}`, { signal, apiData });

const update = async ({
  id,
  values,
  apiData,
}: {
  id: string;
  values: Omit<ISchedule, 'id'>;
  apiData: IPulseData;
}): Promise<any> =>
  await pulseQuery(`projects/${apiData.name}/schedules/${id}`, {
    data: values,
    method: 'PUT',
    apiData,
  });

const remove = async ({ id, apiData }: { id: string; apiData: IPulseData }): Promise<any> =>
  await pulseQuery(`projects/${apiData.name}/schedules/${id}`, {
    method: 'DELETE',
    apiData,
  });

export const PulseService = {
  queryKey,
  create,
  find,
  update,
  get,
  remove,
};

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import seamlessAiImg from '../../../assets/images/sabio-wellbeing.png';
import effortlesscChannelImg from '../../../assets/images/effortless-channel.png';
import languageVoiceImg from '../../../assets/images/language-voice.png';
import interactionRoutingImg from '../../../assets/images/interaction-routing.png';
import agentAssistImg from '../../../assets/images/brands-management.png';
import { Content } from '../../../components/Content';
import { StyledIntroCard } from '../../../components/StyledIntroCard';

export const AIIntro: React.FC = () => {
  const { t } = useTranslation('ai');
  return (
    <Content imgBg={false} header={false}>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, 20]}>
          <StyledIntroCard.MainSection
            img={seamlessAiImg}
            title={t('intro_seamless_title')}
            description={t('intro_seamless_description')}
            maxHeight={328}
          />
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[0, 20]}>
          <Col span={24}>
            <h3>{t('intro_effortless_title')}</h3>
            <StyledIntroCard.Paragraph
              maxWidth={1296}
              description={t('intro_effortless_description')}
            />
          </Col>
          <Col span={24}>
            <img
              alt={t('intro_effortless_title')}
              src={effortlesscChannelImg}
              style={{ maxHeight: 354 }}
            />
          </Col>
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, { xs: 20, sm: 20, md: 0 }]}>
          <StyledIntroCard.SubSection
            left
            img={languageVoiceImg}
            title={t('intro_tailored_title')}
            description={t('intro_tailored_description')}
            maxHeight={264}
          />
          <StyledIntroCard.SubSection
            right
            img={interactionRoutingImg}
            title={t('intro_precision_title')}
            description={t('intro_precision_description')}
            maxHeight={244}
          />
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[0, 20]}>
          <Col span={24}>
            <h3>{t('intro_supercharge_title')}</h3>
            <StyledIntroCard.Paragraph
              maxWidth={864}
              description={t('intro_supercharge_description')}
            />
          </Col>
          <Col span={24}>
            <img
              alt={t('intro_supercharge_title')}
              src={agentAssistImg}
              style={{ maxHeight: 362 }}
            />
          </Col>
        </Row>
      </StyledIntroCard.Section>
    </Content>
  );
};

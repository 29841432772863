import { Navigate, Outlet } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { Paths } from '../../types/paths';
import { Intents } from './Intents';
import { Knowledge } from './index';
import { Parameters } from './Parameters';
import { Presentations } from './Presentations';
import { KnowledgeIntro } from './Intro';
import { CreatePresentation } from './Presentations/CreatePresentation';
import { EditPresentation } from './Presentations/EditPresentation';
import { PresentationsList } from './Presentations/PresentationsList';
import { ParametersList } from './Parameters/ParametersList';
import { CreateParameter } from './Parameters/CreateParameter';
import { EditParameter } from './Parameters/EditParameter';
import { Translations } from './Translations';
import { TranslationsList } from './Translations/TranslationsList';
import { EditTranslations } from './Translations/EditTranslations';
import { WorkflowList } from './Workflow/WorkflowList';

export const knowledgeRoutes = () => ({
  path: Paths.knowledge(),
  element: <Knowledge />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.knowledgePresentations()} />,
      breadcrumb: null,
    },
    {
      path: Paths.knowledgeIntro(),
      element: <KnowledgeIntro />,
    },
    {
      path: Paths.knowledgePresentations(),
      element: <Presentations />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('presentations')}</Translation>,
      children: [
        {
          index: true,
          element: <PresentationsList />,
        },
        {
          path: Paths.knowledgePresentationsCreate(),
          element: <CreatePresentation />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.knowledgePresentationsEdit(),
          element: <EditPresentation />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
      ],
    },
    {
      path: Paths.knowledgeParameters(),
      element: <Parameters />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('parameters')}</Translation>,
      children: [
        {
          index: true,
          element: <ParametersList />,
        },
        {
          path: Paths.knowledgeParametersCreate(),
          element: <CreateParameter />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.knowledgeParametersEdit(),
          element: <EditParameter />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
      ],
    },
    {
      path: Paths.knowledgeIntents(),
      element: <Intents />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('intents')}</Translation>,
    },
    {
      path: Paths.knowledgeTranslations(),
      element: <Translations />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('translations')}</Translation>,
      children: [
        {
          index: true,
          element: <TranslationsList />,
        },
        {
          path: Paths.knowledgeTranslationsEdit(),
          element: <EditTranslations />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
      ],
    },
    //todo: should only be available if the workflow feature enabled on a project when BE is done
    {
      path: Paths.knowledgeWorkflow(),
      element: <Outlet />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('workflow')}</Translation>,
      children: [
        {
          index: true,
          element: <WorkflowList />,
        },
      ],
    },
  ],
});

import React from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ChannelType, IChannelsValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { channelTypeOptions } from '../../../../../constants';
import { AutomationProfileSelector } from '../../../../../components/AutomationProfileSelector';
import { IAutomationProfile } from '../../../Automation/AutomationProfiles/types';
import { IdentifierField } from './IdentifierField';

interface Props {
  initialValues: IChannelsValues;
  onSubmit: (values: IChannelsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isChannel?: boolean;
}

export const ChannelForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isChannel,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { allChannels } = useFlags();
  const [form] = Form.useForm();
  const { getFieldsValue, setFieldsValue, setFields } = form;
  const { visible, openModal, closeModal } = useModal(false);

  const validChannelOptions = allChannels
    ? channelTypeOptions
    : channelTypeOptions.filter(channel =>
        ['web', 'voice', 'email', 'facebook', 'sms'].includes(channel.value),
      );

  const resetFieldsForChannel = () => {
    setFieldsValue({ phone_number: undefined, dial_plan_id: undefined, dial_plan_type: undefined });
  };

  const onProfileSelect = (
    profile?: Pick<IAutomationProfile, 'automation_profile_type' | 'automation_profile_id'>,
  ) => {
    setFields([
      { name: 'dial_plan_id', value: profile?.automation_profile_id },
      { name: 'dial_plan_type', value: profile?.automation_profile_type },
      { name: 'phone_number', value: undefined },
    ]);
  };

  const handleCancel = () => {
    if (!equals(initialValues, getFieldsValue(true))) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = getFieldsValue(true);
    onSubmit(formData, setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="channel_type"
        label={t('channel_type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select
          options={validChannelOptions}
          placeholder={t('select_channel_type')}
          onChange={resetFieldsForChannel}
          disabled={isChannel}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.channel_type !== currentValues.channel_type
        }
      >
        {({ getFieldValue }) => {
          const channelType: ChannelType = getFieldValue('channel_type');
          if (channelType) {
            return (
              <>
                <Form.Item
                  name="dial_plan_id"
                  label={t('automation_profile')}
                  className={formItemClass.base}
                  rules={[{ required: true }]}
                >
                  <AutomationProfileSelector
                    placeholder={t('select_automation_profile')}
                    channelType={channelType}
                    onProfileSelect={onProfileSelect}
                  />
                </Form.Item>
                <Form.Item name="dial_plan_type" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.dial_plan_type !== currentValues.dial_plan_type
                  }
                >
                  {() => <IdentifierField />}
                </Form.Item>
              </>
            );
          }
        }}
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { SchedulesForm } from '../SchedulesForm';
import { useCreate } from '../../../../hooks/useCreate';
import { PulseService } from '../../../../services/pulse';
import { unexpectedError } from '../../../../utils/notification';
import { prepareSchedulePayload } from '../../../../utils/pulse';
import { useIntegrations } from '../../../../state/integrations';
import { IScheduleTimesValues, IScheduleValues } from '../../types';
import { Paths } from '../../../../types/paths';

export const SchedulesNew: React.FC = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const { pulse } = useIntegrations();

  const { mutate: createItem, isLoading } = useCreate([PulseService.queryKey], PulseService.create);

  const onSubmit = async (values: IScheduleValues) => {
    const data = prepareSchedulePayload(values);
    pulse &&
      createItem(
        { apiData: pulse, values: data },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('schedule_created_notify', {
                    name: response.name,
                  })}
                </Trans>
              ),
            });
            navigate(Paths.pulseSchedules());
          },
          onError: () => notification.error(unexpectedError),
        },
      );
  };

  const initialValues: Partial<IScheduleValues> = {
    enabled: true,
    recording: true,
    executionType: 'twilioflow',
    timezone: 'Europe/London',
    scheduleTimes: {} as IScheduleTimesValues,
    repeatPeriod: 30,
    retry: 0,
  };

  return (
    <Content imgBg={false}>
      <p>{t('form_description')}</p>
      <SchedulesForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../ReportsTable';
import { AnalyticsService } from '../../../../../services/analytics';
import { useDelete } from '../../../../../hooks/useDelete';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';
import { useAnalyticsData } from '../../../../../hooks/useAnalyticsData';

export const DashboardsList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { analytics: { url: analyticsUrl, externalId: projectId } = {} } = useIntegrations();
  const { data, isLoading } = useAnalyticsData({
    queryKey: AnalyticsService.queryKeyDashboards,
    fetchDataFn: AnalyticsService.getDashboards,
  });
  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [AnalyticsService.queryKeyDashboards],
    AnalyticsService.deleteDashboard,
  );

  const handleDelete = React.useCallback(
    async (id, componentId) =>
      analyticsUrl && projectId && (await deleteItem({ id, analyticsUrl, projectId, componentId })),
    [analyticsUrl, projectId],
  );

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('dashboards')}</UppercaseLinkBold>
      <ReportsTable
        loading={!!projectId && (isLoading || isDeleteLoading)}
        reports={data || []}
        handleRemove={handleDelete}
      />
    </Content>
  );
};

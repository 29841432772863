import React from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { PasswordInput } from '../../../../../../components/PasswordInput';
import { getProviderName, getRegionOptions } from '../utils/utils';
import { IVoices, TTSSourceType } from '../../types';

type Props = {
  provider: 'TtsSourceAmazon' | 'TtsSourceAzure';
  data?: IVoices;
};

export const AmazonHBFields = ({ provider, data }: Props) => {
  const { t } = useTranslation(['ai', 'project_settings']);
  const selectedProvider = getProviderName(provider as TTSSourceType);
  const regionOptions = selectedProvider ? getRegionOptions(selectedProvider, data) : [];

  return (
    <>
      <Form.Item
        name={['tts_source_attributes', 'region']}
        label={t('region')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select showSearch options={regionOptions} placeholder={t('select_region')} />
      </Form.Item>
      <Space>
        <Form.Item
          name={['tts_source_attributes', 'access_key']}
          label={t('access_key')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_access_key')} />
        </Form.Item>
        {provider === 'TtsSourceAmazon' && (
          <Form.Item
            name={['tts_source_attributes', 'secret_access_key']}
            label={t('secret_access_key')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <PasswordInput placeholder={t('input_secret_access_key')} />
          </Form.Item>
        )}
      </Space>
    </>
  );
};

import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { ISpeechToTextConfig, ISTTValues } from '../../pages/AI/Automation/SpeechToText/types';
import { sortById } from '../../utils/sorting';

const queryKey = 'speech-to-text';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ISpeechToTextConfig[]> => {
  const response = await integrationQuery(`/api/speech_to_text_configs`, {
    apiData,
    signal,
  });
  return sortById(response.speech_to_text_configs);
};

const create = async ({ data, apiData }: { data: ISTTValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/speech_to_text_configs`, {
    method: 'POST',
    data: { data: { type: 'speech_to_text_configs', attributes: data } },
    apiData,
  });

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/speech_to_text_configs/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ISpeechToTextConfig> => {
  const response = await integrationQuery(`/api/speech_to_text_configs/${id}`, {
    apiData,
    signal,
  });
  return response?.speech_to_text_config;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  data: ISTTValues;
} & AiIntegrationParams): Promise<ISpeechToTextConfig> => {
  return integrationQuery(`/api/speech_to_text_configs/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'speech_to_text_configs',
        attributes: data,
      },
    },
    apiData,
  });
};

export const SpeechToTextService = {
  queryKey,
  get,
  remove,
  create,
  find,
  update,
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Flex, Select, SelectProps, Typography } from 'antd';
import { SpeechToTextService } from '../../services/ai/speech-to-text';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { sttProviderOptions } from '../../constants';
import { ProviderTag } from '../ProviderTag';
import { SelectWithFooter } from '../SelectWithFooter';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

const { Text } = Typography;

interface Props extends SelectProps {
  openModal: () => void;
}

export const SpeechToTextSelector: React.FC<Props> = ({ openModal, ...other }) => {
  const { t } = useTranslation('ai');
  const { aiIntegration } = useIntegrations();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data } = useQuery(
    [SpeechToTextService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      SpeechToTextService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleNewSTT = () => {
    setIsOpen(false);
    openModal();
  };

  const options = data?.map(item => ({
    label: (
      <Flex align={'center'}>
        <Text style={{ maxWidth: '80%', color: 'inherit' }} ellipsis>
          {item.name}
        </Text>
        <ProviderTag type={item.stt_source_type} width={68}>
          {sttProviderOptions.find(provider => provider.value === item.stt_source_type)?.label ||
            '-'}
        </ProviderTag>
      </Flex>
    ),
    value: item.id,
  }));

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewSTT}>
        <PlusIcon width={10} height={10} /> {t('new_stt_profile')}
      </SelectWithFooter.Footer>
    </>
  );

  return (
    <Select
      open={isOpen}
      showSearch
      optionFilterProp="label"
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      options={options}
      {...other}
    />
  );
};

import dayjs from 'dayjs';
import {
  IScheduleTime,
  IScheduleValues,
  ISchedulePayload,
  ISchedule,
  IScheduleTimesValues,
} from '../pages/Pulse/types';
import { formatChinaDateFormat, formatTime } from './date';
import { Weekday, days } from '../constants';

export const extractScheduleTimes = (payload: IScheduleTimesValues): IScheduleTime[] => {
  const scheduleTimes: IScheduleTime[] = [];

  Object.keys(days).forEach(day => {
    if (payload[day as Weekday]) {
      scheduleTimes.push({
        dayOfWeek: day as Weekday,
        startTime: formatTime(payload[`start_time_${day as Weekday}`]),
        endTime: formatTime(payload[`end_time_${day as Weekday}`]),
      });
    }
  });
  return scheduleTimes;
};

const extractScheduleData = (data?: IScheduleTime[]): IScheduleTimesValues => {
  const formattedData = {} as IScheduleTimesValues;
  data &&
    data.forEach((scheduleTime: IScheduleTime) => {
      formattedData[scheduleTime.dayOfWeek] = true;
      formattedData[`start_time_${scheduleTime.dayOfWeek}`] = dayjs(
        scheduleTime.startTime,
        'HH:mm',
      );
      formattedData[`end_time_${scheduleTime.dayOfWeek}`] = dayjs(scheduleTime.endTime, 'HH:mm');
    });
  return formattedData;
};

const parseOptions = (input: string): string[] => {
  try {
    const values: string[] = JSON.parse(input);
    if (Array.isArray(values)) {
      return values.map(value => value);
    }
  } catch (e) {}
  return [input];
};

export const prepareDataForEdit = (data: ISchedulePayload) => {
  const {
    scheduleTimes,
    startDate,
    endDate,
    exceptionStartDate,
    exceptionEndDate,
    repeatPeriod,
    pathCode,
    ...rest
  } = data;

  const formattedData: Partial<IScheduleValues> = {
    ...rest,
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    repeatPeriod: repeatPeriod / 1000,
    scheduleTimes: extractScheduleData(scheduleTimes),
    pathCode: parseOptions(pathCode),
  };

  if (exceptionStartDate && exceptionEndDate) {
    formattedData.exceptionPeriod = [
      dayjs(new Date(exceptionStartDate)),
      dayjs(new Date(exceptionEndDate)),
    ];
  }

  return formattedData;
};

export const prepareSchedulePayload = (values: IScheduleValues): Omit<ISchedule, 'id'> => {
  const {
    enabled,
    executionType,
    exceptionPeriod,
    startDate,
    endDate,
    repeatPeriod,
    name,
    fromN,
    toN,
    recording,
    retry,
    timezone,
    serviceName,
    pathCode,
    flowSid,
    twiml,
  } = values;

  const payload: Omit<ISchedule, 'id'> = {
    enabled,
    fromN,
    toN,
    name,
    recording,
    retry,
    timezone,
    serviceName,
    pathCode: JSON.stringify(pathCode),
    executionType,
    flowSid,
    twiml,
    startDate: formatChinaDateFormat(startDate),
    endDate: formatChinaDateFormat(endDate),
    exceptionStartDate: exceptionPeriod && formatChinaDateFormat(exceptionPeriod?.[0]),
    exceptionEndDate: exceptionPeriod && formatChinaDateFormat(exceptionPeriod?.[1]),
    repeatPeriod: repeatPeriod * 1000,
    scheduleTimes: extractScheduleTimes(values.scheduleTimes),
  };

  executionType === 'twilioflow' && delete payload.twiml;
  executionType === 'twiml' && delete payload.flowSid;

  return payload;
};

import React from 'react';
import { Form, Input, Switch, FormInstance } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { GoogleCloudProjectInput } from '../../../../../components/GoogleCloudProjectInput';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { LocationSelect } from '../../../../../components/LocationSelect';
import { useModal } from '../../../../../hooks/useModal';
import { IDialogflowPlatform, INLUAgent, INLUAgentValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { AiIntegrationParams } from '../../../../../services/api';
import { useIntegrations } from '../../../../../state/integrations';
import { useAccount } from '../../../../../state/account';
import { formatNLUFormData } from '../utils';
import { LexFields } from './LexFields';
import { HumanBotFields } from './HumanBotFields';
import { PlatformEditionSwitch } from './PlatformEditionSwitch';

interface Props {
  initialValues: Omit<INLUAgent, 'id'>;
  onSubmit: (values: INLUAgentValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEditing?: boolean;
  isModal?: boolean;
  closeModal?: () => void;
}

export type NLUAgentDFType = Omit<INLUAgent, 'agent_source'> & {
  agent_source: IDialogflowPlatform;
};

export const NLUProvidersForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEditing,
  isModal,
  closeModal,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { isHumanbotProject } = useAccount();
  const [form] = Form.useForm();
  const { visible, openModal: openDiscardModal, closeModal: closeDiscardModal } = useModal(false);
  const { aiIntegration } = useIntegrations();

  const { data } = useQuery(
    [TextToSpeechService.voiceQueryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      TextToSpeechService.getVoices({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const handleCancel = () => {
    if (isModal) {
      closeModal?.();
    } else {
      if (!equals(initialValues, form.getFieldsValue(true))) {
        openDiscardModal();
      } else {
        navigate(-1);
      }
    }
  };

  const onFinish = async () => {
    const formData = form.getFieldsValue();
    const formattedData = await formatNLUFormData(formData);
    onSubmit(formattedData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_provider_name')} />
      </Form.Item>
      <PlatformEditionSwitch isEditing={isEditing} isHBProject={isHumanbotProject} data={data} />
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.agent_source_type !== curr.agent_source_type}
      >
        {({ getFieldValue }) => {
          const platform = getFieldValue('agent_source_type');
          const locationField = (
            <Form.Item
              name={['agent_source', 'location']}
              label={t('location')}
              tooltip={t('location_tooltip')}
              className={formItemClass.base}
              rules={[{ required: true }]}
            >
              <LocationSelect
                showSearch
                placeholder={t('location')}
                platform={platform}
                amazonLocations={data?.amazon.regions}
              />
            </Form.Item>
          );
          return (
            <>
              {(platform === 'ES' || platform === 'CX') && (
                <>
                  <Form.Item
                    name={['agent_source', 'ccai_enabled']}
                    valuePropName="checked"
                    label={t('ccai_enabled')}
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item noStyle name="agent_source">
                    <GoogleCloudProjectInput
                      initialValues={initialValues as NLUAgentDFType}
                      isEditing={isEditing}
                      isNLUForm={true}
                      locationField={locationField}
                    />
                  </Form.Item>
                </>
              )}
              {platform === 'CX' && (
                <Form.Item
                  name={['agent_source', 'uuid']}
                  label={t('uuid')}
                  className={formItemClass.base}
                  tooltip={t('uuid_tooltip')}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('uuid')} />
                </Form.Item>
              )}
              {platform === 'AgentSourceLex' && (
                <>
                  <LexFields />
                  {locationField}
                </>
              )}
              {platform === 'AgentSourceHumanbot' && <HumanBotFields />}
            </>
          );
        }}
      </Form.Item>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeDiscardModal} />
        </>
      )}
    </Form>
  );
};

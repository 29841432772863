import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { ITTSConfig } from '../types';
import { Paths } from '../../../../../types/paths';
import { getProviderName } from '../TextToSpeechForm/utils/utils';
import { snakeToCapitalCase } from '../../../../../utils/string';

export const TextToSpeechList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiTextToSpeechEdit({ id })),
  });

  const columns: ColumnProps<ITTSConfig>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('provider'),
      onCell: onCellHandler,
      dataIndex: 'tts_source_type',
      render: provider => snakeToCapitalCase(getProviderName(provider)),
      width: '15%',
    },
    {
      title: t('voice_type'),
      onCell: onCellHandler,
      dataIndex: 'voice',
    },
    {
      title: t('language'),
      onCell: onCellHandler,
      dataIndex: 'locale',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<ITTSConfig>
      service={TextToSpeechService}
      columns={columns}
      getCreatePath={Paths.aiTextToSpeechCreate}
      deleteConfirmationTitle={t('delete_profile')}
      getDisplayName={record => record.name}
    />
  );
};

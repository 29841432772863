import React from 'react';
import { Button, ConfigProvider, Empty, Form, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Trans, useTranslation } from 'react-i18next';
import { TableRowSelection } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import useUpdateConfig from '../../hooks/useUpdateConfig';
import { IAutomationProfile } from '../../types';
import { DataTable, IDataTable } from '../../../../../../components/DataTable';
import { DeleteConfirmation } from '../../../../../../components/DeleteConfirmation';
import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copy.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check.svg';
import { ReactComponent as UnassignIcon } from '../../../../../../assets/icons/linkslash.svg';
import { useIntegrations } from '../../../../../../state/integrations';
import { getSnippet } from '../EmbedCodeModal';
import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg';
import { ReactComponent as ExclamationCircle } from '../../../../../../assets/icons/exclamation-circle.svg';
import { Paths } from '../../../../../../types/paths';
import { useAccount } from '../../../../../../state/account';

const { Paragraph } = Typography;

type Props = IDataTable<IAutomationProfile> & {
  profiles: IAutomationProfile[];
  rowSelection?: TableRowSelection<IAutomationProfile>;
};

const EmptyTable = () => {
  const { t } = useTranslation('design');
  const navigate = useNavigate();

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
      <Button
        type={'default'}
        size={'small'}
        icon={<Plus width={11} height={12} />}
        onClick={() => navigate(Paths.aiAutomationProfilesCreate())}
      >
        {t('create_profile')}
      </Button>
    </Empty>
  );
};

export const ProfilesTable: React.FC<Props> = ({ profiles, rowSelection, ...other }) => {
  const { t } = useTranslation('design');
  const { activeProjectId } = useAccount();
  const { getFieldsValue, setFields, setFieldValue } = Form.useFormInstance();
  const { chat } = useIntegrations();
  const updateVAConfig = useUpdateConfig();

  const handleDelete = async (profileId: string | number) => {
    const formData = getFieldsValue(true);
    const dialPlans = formData.dial_plan_ids
      .filter((item: string | number) => item.toString() !== profileId.toString())
      .map((item: string | number) => Number(item));
    setFieldValue(['dial_plan_ids'], dialPlans);
    const payload = {
      dial_plan_ids: JSON.stringify(dialPlans),
    };
    await updateVAConfig(payload, setFields);
  };

  const columns: ColumnProps<IAutomationProfile>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];

  if (rowSelection) {
    columns.push({
      title: t('virtual_assistant'),
      dataIndex: ['virtual_agent_config', 'name'],
      render: (value, record) => (
        <>
          {value}
          {record.virtual_agent_config && <ExclamationCircle width={12} className="warningIcon" />}
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '34%',
      className: 'is-relative',
    });
  } else if (activeProjectId && chat) {
    columns.push({
      key: 'actions',
      width: 94,
      render: ({ id, name }) => (
        <>
          <Paragraph
            copyable={{
              text: getSnippet(
                chat.metadata.account_id.toString(),
                chat.metadata.project_id.toString(),
                activeProjectId,
                chat.url,
                id,
              ),
              icon: [
                <CopyIcon key="copy-icon" width={14} height={14} />,
                <CheckIcon key="copied-icon" width={14} height={14} />,
              ],
            }}
          ></Paragraph>
          <DeleteConfirmation
            title={t('unassign_profile')}
            onActionDelete={() => handleDelete(id)}
            modalIcon={<UnassignIcon />}
            modalButtonText={t('unassign')}
          >
            <Trans>{t('unassign_profile_text', { name })}</Trans>
          </DeleteConfirmation>
        </>
      ),
      className: 'actions',
      onCell: () => ({
        onClick: e => e.stopPropagation(),
      }),
    });
  }

  return (
    <ConfigProvider renderEmpty={rowSelection && EmptyTable}>
      <DataTable
        dataSource={profiles}
        columns={columns}
        isPaginationFixed={false}
        isPageSizer={false}
        {...(rowSelection && { rowSelection: rowSelection })}
        {...other}
      />
    </ConfigProvider>
  );
};

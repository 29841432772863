import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { SpeechToTextNew } from '../SpeechToTextNew';

interface IParameterModal {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setNewSTT: (id: number) => void;
}

export const SpeechToTextModal: React.FC<IParameterModal> = ({
  isVisible,
  setIsVisible,
  setNewSTT,
}) => {
  const { t } = useTranslation('ai');

  const handleCreateSTT = (id: number) => {
    setNewSTT(id);
    setIsVisible(false);
  };

  const handleCancel = () => setIsVisible(false);

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} /> {t('new_stt_profile')}
        </>
      }
      width={848}
      centered
      open={isVisible}
      footer={false}
      onCancel={handleCancel}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
    >
      <SpeechToTextNew isModal={true} closeModal={handleCancel} handleCreateSTT={handleCreateSTT} />
    </Modal>
  );
};

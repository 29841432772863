import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { FallbackBehaviourForm } from '../FallbackBehaviourForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { FallbackBehaviourValues } from '../types';
import { FallbackConfigsService } from '../../../../../services/ai/fallback-configs';
import { Content } from '../../../../../components/Content';
import { InfoBanner } from '../../../../../components/InfoBanner';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { Space } from '../../../../../components/Space';
import { formItemClass } from '../../../../../theme';

interface Props {
  isModal?: boolean;
  closeModal?: () => void;
  handleCreateBehaviour?: (id: number) => void;
}

export const FallbackBehaviourNew: React.FC<Props> = ({
  isModal,
  closeModal,
  handleCreateBehaviour,
}) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [FallbackConfigsService.queryKey],
    FallbackConfigsService.create,
  );

  const onSubmit = async (
    data: FallbackBehaviourValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { escalate_message, hangup_message, powered_by_twilio } = data;
    (!escalate_message || powered_by_twilio) && (data.escalate_message = '-');
    (!hangup_message || powered_by_twilio) && (data.hangup_message = '-');
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('fallback_behav_created_notify', { name: data.fallback_config.name })}
              </Trans>
            ),
          });
          if (isModal && handleCreateBehaviour) {
            handleCreateBehaviour(data.fallback_config.id);
          } else navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: FallbackBehaviourValues = {
    name: '',
    escalate_message: '',
    hangup_message: '',
    is_default: false,
    powered_by_twilio: false,
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <Space direction="vertical" size="middle">
      <InfoBanner className={formItemClass.full} message={t('fallback_behav_description')} />
      <FallbackBehaviourForm {...formProps} isModal={isModal} closeModal={closeModal} />
    </Space>
  ) : (
    <Content imgBg={false}>
      <Space direction="vertical" size="middle">
        <InfoBanner className={formItemClass.full} message={t('fallback_behav_description')} />
        <FallbackBehaviourForm {...formProps} />
      </Space>
    </Content>
  );
};

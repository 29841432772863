import { LookerEmbedCookielessSessionData } from '@looker/embed-sdk';
import { analyticsQuery } from './api';
import { AnalyticsParams } from './analytics';
import { getLookerTokens, setLookerTokens } from '../utils/auth';

const acquireEmbedSession = async ({
  projectId,
  componentId,
  analyticsUrl,
}: AnalyticsParams & {
  componentId: number | string;
}): Promise<LookerEmbedCookielessSessionData> => {
  const response = await analyticsQuery(
    `/api/projects/${projectId}/components/${componentId}/looker/embed/cookieless_sessions`,
    { method: 'POST', analyticsUrl },
  );
  setLookerTokens(response);
  return response;
};

const generateEmbedTokens = async ({
  projectId,
  componentId,
  analyticsUrl,
}: AnalyticsParams & {
  componentId: number | string;
}): Promise<LookerEmbedCookielessSessionData> => {
  const { api_token, navigation_token, session_reference_token } = getLookerTokens() || {};
  return await analyticsQuery(
    `/api/projects/${projectId}/components/${componentId}/looker/embed/tokens`,
    {
      method: 'POST',
      data: { api_token, navigation_token, session_reference_token },
      analyticsUrl,
    },
  );
};

export const LookerSession = { acquireEmbedSession, generateEmbedTokens };

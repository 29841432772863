import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { ContextNew } from '../ContextNew';

interface IParameterModal {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setNewContext: (id: number) => void;
}

export const ContextModal: React.FC<IParameterModal> = ({
  isVisible,
  setIsVisible,
  setNewContext,
}) => {
  const { t } = useTranslation('ai');

  const handleCreateContext = (id: number) => {
    setNewContext(id);
    setIsVisible(false);
  };

  const handleCancel = () => setIsVisible(false);

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} /> {t('new_context')}
        </>
      }
      width={848}
      centered
      open={isVisible}
      footer={false}
      onCancel={handleCancel}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
      destroyOnClose={true}
    >
      <ContextNew
        isModal={true}
        closeModal={handleCancel}
        handleCreateContext={handleCreateContext}
      />
    </Modal>
  );
};

import React from 'react';
import { Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { days, Weekday } from '../../../../../constants';
import { FormTimePicker, TimeSchedule, To } from '../../../Pulse.styles';
import { formItemClass } from '../../../../../theme';
import { TimePicker } from '../../../../../components/DatePicker';

type Props = {
  handleValidation: (field: 'start_time' | 'end_time', day: string) => Promise<void>;
};

export const TimeSchedulePicker: React.FC<Props> = ({ handleValidation }) => {
  const { t } = useTranslation('pulse');

  return (
    <>
      {Object.keys(days).map((day, index) => {
        return (
          <TimeSchedule key={day}>
            <Form.Item
              className={formItemClass.xsmall}
              style={{ marginTop: index === 0 ? '25px' : '' }}
            >
              <b>{days[day as Weekday]}</b>
            </Form.Item>
            <Form.Item
              name={['scheduleTimes', day]}
              className={formItemClass.hidden}
              valuePropName="checked"
              style={{ marginTop: index === 0 ? '25px' : '' }}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => prev.scheduleTimes[day] !== curr.scheduleTimes[day]}
            >
              {({ getFieldValue }) => (
                <FormTimePicker
                  name={['scheduleTimes', `start_time_${day}`]}
                  label={index === 0 && t('start_time')}
                  style={{ margin: '0 12px 20px 20px' }}
                  className={formItemClass.xsmall}
                  rules={[
                    {
                      required: getFieldValue(['scheduleTimes', day]),
                      message: t('start_time_required'),
                    },
                    {
                      validator: () => handleValidation('start_time', day),
                    },
                  ]}
                >
                  <TimePicker showNow={false} disabled={!getFieldValue(['scheduleTimes', day])} />
                </FormTimePicker>
              )}
            </Form.Item>
            <To firstIndex={index === 0 ? 'true' : 'false'}>{t('to')}</To>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => prev.scheduleTimes[day] !== curr.scheduleTimes[day]}
            >
              {({ getFieldValue }) => (
                <FormTimePicker
                  name={['scheduleTimes', `end_time_${day}`]}
                  label={index === 0 && t('end_time')}
                  style={{ margin: '0 20px 20px 12px' }}
                  className={formItemClass.xsmall}
                  rules={[
                    {
                      required: getFieldValue(['scheduleTimes', day]),
                      message: t('end_time_required'),
                    },
                    {
                      validator: () => handleValidation('end_time', day),
                    },
                  ]}
                >
                  <TimePicker showNow={false} disabled={!getFieldValue(['scheduleTimes', day])} />
                </FormTimePicker>
              )}
            </Form.Item>
          </TimeSchedule>
        );
      })}
    </>
  );
};

import { SelectProps } from 'antd';
import { IVoices, TTSSourceType } from '../../types';

type TTSProviderName = 'google' | 'amazon' | 'azure';

export const getProviderName = (value: TTSSourceType): TTSProviderName => {
  switch (value) {
    case 'TtsSourceGoogle':
      return 'google';
    case 'TtsSourceAmazon':
      return 'amazon';
    case 'TtsSourceAzure':
      return 'azure';
  }
};

const getAllLocaleOptions = (data: IVoices): { label: string; value: string }[] => {
  const googleLocales = Object.keys(data.google);
  const amazonLocales = Object.keys(data.amazon?.voice_map);
  const azureLocales = Object.keys(data.azure?.voice_map);

  const uniqueLocales = new Set([...googleLocales, ...amazonLocales, ...azureLocales]);

  return Array.from(uniqueLocales).map(locale => ({ label: locale, value: locale }));
};

export const getLocaleOptions = (
  provider: keyof IVoices | 'all',
  data?: IVoices,
): { label: string; value: string }[] => {
  if (!data) return [];
  if (provider === 'all') return getAllLocaleOptions(data);

  const source = provider === 'google' ? data[provider] : data[provider]?.voice_map;
  if (!source) return [];

  return Object.keys(source).map(key => ({ label: key, value: key }));
};

export const getVoiceOptions = (
  provider: keyof IVoices,
  locale: string,
  data?: IVoices,
): SelectProps['options'] => {
  if (!locale || !data || !data[provider]) return [];

  const source =
    provider === 'google' ? data[provider]?.[locale] : data[provider]?.voice_map?.[locale];
  if (!source) return [];

  return source.filter(item => !!item).map((voice: string) => ({ label: voice, value: voice }));
};

export const getRegionOptions = (
  selectedProviderKey: TTSProviderName,
  data?: IVoices,
): SelectProps['options'] => {
  if (!selectedProviderKey) return [];
  return data?.[selectedProviderKey]?.regions?.map(r => ({ label: r, value: r })) || [];
};

import { UploadFile } from 'antd/es/upload/interface';
import { clone } from 'ramda';
import {
  IHumanbotPlatform,
  ILexPlatform,
  IDialogflowPlatform,
  INLUAgent,
  INLUAgentValues,
} from './types';
import { getGCPKeyfile } from '../../../../utils/files';

export const isDialogflowAgent = (
  agent: ILexPlatform | IDialogflowPlatform | IHumanbotPlatform | null,
): agent is IDialogflowPlatform => !!agent && 'gcp_project_name' in agent;

export const isLexAgent = (
  agent: ILexPlatform | IDialogflowPlatform | IHumanbotPlatform | null,
): agent is ILexPlatform => !!agent && 'agent_alias' in agent;

export const isHumanbotAgent = (
  agent: ILexPlatform | IDialogflowPlatform | IHumanbotPlatform | null,
): agent is IHumanbotPlatform => !!agent && 'api_key' in agent;

export const hasUUID = (
  agent: ILexPlatform | IDialogflowPlatform | IHumanbotPlatform | null,
): agent is IDialogflowPlatform => !!agent && 'uuid' in agent && agent.uuid != null;

export const formatNLUFormData = async (formData: INLUAgent): Promise<INLUAgentValues> => {
  const formattedData: INLUAgent = clone(formData);

  if (['ES', 'CX'].includes(formattedData.agent_source_type) && formattedData.agent_source) {
    if (formattedData.agent_source_type === 'ES' && 'uuid' in formattedData.agent_source) {
      (formattedData.agent_source as IDialogflowPlatform).uuid = null;
    }
    formattedData.agent_source_type = 'DialogflowAgent';

    if ('gcp_keyfile' in formattedData.agent_source)
      formattedData.agent_source.display_name = formattedData.name;
  }

  if (
    formattedData.agent_source &&
    'gcp_keyfile' in formattedData.agent_source &&
    formattedData.agent_source.gcp_keyfile
  ) {
    formattedData.agent_source.gcp_keyfile = await getGCPKeyfile(
      formattedData.agent_source.gcp_keyfile as unknown as UploadFile<any>[],
    );
  }

  if (
    formattedData.agent_source &&
    'gcp_fulfillment_auth_keyfile' in formattedData.agent_source &&
    formattedData.agent_source.gcp_fulfillment_auth_keyfile
  ) {
    formattedData.agent_source.gcp_fulfillment_auth_keyfile = await getGCPKeyfile(
      formattedData.agent_source.gcp_fulfillment_auth_keyfile as unknown as UploadFile<any>[],
    );
  }

  const agent_source_attributes =
    formattedData.agent_source_type === 'AgentSourceLex'
      ? {
          id: formattedData.agent_source?.id,
          access_key: (formattedData.agent_source as ILexPlatform)?.access_key.replace(/\s/g, ''),
          agent_alias: (formattedData.agent_source as ILexPlatform)?.agent_alias,
          agent_uuid: (formattedData.agent_source as ILexPlatform)?.agent_uuid,
          secret_access_key: (
            formattedData.agent_source as ILexPlatform
          )?.secret_access_key.replace(/\s/g, ''),
          location: (formattedData.agent_source as ILexPlatform).location,
        }
      : formattedData.agent_source_type === 'AgentSourceHumanbot'
      ? {
          id: formattedData.agent_source?.id,
          api_key: (formattedData.agent_source as IHumanbotPlatform)?.api_key,
          url: (formattedData.agent_source as IHumanbotPlatform)?.url,
        }
      : {
          id: formattedData.agent_source?.id,
          gcp_project_name: (formattedData.agent_source as IDialogflowPlatform)?.gcp_project_name,
          gcp_keyfile: (formattedData.agent_source as IDialogflowPlatform)?.gcp_keyfile,
          ccai_enabled: (formattedData.agent_source as IDialogflowPlatform)?.ccai_enabled,
          gcp_fulfillment_auth_keyfile: (formattedData.agent_source as IDialogflowPlatform)
            ?.gcp_fulfillment_auth_keyfile,
          display_name: (formattedData.agent_source as IDialogflowPlatform)?.display_name,
          uuid: (formattedData.agent_source as IDialogflowPlatform)?.uuid,
          location: (formattedData.agent_source as IDialogflowPlatform)?.location,
        };

  return {
    name: formattedData.name,
    agent_source_type: formattedData.agent_source_type,
    agent_source_attributes,
  };
};

export const convertPlatformName = (record: INLUAgent): 'ES' | 'CX' | 'LEX' | 'HB' | undefined => {
  const { agent_source_type, agent_source } = record;
  if (agent_source_type === 'AgentSourceHumanbot') return 'HB';
  if (agent_source_type === 'AgentSourceLex') return 'LEX';
  if (agent_source_type === 'DialogflowAgent') {
    if (hasUUID(agent_source)) return 'CX';
    return 'ES';
  }
};

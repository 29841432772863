import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../../components/Space';
import { formItemClass } from '../../../../../theme';
import { DatePicker } from '../../../../../components/DatePicker';

type Props = {
  handleValidation: (field: 'startDate' | 'endDate') => Promise<void>;
};

export const DateSelector: React.FC<Props> = ({ handleValidation }) => {
  const { setFieldValue } = Form.useFormInstance();
  const { t } = useTranslation('pulse');
  return (
    <Space>
      <Form.Item
        name="startDate"
        label={t('start_date')}
        valuePropName="value"
        className={formItemClass.base}
        rules={[{ required: true }, { validator: () => handleValidation('startDate') }]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          placeholder={t('start_date')}
          showToday={false}
          style={{ fontSize: 16 }}
          onChange={() => setFieldValue('exceptionPeriod', undefined)}
        />
      </Form.Item>
      <Form.Item
        name="endDate"
        label={t('end_date')}
        valuePropName="value"
        className={formItemClass.base}
        rules={[{ required: true }, { validator: () => handleValidation('endDate') }]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          placeholder={t('end_date')}
          showToday={false}
          onChange={() => setFieldValue('exceptionPeriod', undefined)}
        />
      </Form.Item>
    </Space>
  );
};

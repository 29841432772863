import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Content } from '../../../../components/Content';
import { DataTable } from '../../../../components/DataTable';
import { ActionButton } from '../../../../components/ActionButton';
import { IconIndicator } from '../../../../components/IconIndicator';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { PulseService } from '../../../../services/pulse';
import { IPulseData, useIntegrations } from '../../../../state/integrations';
import { useDelete } from '../../../../hooks/useDelete';
import { ISchedulePayload } from '../../types';
import { Paths } from '../../../../types/paths';
import { formatDate } from '../../../../utils/date';

export const SchedulesList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pulse');
  const { pulse } = useIntegrations();

  const { data, isLoading, fetchStatus } = useQuery<ISchedulePayload[]>(
    [PulseService.queryKey, pulse?.name],
    ({ signal }) => PulseService.get(pulse as IPulseData, signal),
    { enabled: !!pulse?.name },
  );
  const { mutate: deleteItem } = useDelete([PulseService.queryKey], PulseService.remove);
  const loading = isLoading && fetchStatus !== 'idle';

  const onRowHandler = ({ id }: ISchedulePayload) => ({
    onClick: () => navigate(Paths.pulseScheduleEdit({ id })),
  });

  const handleDelete = useCallback(id => pulse && deleteItem({ id, apiData: pulse }), [pulse]);

  const columns: ColumnProps<ISchedulePayload>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('service_name'),
      dataIndex: 'serviceName',
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    {
      title: t('status'),
      dataIndex: 'enabled',
      width: 90,
      render: enabled => <IconIndicator enabled={enabled} />,
      sorter: (a, b) => Number(a.enabled) - Number(b.enabled),
    },
    {
      title: t('caller_#_from'),
      dataIndex: 'fromN',
      sorter: (a, b) => a.fromN.localeCompare(b.fromN),
    },
    {
      title: t('caller_#_to'),
      dataIndex: 'toN',
      sorter: (a, b) => a.toN.localeCompare(b.toN),
    },
    {
      title: t('rep_call'),
      dataIndex: 'repeatPeriod',
      render: (repeatPeriod: number) => `${repeatPeriod / 1000}s`,
      sorter: (a, b) => Number(a.repeatPeriod) - Number(b.repeatPeriod),
    },
    {
      title: t('start_date'),
      dataIndex: 'startDate',
      render: date => formatDate(date),
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
    },
    {
      title: t('end_date'),
      dataIndex: 'endDate',
      render: date => formatDate(date),
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
    },
    {
      key: 'actions',
      fixed: 'right',
      width: '3%',
      className: 'actions',
      onCell: () => ({
        onClick: e => e.stopPropagation(),
      }),
      render: record => (
        <DeleteConfirmation
          name={record.name}
          title={t('delete_schedule')}
          id={record?.id.toString()}
          onActionDelete={handleDelete}
        />
      ),
    },
  ];

  const ButtonCreate = (
    <ActionButton
      disabled={!pulse?.name}
      type="create"
      onClick={() => navigate(Paths.pulseScheduleCreate())}
    />
  );

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable dataSource={data} columns={columns} onRow={onRowHandler} loading={loading} />
    </Content>
  );
};

import React from 'react';
import { Divider, Form, Input, Select, Switch, InputNumber, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { equals } from 'ramda';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { IScheduleValues } from '../../types';
import { formItemClass } from '../../../../theme';
import { RangePicker } from '../../../../components/DatePicker';
import { Space } from '../../../../components/Space';
import { FixedBottomBar } from '../../../../components/FixedBottomBar';
import { ActionButton } from '../../../../components/ActionButton';
import { FormActions } from '../../../../components/FormActions';
import { FormTimezoneSelector } from '../../../../components/FormTimezoneSelector';
import { DiscardChangesModal } from '../../../../components/DiscardChangesModal';
import { IncomingPhoneNumberSelector } from '../../../../components/IncomingPhoneNumberSelector';
import { Description } from '../../Pulse.styles';
import { useModal } from '../../../../hooks/useModal';
import { validateMessages } from '../../../../utils/validation';
import { DateSelector } from './DateSelector';
import { TimeSchedulePicker } from './TimeSchedulePicker';
import { Paths } from '../../../../types/paths';
const { Option } = Select;

type Props = {
  initialValues: Partial<IScheduleValues>;
  onSubmit: (values: IScheduleValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
};

export const SchedulesForm: React.FC<Props> = ({ initialValues, submitButton, onSubmit }) => {
  const { t } = useTranslation('pulse');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue(true))) {
      openModal();
    } else {
      navigate(Paths.pulseSchedules());
    }
  };

  const handleValidation = (
    field: 'startDate' | 'endDate' | 'start_time' | 'end_time',
    day?: string,
  ): Promise<void> => {
    const isTimeField = field.includes('time');
    const startField = isTimeField ? `start_time_${day}` : 'startDate';
    const endField = isTimeField ? `end_time_${day}` : 'endDate';

    const startValue = isTimeField
      ? form.getFieldValue(['scheduleTimes', startField])
      : form.getFieldValue(startField);
    const endValue = isTimeField
      ? form.getFieldValue(['scheduleTimes', endField])
      : form.getFieldValue(endField);

    if (!startValue || !endValue) return Promise.resolve();

    const format = isTimeField ? 'h:mm A' : 'DD/MM/YYYY';
    const start = dayjs(startValue, format);
    const end = dayjs(endValue, format);

    if (!isTimeField && field === 'startDate' && start.isAfter(end)) {
      return Promise.reject(t('error_start_date'));
    }
    if (!isTimeField && field === 'endDate' && end.isBefore(start)) {
      return Promise.reject(t('error_end_date'));
    }
    if (isTimeField && field === 'start_time' && start.isAfter(end)) {
      return Promise.reject(t('error_start_time'));
    }
    if (isTimeField && field === 'end_time' && end.isBefore(start)) {
      return Promise.reject(t('error_end_time'));
    }

    const clearRelatedFieldErrors = () => {
      const customField = isTimeField ? `${field}_${day}` : field;
      const relatedField = customField === startField ? endField : startField;
      form.setFields([
        { name: isTimeField ? ['scheduleTimes', relatedField] : relatedField, errors: [] },
      ]);
    };

    setTimeout(clearRelatedFieldErrors, 0);

    return Promise.resolve();
  };

  const onFinish = () => {
    const data = form.getFieldsValue(true);
    onSubmit(data, form.setFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="enabled"
        label={t('enabled')}
        className={formItemClass.base}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        name="serviceName"
        label={t('service_name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('service_name')} />
      </Form.Item>
      <Divider />
      <Space>
        <Form.Item
          name="fromN"
          label={t('caller_from')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <IncomingPhoneNumberSelector channelType="voice" placeholder={t('caller_from')} />
        </Form.Item>
        <Form.Item
          name="toN"
          label={t('caller_to')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('caller_to')} />
        </Form.Item>
      </Space>
      <Form.Item
        name="pathCode"
        label={t('path_code')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        tooltip={t('path_code_tooltip')}
      >
        <StyledSelect mode="tags" placeholder={t('path_code')} />
      </Form.Item>
      <Divider />
      <b>{t('schedule_period')} </b>
      <Description>{t('schedule_period_description')}</Description>
      <Form.Item
        name="timezone"
        label={t('timezone')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
      >
        <FormTimezoneSelector showSearch placeholder={t('select_timezone')} />
      </Form.Item>
      <DateSelector handleValidation={handleValidation} />
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) => prev.exceptionPeriod !== curr.exceptionPeriod}
      >
        {({ getFieldValue }) => (
          <Form.Item
            name="exceptionPeriod"
            label={t('exception_period')}
            className={formItemClass.base}
            rules={[{ required: false }]}
          >
            <RangePicker
              placeholder={[t('start_date'), t('end_date')]}
              allowClear
              disabledDate={current =>
                current.subtract(1, 'days') < getFieldValue('startDate') ||
                current.isAfter(getFieldValue('endDate'))
              }
            />
          </Form.Item>
        )}
      </Form.Item>
      <Divider />
      <b>{t('call_recurrence')} </b>
      <Description>{t('call_recurrence_description')}</Description>
      <Form.Item
        name="repeatPeriod"
        label={t('repeat_call')}
        className={formItemClass.base}
        rules={[
          { required: true },
          {
            validator: async (_, repeatPeriod) => {
              const executionType = form.getFieldValue('executionType');
              const retry = form.getFieldValue('retry');
              if (retry > 0 && repeatPeriod < 300) {
                return Promise.reject(t('error_repeat_period_retry'));
              }
              if (executionType === 'twilioflow' && repeatPeriod < 30) {
                return Promise.reject(t('error_repeat_period_type'));
              }
            },
          },
        ]}
      >
        <InputNumber placeholder={t('repeat_call')} min={0} />
      </Form.Item>
      <Form.Item
        name="retry"
        label={t('retry')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <InputNumber min={0} max={2} placeholder={t('number_of_retries')} />
      </Form.Item>
      <Divider />
      <TimeSchedulePicker handleValidation={handleValidation} />
      <Divider />
      <Form.Item
        name="recording"
        label={t('recording')}
        className={formItemClass.small}
        tooltip={t('recording_tooltip')}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="executionType"
        label={t('execution_type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
        tooltip={t('execution_type_tooltip')}
      >
        <Select>
          <Option value="twilioflow">{t('flow_sid')}</Option>
          <Option value="twiml">{t('twiml')}</Option>
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.executionType !== curr.executionType}>
        {({ getFieldValue }) =>
          getFieldValue('executionType') === 'twilioflow' ? (
            <>
              <Form.Item
                name="flowSid"
                label={t('flow_sid')}
                className={formItemClass.base}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('flow_sid')} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="twiml"
                label={t('twiml')}
                className={formItemClass.base}
                rules={[{ required: true }]}
              >
                <Input.TextArea placeholder={t('twiml')} />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selection-item .ant-select-selection-item-content {
    font-size: 14px;
    text-transform: initial;
    font-weight: 100;
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ReportsTable } from '../../../ReportsTable';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { AnalyticsService } from '../../../../../services/analytics';
import { ILookerItem } from '../../types';

export const FAReportsList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { isLoading, data } = useQuery<ILookerItem[]>(
    [AnalyticsService.queryKeyFAReports],
    ({ signal }) => AnalyticsService.getFAReports(signal),
  );

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('reports')}</UppercaseLinkBold>
      <ReportsTable loading={isLoading} reports={data || []} />
    </Content>
  );
};

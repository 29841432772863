import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { NLUProvidersNew } from '../NLUProvidersNew';
import { DFAgentType, INLUAgent } from '../types';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';

interface IParameterModal {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setNewNLU: (nlu: INLUAgent) => void;
  platformEdition?: DFAgentType;
}

export const NLUProvidersModal: React.FC<IParameterModal> = ({
  isVisible,
  setIsVisible,
  setNewNLU,
  platformEdition,
}) => {
  const { t } = useTranslation('ai');

  const handleCreateNLU = (item: INLUAgent) => {
    setNewNLU(item);
    setIsVisible(false);
  };

  const closeModal = () => setIsVisible(false);

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} /> {t('new_nlu_provider')}
        </>
      }
      width={848}
      centered
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
      destroyOnClose={true}
    >
      <NLUProvidersNew
        platformEdition={platformEdition}
        isModal={true}
        closeModal={closeModal}
        handleCreateNLU={handleCreateNLU}
      />
    </Modal>
  );
};

import styled from '@emotion/styled';
import { Col } from 'antd';

const Section = styled.section`
  padding: 40px;
  &:nth-of-type(odd) {
    background-color: #fff;
  }
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.lightBg};
    text-align: center;
  }
  h1 {
    font-size: 30px;
    @media (min-width: 1400px) {
      font-size: 36px;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    @media (min-width: 1400px) {
      font-size: 24px;
    }
  }
`;

const Paragraph = ({ maxWidth, description }: { maxWidth: number; description: string }) => {
  return <StyledParagraph maxWidth={maxWidth}>{description}</StyledParagraph>;
};

const StyledParagraph = styled.div<{ maxWidth: number }>`
  margin: auto;
  max-width: ${props => props.maxWidth}px;
  font-size: 14px;
  @media (min-width: 1400px) {
    font-size: 16px;
  }
`;

const MainSection = ({
  img,
  title,
  description,
  maxHeight,
}: {
  img: string;
  title: string;
  description: string;
  maxHeight: number;
}) => {
  return (
    <>
      <Col xs={24} sm={24} md={14}>
        <h1>{title}</h1>
        {description}
      </Col>
      <Col xs={24} sm={24} md={10}>
        <img alt={title} src={img} style={{ maxHeight: maxHeight }} />
      </Col>
    </>
  );
};

const SubSection = ({
  left,
  right,
  img,
  title,
  description,
  maxHeight,
}: {
  left?: boolean;
  right?: boolean;
  img: string;
  title: string;
  description: string;
  maxHeight: number;
}) => {
  return (
    <>
      {left && (
        <>
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 10, order: 1 }}>
            <img alt={title} src={img} style={{ maxHeight: maxHeight }} />
          </Col>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 14, order: 2 }}>
            <h3>{title}</h3>
            {description}
          </Col>
        </>
      )}
      {right && (
        <>
          <Col xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 14, order: 3 }}>
            <h3>{title}</h3>
            {description}
          </Col>
          <Col xs={{ span: 24, order: 4 }} sm={{ span: 24, order: 4 }} md={{ span: 10, order: 4 }}>
            <img alt={title} src={img} style={{ maxHeight: maxHeight }} />
          </Col>
        </>
      )}
    </>
  );
};

export const StyledIntroCard = {
  Section,
  MainSection,
  SubSection,
  Paragraph,
};

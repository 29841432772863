import { Translation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Pulse } from './index';
import { PulseIntro } from './Intro';
import { SchedulesList } from './Schedules/SchedulesList';
import { SchedulesNew } from './Schedules/SchedulesNew';
import { SchedulesEdit } from './Schedules/SchedulesEdit';
import { InsightsIframe } from './Insights/InsightsIframe';

export const pulseRoutes = () => {
  return {
    path: Paths.pulse(),
    element: <Pulse />,
    breadcrumb: null,
    children: [
      {
        index: true,
        element: <Navigate replace to={Paths.pulseInsights()} />,
        breadcrumb: null,
      },
      {
        path: Paths.pulseIntro(),
        element: <PulseIntro />,
      },
      {
        path: Paths.pulseInsights(),
        element: <InsightsIframe />,
        breadcrumb: null,
      },
      {
        path: Paths.pulseSchedules(),
        element: <Outlet />,
        breadcrumb: () => <Translation ns={'pages_title'}>{t => t('schedules')}</Translation>,
        children: [
          { index: true, element: <SchedulesList /> },
          {
            path: Paths.pulseScheduleCreate(),
            element: <SchedulesNew />,
            breadcrumb: () => <Translation>{t => t('create')}</Translation>,
          },
          {
            path: Paths.pulseScheduleEdit(),
            element: <SchedulesEdit />,
            breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
          },
        ],
      },
    ],
  };
};

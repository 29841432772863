import React, { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Flex, Select, SelectProps, Typography } from 'antd';
import { NLUProvidersService } from '../../services/ai/nlu-providers';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { ProviderTag } from '../ProviderTag';
import { SelectWithFooter } from '../SelectWithFooter';
import { DFAgentType } from '../../pages/AI/Automation/NLUProviders/types';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { convertPlatformName, hasUUID } from '../../pages/AI/Automation/NLUProviders/utils';
import { useAccount } from '../../state/account';

const { Text } = Typography;

interface Props extends SelectProps {
  disabled?: boolean;
  openModal: () => void;
  platformEdition?: DFAgentType;
}

export const NLUAgentSelector: React.FC<Props> = ({
  openModal,
  disabled,
  platformEdition,
  ...rest
}) => {
  const { aiIntegration } = useIntegrations();
  const { isHumanbotProject } = useAccount();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('ai');

  const { data } = useQuery(
    [NLUProvidersService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      NLUProvidersService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const filteredData = useMemo(() => {
    if (isHumanbotProject) return data;

    if (platformEdition === 'CX') {
      return data?.filter(
        item => item.agent_source_type === 'DialogflowAgent' && hasUUID(item.agent_source),
      );
    }

    return data?.filter(
      item => item.agent_source_type === 'DialogflowAgent' && !hasUUID(item.agent_source),
    );
  }, [platformEdition, data]);

  const handleNewNLU = () => {
    setIsOpen(false);
    openModal();
  };

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewNLU}>
        <PlusIcon width={10} height={10} /> {t('new_nlu_provider')}
      </SelectWithFooter.Footer>
    </>
  );

  const options = useMemo(
    () =>
      filteredData?.map(item => {
        const platform = convertPlatformName(item) as string;
        return {
          label: (
            <Flex align={'center'}>
              <Text style={{ maxWidth: '80%', color: 'inherit' }} ellipsis>
                {item.name}
              </Text>
              <ProviderTag type={platform} disabled={disabled}>
                {platform}
              </ProviderTag>
            </Flex>
          ),
          value: isHumanbotProject ? item.id : item.agent_source?.id,
          display_name: item.name,
        };
      }),
    [filteredData, disabled],
  );

  return (
    <Select
      open={isOpen}
      showSearch
      optionFilterProp="display_name"
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      disabled={disabled}
      options={options}
      {...rest}
    />
  );
};

import { DFAgentType, IDFAgent, INLUAgent } from '../NLUProviders/types';
import { IChannels, IChannelsValues } from '../../Channels/Channel/types';
import { ITTSConfig } from '../TextToSpeech/types';
import { IContext } from '../Contexts/types';
import { IFallbackConfig } from '../../Configuration/FallbackBehaviour/types';
import { ISpeechToTextConfig } from '../SpeechToText/types';
import { IDLPTemplate } from '../../../Analytics/Configurations/DLPTemplate/types';

export type IntegrationType = 'standard' | 'ccai';

export type AutomationProfileType =
  | 'DialPlan'
  | 'AutomationProfileAgentic'
  | 'AutomationProfileHumanbot';

export enum AutomationProfileTypes {
  dialPlan = 'DialPlan',
  agentic = 'AutomationProfileAgentic',
  humanbot = 'AutomationProfileHumanbot',
}

interface ITTSItem {
  locale: string;
  text_to_speech_config: ITTSConfig;
}

export interface ITTSAttributes {
  text_to_speech_config_id?: number;
  locale?: string;
  _destroy?: boolean;
  id?: number;
}

export type AutoProfileAgenticFeature = 'email';

export interface IDialPlan {
  id: number;
  name: string;
  dialogflow_agent?: IDFAgent;
  dialogflow_conversation_profile_id?: number;
  analyze_query_text_sentiment?: boolean;
  dlp_config?: IDLPTemplate;
  fallback_config?: IFallbackConfig;
  locale?: string;
  text_to_speech_config?: ITTSConfig;
  audio_config?: { speech_model?: string };
  analytic_openai_summary_enabled?: boolean;
  openai_summary_enabled?: boolean;
  ccai_summary_enabled?: boolean;
  voice_recording_config?: {
    voice_recording_enabled?: boolean;
    stop_on_escalation?: boolean;
  };
  contexts?: IContext[];
  dialogflow_environment_uuid?: string;
  inbound_phone_numbers?: IChannels[];
  automation_profile_config?: IAutomationProfile;
}

export interface IAgentic {
  id: number;
  name: string;
  agentic_model?: {
    id: number;
    name: string;
    provider: string;
    model: string;
    api_key: string;
    updated_at: string;
  };
  product: AutoProfileAgenticFeature;
  config: string;
  inbound_phone_numbers?: IChannels[];
  automation_profile_config?: IAutomationProfile;
}

interface IHumanbotTTSConfig {
  automation_profile_humanbot_id: number;
  id: number;
  text_to_speech_config_id: number;
}

export interface IHumanBot {
  id: number;
  name: string;
  humanbot_tts_configs: IHumanbotTTSConfig[];
  text_to_speech_config?: ITTSConfig;
  speech_to_text_config?: ISpeechToTextConfig;
  fallback_config?: IFallbackConfig;
  inbound_phone_numbers?: IChannels[];
  automation_profile_config?: IAutomationProfile;
  text_to_speech_configs?: ITTSItem[];
  nlu_agent?: INLUAgent;
}

export interface IAutomationProfile {
  id: number;
  name: string;
  automation_profile_id: number;
  automation_profile_type: AutomationProfileType;
  updated_at: string;
  dial_plan?: IDialPlan;
  automation_profile_agentic?: IAgentic;
  automation_profile_humanbot?: IHumanBot;
}

export interface IAutomationProfileValues {
  name: string;
  automation_profile_type: AutomationProfileType;
  automation_profile_attributes: {
    integration_type?: IntegrationType;
    name?: string;
    platform_edition?: DFAgentType;
    id?: number;
    analyze_query_text_sentiment?: boolean;
    dialogflow_agent_id?: number | null;
    dialogflow_conversation_profile_id?: number | null;
    dlp_config_id?: number;
    context_ids?: number[];
    fallback_config_id?: number;
    text_to_speech_config_id?: number;
    humanbot_tts_configs_attributes?: ITTSAttributes[];
    speech_to_text_config_id?: number;
    locale?: string;
    audio_config_attributes?: { speech_model?: string };
    analytic_openai_summary_enabled?: boolean;
    openai_summary_enabled?: boolean;
    ccai_summary_enabled?: boolean;
    voice_recording_config_attributes?: {
      voice_recording_enabled?: boolean;
      stop_on_escalation?: boolean;
    };
    dialogflow_environment_uuid?: string;
    agentic_model_id?: number;
    product?: AutoProfileAgenticFeature;
    company?: string;
    priority?: string;
    categories?: string;
    branding?: string;
    personalisation?: string;
    config?: string;
    inbound_phone_numbers_attributes?: IChannelsValues[];
    nlu_agent_id?: number;
  };
}

import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../../../components/Space';
import { formItemClass } from '../../../../../../theme';

export const HumanBotFields = () => {
  const { t } = useTranslation('ai');

  return (
    <Space>
      <Form.Item
        name={['agent_source', 'api_key']}
        label={t('api_key')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('enter_api_key')} />
      </Form.Item>
      <Form.Item
        name={['agent_source', 'url']}
        label={t('url')}
        className={formItemClass.base}
        validateTrigger="onBlur"
        rules={[{ required: true }, { type: 'url' }]}
      >
        <Input placeholder={t('enter_url')} />
      </Form.Item>
    </Space>
  );
};

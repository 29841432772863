import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useIntegrations } from '../../state/integrations';
import { useAccount } from '../../state/account';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { Paths } from '../../types/paths';
import { OldKnowledgeIframe } from './OldKnowledgeIframe';
import { CenteredSpinner } from '../../components/Spinner';

export const OldKnowledge: React.FC = () => {
  const navigate = useNavigate();
  const { activeProjectId } = useAccount();
  const { oldKnowledge, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const { pathname } = useLocation();
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const url = oldKnowledge && oldKnowledge[0].urls.url;
  const isIntroPath = pathname === Paths.oldKnowledgeIntro();

  useEffect(() => {
    if (activeProjectId && !isFetching) {
      if (url) {
        setIsEnabled(true);
        navigate(Paths.oldKnowledge(), { replace: true });
      } else {
        setIsEnabled(false);
        navigate(Paths.oldKnowledgeIntro(), { replace: true });
      }
    }
  }, [url, activeProjectId, isFetching]);

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject && !isIntroPath) return <NoProject />;

  return (
    <>
      <OldKnowledgeIframe isEnabled={isEnabled} isIntroPath={isIntroPath} />
      <Outlet />
    </>
  );
};

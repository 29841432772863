import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { DataTable } from '../../../components/DataTable';
import { ILookerItem } from '../Reports/types';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { snakeToCapitalCase } from '../../../utils/string';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { useAnalyticsComponents } from '../../../hooks/useAnalyticsComponents';
import { useCurrentReport } from './useCurrentReport';

interface Props {
  reports: ILookerItem[];
  loading: boolean;
  handleRemove?: (id: string, componentId: number | string) => void;
}

export const ReportsTable: React.FC<Props> = React.memo(props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { queryKey, type } = useCurrentReport();
  const { reports, loading, handleRemove } = props;
  const { pathname } = location;
  const components = useAnalyticsComponents(queryKey);

  const componentFilters = components.map(item => ({
    text: snakeToCapitalCase(item),
    value: item,
  }));

  const onCellHandler = useCallback(
    (record: ILookerItem) => ({
      onClick: () =>
        navigate(`${pathname}/${record.id}`, {
          state: {
            name: record.name,
            type,
            ...(type !== 'fAReports' && { componentId: record.component.id }),
          },
        }),
    }),
    [navigate, pathname, type],
  );

  const columns: ColumnProps<ILookerItem>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: onCellHandler,
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '67%',
    },
    ...(type !== 'fAReports'
      ? ([
          {
            title: t('component', { ns: 'reports' }),
            dataIndex: ['component', 'name'],
            render: value => snakeToCapitalCase(value),
            onCell: onCellHandler,
            sorter: (a: ILookerItem, b: ILookerItem) =>
              a.component.name.localeCompare(b.component.name),
            filters: componentFilters.length > 1 ? componentFilters : undefined,
            filterIcon: () => <FilterIcon width={10} />,
            onFilter: (value, record) => record.component.name === value,
            width: '30%',
          },
        ] as ColumnProps<ILookerItem>[])
      : []),
    ...(handleRemove
      ? [
          {
            key: 'actions',
            width: '3%',
            render: (record: ILookerItem) => (
              <DeleteConfirmation
                name={record.name}
                onActionDelete={() => handleRemove(record.id, record.component.id)}
              />
            ),
            className: 'actions',
          },
        ]
      : []),
  ];

  return <DataTable loading={loading} dataSource={reports} columns={columns} />;
});

ReportsTable.displayName = 'ReportsTable';

import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useIntegrations } from '../../state/integrations';
import { NoProject } from '../../components/NoProject';
import useStateLoading from '../../hooks/useStateLoading';
import { Paths } from '../../types/paths';
import { AINav } from './AINav';
import { CenteredSpinner } from '../../components/Spinner';

export const Ai: React.FC = () => {
  const { aiIntegration, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isEnabled, setIsEnabled] = useState<boolean | undefined>();
  const isIntroPath = pathname === Paths.aiIntro();
  const isBasePath = pathname === Paths.ai();

  useEffect(() => {
    if (isProject && !isFetching) {
      if (aiIntegration?.url) {
        setIsEnabled(true);
        navigate(isIntroPath || isBasePath ? Paths.aiAutomation() : pathname, { replace: true });
      } else {
        setIsEnabled(false);
        navigate(Paths.aiIntro(), { replace: true });
      }
    }
  }, [aiIntegration?.url, isProject, isFetching]);

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject && !isIntroPath) return <NoProject />;

  return (
    <>
      {isEnabled && <AINav />}
      <Outlet />
    </>
  );
};

import { useState } from 'react';
import { Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { FallbackBehaviourSelector } from '../../../../../../components/FallbackBehaviourSelector';
import { NLUAgentSelector } from '../../../../../../components/NLUAgentSelector';
import { FallbackBehaviourModal } from '../../../../Configuration/FallbackBehaviour/FallbackBehaviourModal';
import { AudioConfig } from '../AudioConfig';
import { NLUProvidersModal } from '../../../NLUProviders/NLUProvidersModal';
import { INLUAgent } from '../../../NLUProviders/types';
import { normalizeToNull } from '../../../../../../utils/ai';
import { Space } from '../../../../../../components/Space';

export const HumanbotConfig = (): JSX.Element => {
  const { t } = useTranslation(['ai', 'project_settings']);
  const { setFieldValue } = Form.useFormInstance();
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisibleNLUModal, setIsVisibleNLUModal] = useState<boolean>(false);

  const setNewFallbackBehaviour = (id: number) => {
    setFieldValue(['automation_profile_attributes', 'fallback_config_id'], id);
  };

  const setNewNLU = (nlu: INLUAgent) =>
    setFieldValue(['automation_profile_attributes', 'nlu_agent_id'], nlu.id);

  return (
    <>
      <Space>
        <Form.Item
          name={['automation_profile_attributes', 'nlu_agent_id']}
          label={t('nlu_provider', { ns: 'project_settings' })}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <NLUAgentSelector
            openModal={() => setIsVisibleNLUModal(true)}
            placeholder={t('select_provider')}
          />
        </Form.Item>
        <NLUProvidersModal
          isVisible={isVisibleNLUModal}
          setIsVisible={setIsVisibleNLUModal}
          setNewNLU={setNewNLU}
        />
        <Form.Item
          name={['automation_profile_attributes', 'fallback_config_id']}
          label={t('fallback_behav')}
          className={formItemClass.base}
          normalize={normalizeToNull}
        >
          <FallbackBehaviourSelector
            placeholder={t('select_fallback_behaviour')}
            allowClear={true}
            openModal={() => setIsVisibleModal(true)}
          />
        </Form.Item>
        <FallbackBehaviourModal
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
          setNewFallbackBehaviour={setNewFallbackBehaviour}
        />
      </Space>
      <Divider className={formItemClass.full} />
      <AudioConfig />
    </>
  );
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { ChannelForm } from '../ChannelForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { ChannelType, ChannelTypes, IChannelsValues } from '../types';
import { InboundPhoneNumbersService } from '../../../../../services/ai/inbound-phone-numbers';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { AutomationProfileTypes } from '../../../Automation/AutomationProfiles/types';

export const ChannelNew: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { channel } = useParams() as { channel: string };

  const { mutate: createItem, isLoading } = useCreate(
    [InboundPhoneNumbersService.queryKey],
    InboundPhoneNumbersService.create,
  );

  const onSubmit = async (
    data: IChannelsValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          const channelType = data?.inbound_phone_number.channel_type;
          const phoneNumber = data?.inbound_phone_number.phone_number;
          notification.success({
            message: (
              <Trans t={t}>
                {t('channel_created_notify', {
                  channel:
                    channelType === ChannelTypes.facebook
                      ? t('channel_messenger')
                      : t(`channel_${channelType}`),
                  name: phoneNumber,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification.error(unexpectedError);
        },
      },
    );
  };

  const initialValues: IChannelsValues = {
    channel_type:
      channel !== ChannelTypes.all
        ? channel === ChannelTypes.messenger
          ? ChannelTypes.facebook
          : (channel as ChannelType)
        : undefined,
    dial_plan_type: AutomationProfileTypes.dialPlan,
  };

  return (
    <Content imgBg={false} breadcrumbParamTitle={t(`channel_${channel}`)}>
      <ChannelForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
        isChannel={!!(channel && channel !== ChannelTypes.all)}
      />
    </Content>
  );
};

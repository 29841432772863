import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps } from 'antd';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface AddFieldProps extends Omit<ButtonProps, 'children'> {
  buttonText: string;
}

export const AddField: React.FC<AddFieldProps> = ({ buttonText, ...other }): JSX.Element => (
  <StyledButton icon={<PlusIcon width={10} height={10} />} {...other}>
    {buttonText}
  </StyledButton>
);

const StyledButton = styled(Button)`
  margin-top: -12px;
  transition: ${({ theme }) => `all 0.2s ${theme.transitionFn}, margin 0s`};
  &:only-child {
    margin-top: 0;
  }
`;

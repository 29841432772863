import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg';
import { TextToSpeechNew } from '../TextToSpeechNew';
import { ITTSConfig } from '../types';

interface IParameterModal {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  setNewTTS: (tts: ITTSConfig) => void;
  activeLocale?: string;
}

export const TextToSpeechModal: React.FC<IParameterModal> = ({
  isVisible,
  setIsVisible,
  setNewTTS,
  activeLocale,
}) => {
  const { t } = useTranslation('ai');

  const handleCreateTTS = (item: ITTSConfig) => {
    setNewTTS(item);
    setIsVisible(false);
  };

  const closeModal = () => setIsVisible(false);

  return (
    <Modal
      title={
        <>
          <PlusIcon width={14} /> {t('new_tts_profile')}
        </>
      }
      width={848}
      centered
      open={isVisible}
      footer={false}
      onCancel={closeModal}
      styles={{ body: { overflowY: 'auto', maxHeight: '60vh', marginBottom: '46px' } }}
      destroyOnClose={true}
    >
      <TextToSpeechNew
        isModal={true}
        closeModal={closeModal}
        handleCreateTTS={handleCreateTTS}
        activeLocale={activeLocale}
      />
    </Modal>
  );
};

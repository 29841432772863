import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { PasswordInput } from '../../../../../../components/PasswordInput';
import { Space } from '../../../../../../components/Space';

export const LexFields = () => {
  const { t } = useTranslation('ai');

  return (
    <>
      <Space>
        <Form.Item
          name={['agent_source', 'agent_alias']}
          label={t('agent_alias')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_agent_alias')} />
        </Form.Item>
        <Form.Item
          name={['agent_source', 'agent_uuid']}
          label={t('agent_uuid')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_agent_uuid')} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['agent_source', 'access_key']}
          label={t('access_key')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_access_key')} />
        </Form.Item>
        <Form.Item
          name={['agent_source', 'secret_access_key']}
          label={t('secret_access_key')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <PasswordInput placeholder={t('enter_secret_access_key')} />
        </Form.Item>
      </Space>
    </>
  );
};

import { Form, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import { equals } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../../../../components/Space';
import { formItemClass } from '../../../../../../../theme';
import { InputWithRemove } from '../../../../../../../components/InputWithRemove';
import { TextToSpeechSelector } from '../../../../../../../components/TextToSpeechSelector';
import { AddField } from '../../../../../../../components/AddField';
import { ITTSAttributes } from '../../../types';
import { TextToSpeechModal } from '../../../../TextToSpeech/TextToSpeechModal';
import { ITTSConfig } from '../../../../TextToSpeech/types';
import { useTTSVoices } from '../../../../../../../hooks/useTTSVoices';
import { getLocaleOptions } from '../../../../TextToSpeech/TextToSpeechForm/utils/utils';

export const TextToSpeechList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { data: voices } = useTTSVoices();
  const { setFieldValue, getFieldValue } = Form.useFormInstance();
  const [isVisibleTTSModal, setIsVisibleTTSModal] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [activeLocale, setActiveLocale] = useState<string>();

  const humanbotTTSPath = ['automation_profile_attributes', 'humanbot_tts_configs_attributes'];
  const allLocaleOptions = useMemo(() => getLocaleOptions('all', voices), [voices]);

  const onLocaleChange = (index: number) => {
    setFieldValue([...humanbotTTSPath, index, 'text_to_speech_config_id'], undefined);
  };

  const setDestroyFlag = (index: number) => {
    const currentValues: ITTSAttributes[] = getFieldValue(humanbotTTSPath);
    const updatedValues = [...currentValues];
    updatedValues[index]._destroy = true;
    setFieldValue(humanbotTTSPath, updatedValues);
  };

  const openModal = (index: number) => () => {
    setActiveIndex(index);
    setActiveLocale(getFieldValue([...humanbotTTSPath, index, 'locale']));
    setIsVisibleTTSModal(true);
  };

  const setNewTTS = (tts: ITTSConfig) => {
    if (activeIndex === null) return;
    if (activeLocale === tts.locale) {
      setFieldValue([...humanbotTTSPath, activeIndex, 'text_to_speech_config_id'], tts.id);
      return;
    }
    const existingLocales = (getFieldValue(humanbotTTSPath) as ITTSAttributes[])
      .filter(item => !item._destroy && item.locale)
      .map(item => item.locale as string);
    if (!existingLocales.includes(tts.locale)) {
      setFieldValue([...humanbotTTSPath, activeIndex, 'locale'], tts.locale);
      setFieldValue([...humanbotTTSPath, activeIndex, 'text_to_speech_config_id'], tts.id);
    }
  };

  return (
    <>
      <Form.List name={['automation_profile_attributes', 'humanbot_tts_configs_attributes']}>
        {(fields, { add, remove }) => {
          const fieldValues: ITTSAttributes[] = getFieldValue(humanbotTTSPath);
          return (
            <>
              {fields.map((field, index) => {
                const fieldData = fieldValues?.[index];
                if (fieldData?._destroy) return null;
                return (
                  <Form.Item
                    key={field.key}
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      !equals(
                        prevValues.automation_profile_attributes.humanbot_tts_configs_attributes[
                          index
                        ],
                        currentValues.automation_profile_attributes.humanbot_tts_configs_attributes[
                          index
                        ],
                      )
                    }
                  >
                    {({ getFieldValue }) => {
                      const ttsConfigAttributes: ITTSAttributes[] = getFieldValue(humanbotTTSPath);
                      const selectedLocales = ttsConfigAttributes
                        .filter(item => !item._destroy && item.locale)
                        .map(item => item.locale as string);
                      const locale = getFieldValue([...humanbotTTSPath, index, 'locale']);
                      const isLast = index === fields.length - 1;
                      const localeOptions = allLocaleOptions.filter(
                        option =>
                          !selectedLocales.includes(option.value) || option.value === locale,
                      );

                      return (
                        <Space>
                          <Form.Item
                            name={[index, 'locale']}
                            label={t('locale')}
                            className={formItemClass.base}
                            rules={[{ required: true }]}
                            {...(isLast && { rootClassName: 'is-shifted-md' })}
                          >
                            <Select
                              showSearch
                              options={localeOptions}
                              placeholder={t('select_locale')}
                              onChange={() => onLocaleChange(index)}
                            />
                          </Form.Item>
                          <InputWithRemove
                            name={[index, 'text_to_speech_config_id']}
                            onClick={() => {
                              fieldData?.id ? setDestroyFlag(index) : remove(index);
                            }}
                            placeholder={t('select_profile')}
                            label={t('text_to_speech_profile')}
                            validateTrigger="onChange"
                            showRemove={!(fieldValues.filter(item => !item._destroy).length === 1)}
                            isShifted={isLast}
                            required
                          >
                            <TextToSpeechSelector
                              placeholder={t('select_profile')}
                              disabled={!locale}
                              locale={locale}
                              openModal={openModal(index)}
                            />
                          </InputWithRemove>
                        </Space>
                      );
                    }}
                  </Form.Item>
                );
              })}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  !equals(
                    prevValues.automation_profile_attributes.humanbot_tts_configs_attributes,
                    currentValues.automation_profile_attributes.humanbot_tts_configs_attributes,
                  )
                }
              >
                {({ getFieldValue }) => {
                  const humanbotTTSConfigs: ITTSAttributes[] = getFieldValue(humanbotTTSPath);
                  const hasMissingFields = humanbotTTSConfigs?.some(
                    config => !config.locale || !config.text_to_speech_config_id,
                  );

                  return (
                    <AddField onClick={add} buttonText={t('add_tts')} disabled={hasMissingFields} />
                  );
                }}
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <TextToSpeechModal
        isVisible={isVisibleTTSModal}
        setIsVisible={setIsVisibleTTSModal}
        setNewTTS={setNewTTS}
        activeLocale={activeLocale}
      />
    </>
  );
};

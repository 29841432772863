import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { NLUProvidersForm } from '../NLUProvidersForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { eslocations } from '../../../../../components/LocationSelect';
import { Paths } from '../../../../../types/paths';
import { AgentSourceType, DFAgentType, INLUAgent, INLUAgentValues } from '../types';

interface Props {
  isModal?: boolean;
  closeModal?: () => void;
  handleCreateNLU?: (agent: INLUAgent) => void;
  platformEdition?: DFAgentType;
}

export const NLUProvidersNew: React.FC<Props> = ({
  isModal,
  closeModal,
  handleCreateNLU,
  platformEdition,
}) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [NLUProvidersService.queryKey],
    NLUProvidersService.create,
  );

  const onSubmit = async (
    data: INLUAgentValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: (response: { nlu_agent: INLUAgent }) => {
          const nluAgent = response.nlu_agent;
          notification.success({
            message: (
              <Trans t={t}>{t('nlu_provider_created_notify', { name: nluAgent.name })}</Trans>
            ),
          });
          if (isModal && handleCreateNLU) {
            handleCreateNLU(nluAgent);
          } else navigate(Paths.aiNLUProviders());
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: Omit<INLUAgent, 'id'> = {
    name: '',
    agent_source_type: (platformEdition || 'ES') as AgentSourceType,
    agent_source: {
      ccai_enabled: false,
      location: eslocations[0],
      uuid: undefined,
      display_name: '',
      gcp_keyfile: undefined,
      gcp_project_name: '',
      gcp_fulfillment_auth_keyfile: null,
    },
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <NLUProvidersForm {...formProps} isModal={isModal} closeModal={closeModal} />
  ) : (
    <Content imgBg={false}>
      <NLUProvidersForm {...formProps} />
    </Content>
  );
};

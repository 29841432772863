import React from 'react';
import { Divider, Form, Input, Select, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { AgenticModelSelector } from '../../../../../../components/AgenticModelSelector';
import { autoProfileAgenticFeatures } from '../../../../../../constants';

const { TextArea } = Input;
const { Title } = Typography;

export const AgenticConfig = (): JSX.Element => {
  const { t } = useTranslation('ai');
  return (
    <>
      <Form.Item
        name={['automation_profile_attributes', 'agentic_model_id']}
        label={t('agentic_model')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <AgenticModelSelector placeholder={t('select_agentic_model')} />
      </Form.Item>
      <Form.Item
        name={['automation_profile_attributes', 'product']}
        label={t('feature')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select options={autoProfileAgenticFeatures} disabled={true} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('email_settings')}
      </Title>
      <Tabs
        defaultActiveKey="company_info"
        items={[
          {
            label: t('company_info'),
            key: 'company_info',
            children: (
              <Form.Item
                name={['automation_profile_attributes', 'company']}
                className={formItemClass.full}
              >
                <TextArea placeholder={t('ai:enter_info')} />
              </Form.Item>
            ),
          },
          {
            label: t('priority'),
            key: 'priority',
            children: (
              <Form.Item
                name={['automation_profile_attributes', 'priority']}
                className={formItemClass.full}
              >
                <TextArea placeholder={t('ai:enter_priority')} />
              </Form.Item>
            ),
          },
          {
            label: t('categories'),
            key: 'categories',
            children: (
              <Form.Item
                name={['automation_profile_attributes', 'categories']}
                className={formItemClass.full}
              >
                <TextArea placeholder={t('ai:enter_categories')} />
              </Form.Item>
            ),
          },
          {
            label: t('branding'),
            key: 'branding',
            children: (
              <Form.Item
                name={['automation_profile_attributes', 'branding']}
                className={formItemClass.full}
              >
                <TextArea placeholder={t('ai:enter_branding')} />
              </Form.Item>
            ),
          },
          {
            label: t('personalisation'),
            key: 'personalisation',
            children: (
              <Form.Item
                name={['automation_profile_attributes', 'personalisation']}
                className={formItemClass.full}
              >
                <TextArea placeholder={t('ai:enter_personalisation')} />
              </Form.Item>
            ),
          },
        ]}
      />
    </>
  );
};

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { AutomationProfileService } from '../../../../../services/ai/automation-profile';
import { IAutomationProfile } from '../types';
import { Paths } from '../../../../../types/paths';
import { NoWrap } from '../../../../../components/DataTable/DataTable.styles';
import { formatDate } from '../../../../../utils/date';
import { autoProfileTypeOptions } from '../../../../../constants';

export const AutomationProfilesList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiAutomationProfilesEdit({ id })),
  });

  const columns: ColumnProps<IAutomationProfile>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '50%',
    },
    {
      title: t('type'),
      onCell: onCellHandler,
      dataIndex: 'automation_profile_type',
      sorter: (a, b) => a.automation_profile_type.localeCompare(b.automation_profile_type),

      width: '27%',
      render: record => autoProfileTypeOptions.find(type => type.value === record)?.label || '-',
    },
    {
      title: t('date_modified'),
      onCell: onCellHandler,
      dataIndex: 'updated_at',
      width: '20%',
      render: date => (date ? <NoWrap>{formatDate(date)}</NoWrap> : ''),
      sorter: (a, b) => dayjs(a.updated_at).diff(b.updated_at, 'minute'),
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IAutomationProfile>
      service={AutomationProfileService}
      columns={columns}
      getCreatePath={Paths.aiAutomationProfilesCreate}
      deleteConfirmationTitle={t('delete_automation_profile')}
      getDisplayName={record => record.name}
    />
  );
};

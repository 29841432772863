import { Divider, Form, Input, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { Space } from '../../../../../../components/Space';
import { InputWithRemove } from '../../../../../../components/InputWithRemove';
import { AddField } from '../../../../../../components/AddField';
import { ChannelType } from '../../../../Channels/Channel/types';
import { channelIdentifiers } from '../../../../../../constants';
import { AutomationProfileType } from '../../types';
import { getChannelOptionsByProfile } from '../../utils';

const { Title } = Typography;

export const Channels = ({ profileType }: { profileType: AutomationProfileType }): JSX.Element => {
  const { t } = useTranslation('ai');
  const { getFieldValue, setFieldValue } = Form.useFormInstance();

  const resetPhoneNumber = (index: number) => {
    setFieldValue(
      ['automation_profile_attributes', 'inbound_phone_numbers_attributes', index, 'phone_number'],
      undefined,
    );
  };

  const setDestroyFlag = (index: number) => {
    const currentValues = getFieldValue([
      'automation_profile_attributes',
      'inbound_phone_numbers_attributes',
    ]);
    const updatedValues = [...currentValues];
    updatedValues[index]._destroy = true;

    setFieldValue(
      ['automation_profile_attributes', 'inbound_phone_numbers_attributes'],
      updatedValues,
    );
  };

  return (
    <>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('channels')}
      </Title>
      <Form.List name={['automation_profile_attributes', 'inbound_phone_numbers_attributes']}>
        {(fields, { add, remove }) => {
          const fieldValues = getFieldValue([
            'automation_profile_attributes',
            'inbound_phone_numbers_attributes',
          ]);
          return (
            <div>
              {fields.map((field, index) => {
                const fieldData = fieldValues?.[index];
                if (fieldData?._destroy) return null;
                return (
                  <Space key={field.key}>
                    <Form.Item
                      name={[index, 'channel_type']}
                      label={t('type')}
                      className={formItemClass.base}
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={getChannelOptionsByProfile(profileType)}
                        placeholder={t('select_channel_type')}
                        onChange={() => resetPhoneNumber(index)}
                      />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.automation_profile_attributes.inbound_phone_numbers_attributes[index]
                          ?.channel_type !==
                        next.automation_profile_attributes.inbound_phone_numbers_attributes[index]
                          ?.channel_type
                      }
                    >
                      {({ getFieldValue }) => {
                        const channelType: ChannelType = getFieldValue([
                          'automation_profile_attributes',
                          'inbound_phone_numbers_attributes',
                          index,
                          'channel_type',
                        ]);
                        return (
                          <InputWithRemove
                            name={[index, 'phone_number']}
                            onClick={() => {
                              fieldData?.id ? setDestroyFlag(index) : remove(index);
                            }}
                            placeholder={t(channelIdentifiers[channelType].placeholder)}
                            label={t('identifier')}
                            required
                            tooltip={t(channelIdentifiers[channelType].tooltip)}
                            channelType={channelType}
                            isShifted={index === fields.length - 1}
                          />
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      name={[index, 'dial_plan_type']}
                      initialValue={profileType}
                      hidden={true}
                    >
                      <Input />
                    </Form.Item>
                  </Space>
                );
              })}
              <AddField
                onClick={() => {
                  add({
                    channel_type: getChannelOptionsByProfile(profileType)[0].value as ChannelType,
                    dial_plan_type: profileType,
                  });
                }}
                buttonText={t('add_channel')}
              />
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

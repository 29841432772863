import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tag, TagProps } from 'antd';
import { DFAgentType } from '../../pages/AI/Automation/NLUProviders/types';
import { STTProvider } from '../../pages/AI/Automation/SpeechToText/types';

interface Props extends TagProps {
  type: Omit<DFAgentType, 'AgentSourceLex'> | 'LEX' | STTProvider;
  width?: number;
  disabled?: boolean;
}

export const ProviderTag = (props: Props): JSX.Element => {
  const { type, width, disabled, children, ...other } = props;
  return (
    <Root type={type} width={width} disabled={disabled} {...other}>
      {children}
    </Root>
  );
};

interface RootProps {
  type: Props['type'];
  width?: number;
  disabled?: boolean;
}

const Root = styled(Tag, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'disabled',
})<RootProps>`
  min-width: 38px;
  font-weight: 600;
  margin: auto 0 auto auto;
  text-align: center;

  ${props =>
    !!props.width &&
    css`
      width: ${props.width}px;
    `};

  ${props =>
    (props.type === 'CX' || props.type === 'SttSourceGoogle') &&
    css`
      border: 1px solid #5e1bb3;
      color: #5e1bb3;
      background-color: rgba(94, 27, 179, 0.1);
    `};

  ${props =>
    (props.type === 'ES' || props.type === 'SttSourceAzure') &&
    css`
      border: 1px solid #0047fa;
      color: #0047fa;
      background-color: rgba(0, 71, 250, 0.1);
    `};

  ${props =>
    props.type === 'HB' &&
    css`
      border: 1px solid #008bc9;
      color: #008bc9;
      background-color: rgba(0, 139, 201, 0.1);
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;

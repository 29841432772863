import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntegrations } from '../../state/integrations';
import { AnalyticsService } from '../../services/analytics';
import { useAccount } from '../../state/account';

export const useAnalyticsComponents = (queryKey: string): string[] => {
  const { aiIntegration, pulse } = useIntegrations();
  const { aiEmail, analyticsPersonalFolder } = useFlags();
  const { isHumanbotProject } = useAccount();
  const isPersonalFolder =
    analyticsPersonalFolder &&
    [AnalyticsService.queryKeyDashboards, AnalyticsService.queryKeyLooks].includes(queryKey);
  return [
    aiIntegration && 'airline',
    aiEmail && !isHumanbotProject && 'ai_email',
    pulse && 'pulse',
    isPersonalFolder && 'personal_folder',
  ].filter(Boolean);
};

import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { GenesysLocations } from '../../../../../constants';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';

export const GenesysConfig = (): JSX.Element => {
  const { t } = useTranslation('ai');

  return (
    <>
      <Space>
        <Form.Item
          name="integration_id"
          label={t('integration_id')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_integration_id')} />
        </Form.Item>
        <Form.Item
          name="client_id"
          label={t('client_id')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_client_id')} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name="client_secret"
          label={t('client_secret')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <PasswordInput placeholder={t('enter_client_secret')} />
        </Form.Item>
        <Form.Item
          name="region"
          label={t('cloud_region')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select placeholder={t('select_cloud_region')} options={GenesysLocations} />
        </Form.Item>
      </Space>
    </>
  );
};

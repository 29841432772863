import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import realTimeInsightsImg from '../../../assets/images/real-time-insights.png';
import customVisualisationsImg from '../../../assets/images/custom-visualisations.png';
import dataManagementImg from '../../../assets/images/data-management.png';
import flexibleDataImg from '../../../assets/images/team-manager.png';
import { Content } from '../../../components/Content';
import { StyledIntroCard } from '../../../components/StyledIntroCard';

export const AnalyticsIntro: React.FC = () => {
  const { t } = useTranslation('reports');

  return (
    <Content imgBg={false} header={false}>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, 20]}>
          <StyledIntroCard.MainSection
            img={realTimeInsightsImg}
            title={t('intro_realtime_title')}
            description={t('intro_realtime_description')}
            maxHeight={360}
          />
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[0, 20]}>
          <Col span={24}>
            <h3>{t('intro_unlocking_title')}</h3>
            <StyledIntroCard.Paragraph
              maxWidth={1056}
              description={t('intro_unlocking_description')}
            />
          </Col>
          <Col span={24}>
            <img
              alt={t('intro_unlocking_title')}
              src={customVisualisationsImg}
              style={{ maxHeight: 354 }}
            />
          </Col>
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, { xs: 20, sm: 20, md: 0 }]}>
          <StyledIntroCard.SubSection
            left
            img={dataManagementImg}
            title={t('intro_empower_title')}
            description={t('intro_empower_description')}
            maxHeight={240}
          />
          <StyledIntroCard.SubSection
            right
            img={flexibleDataImg}
            title={t('intro_flexible_title')}
            description={t('intro_flexible_description')}
            maxHeight={282}
          />
        </Row>
      </StyledIntroCard.Section>
    </Content>
  );
};

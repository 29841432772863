import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntegrations } from '../../state/integrations';
import { OverlaySpinner } from '../../components/Spinner';

export const OldKnowledgeIframe = ({
  isEnabled,
  isIntroPath,
}: {
  isEnabled?: boolean;
  isIntroPath?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('pages_title');
  const { oldKnowledge } = useIntegrations();
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);
  const url = oldKnowledge?.[0].urls.url;

  return (
    <>
      {isEnabled && !isIntroPath && (
        <>
          {oldKnowledge && (
            <iframe
              title={t('knowledge').toLowerCase()}
              src={url}
              onLoad={() => setIsIframeLoading(false)}
              aria-hidden="true"
            />
          )}
          <OverlaySpinner loading={isIframeLoading} />
        </>
      )}
    </>
  );
};

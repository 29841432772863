import { DFAgentType, IDFAgent } from '../../Automation/NLUProviders/types';

export type DFAgentTypeValues = {
  id: number;
  gcpProjectName: string;
  platform_edition: DFAgentType;
  location: string;
  uuid: string;
};

export type SuggestionFeatureType = 'DIALOGFLOW_ASSIST' | 'FAQ';

export enum SuggestionFeatureTypes {
  FAQ = 'FAQ',
  DIALOGFLOW_ASSIST = 'DIALOGFLOW_ASSIST',
}

export interface IConversationProfile {
  id: number;
  dialogflow_data: {
    display_name: string;
    logging_config?: {
      enable_stackdriver_logging?: boolean;
    };
    human_agent_assistant_config?: {
      enabled?: boolean;
      human_agent_suggestion_config?: {
        feature_configs?: IFeatureConfigs[];
        group_suggestion_responses?: boolean;
      };
    };
  };
  dialogflow_agent: IDFAgent;
}

export interface IConversationProfileValues {
  display_name?: string;
  dialogflow_agent_id?: number;
  gcpProjectName?: string;
  platform_edition?: DFAgentType;
  location?: string;
  uuid?: string | null;
  automated_agent_config?: {
    agent: string;
  };
  human_agent_assistant_config?: {
    enabled?: boolean;
    human_agent_suggestion_config?: {
      feature_configs?: IFeatureConfigs[];
      group_suggestion_responses?: boolean;
    };
  };
  logging_config?: {
    enable_stackdriver_logging?: boolean;
  };
}

interface IFeatureConfigs {
  suggestion_feature?: {
    type?: SuggestionFeatureType;
  };
  enable_inline_suggestion?: boolean;
  enable_event_based_suggestion?: boolean;
  query_config?: {
    dialogflow_query_source?: {
      agent?: string;
    };
    knowledge_base_query_source?: {
      knowledge_bases?: string[];
    };
  };
}

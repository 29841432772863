import React from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { Content } from '../../../../../components/Content';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { useIntegrations } from '../../../../../state/integrations';
import { useCreate } from '../../../../../hooks/useCreate';
import { TextToSpeechForm } from '../TextToSpeechForm';
import { TextToSpeechService } from '../../../../../services/ai/text-to-speech';
import { ITTSConfig, ITTSValues } from '../types';
import { ActionButton } from '../../../../../components/ActionButton';
import { Paths } from '../../../../../types/paths';

interface Props {
  isModal?: boolean;
  closeModal?: () => void;
  handleCreateTTS?: (tts: ITTSConfig) => void;
  activeLocale?: string;
}

export const TextToSpeechNew: React.FC<Props> = ({
  isModal,
  closeModal,
  handleCreateTTS,
  activeLocale,
}) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [TextToSpeechService.queryKey],
    TextToSpeechService.create,
  );

  const onSubmit = async (
    data: ITTSValues,
    setFields: FormInstance['setFields'],
    resetFields: FormInstance['resetFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: (response: { text_to_speech_config: ITTSConfig }) => {
          const ttsConfig = response.text_to_speech_config;
          notification.success({
            message: (
              <Trans t={t}>{t('tts_profile_created_notify', { name: ttsConfig.name })}</Trans>
            ),
          });
          if (isModal && handleCreateTTS) {
            handleCreateTTS(ttsConfig);
            resetFields();
          } else navigate(Paths.aiTextToSpeech());
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: ITTSValues = {
    name: '',
    tts_source_type: 'TtsSourceGoogle',
    tts_source_attributes: {
      locale: activeLocale || 'en-GB',
      voice: undefined,
      speaking_rate: 1.0,
      pitch: 0.0,
      gcp_keyfile: undefined,
    },
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <TextToSpeechForm {...formProps} isModal={isModal} closeModal={closeModal} />
  ) : (
    <Content imgBg={false}>
      <TextToSpeechForm {...formProps} />
    </Content>
  );
};

import { Select, SelectProps } from 'antd';
import { cxLocales, esLocales, humanbotLocales } from '../../constants';
import { DFAgentType } from '../../pages/AI/Automation/NLUProviders/types';

interface Props extends SelectProps {
  platform?: DFAgentType | 'Humanbot';
}

const getLocalesList = (platform: Props['platform']) => {
  switch (platform) {
    case 'ES':
      return esLocales;
    case 'CX':
      return cxLocales;
    case 'Humanbot':
      return humanbotLocales;
    default:
      return [];
  }
};

export const LocaleSelector: React.FC<Props> = ({ platform, ...other }) => {
  const options = getLocalesList(platform).map(item => ({ label: item, value: item }));

  return <Select options={options} showSearch optionFilterProp="label" {...other} />;
};

import styled from '@emotion/styled';

export const StyledInputWithRemove = styled.div`
  position: relative;
  @media (min-width: 475px) and (max-width: 875px) {
    width: 400px;
  }
`;

export const MinusContainer = styled.div`
  top: 24px;
  position: absolute;
  left: 364px;
  width: ${({ theme }) => theme.inputHeight};
  height: ${({ theme }) => theme.inputHeight};
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondaryBg};
  }
  @media (max-width: 475px) {
    top: -16px;
    margin-bottom: 20px;
    position: relative;
    left: 0;
  }
`;

import React from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { Content } from '../../../../../components/Content';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { useIntegrations } from '../../../../../state/integrations';
import { useCreate } from '../../../../../hooks/useCreate';
import { SpeechToTextForm } from '../SpeechToTextForm';
import { SpeechToTextService } from '../../../../../services/ai/speech-to-text';
import { ActionButton } from '../../../../../components/ActionButton';
import { ISTTValues } from '../types';
import { Paths } from '../../../../../types/paths';

const initialValues: ISTTValues = {
  name: '',
  stt_source_type: 'SttSourceAzure',
  stt_source_attributes: {
    bargein: false,
    complete_timeout: 1.5,
    timeout: 5,
    interdigit_timeout: 5,
    maxspeech_timeout: 15,
  },
};

interface Props {
  isModal?: boolean;
  closeModal?: () => void;
  handleCreateSTT?: (id: number) => void;
}

export const SpeechToTextNew: React.FC<Props> = ({ isModal, closeModal, handleCreateSTT }) => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const { mutate: createItem, isLoading } = useCreate(
    [SpeechToTextService.queryKey],
    SpeechToTextService.create,
  );

  const onSubmit = async (
    data: ISTTValues,
    setFields: FormInstance['setFields'],
    resetFields: FormInstance['resetFields'],
  ): Promise<void> => {
    createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: response => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('stt_profile_created_notify', { name: response.speech_to_text_config.name })}
              </Trans>
            ),
          });
          if (isModal && handleCreateSTT) {
            handleCreateSTT(response.speech_to_text_config.id);
            resetFields();
          } else navigate(Paths.aiSpeechToText());
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification.error(unexpectedError);
        },
      },
    );
  };

  const formProps = {
    initialValues,
    onSubmit,
    submitButton: <ActionButton type="create" htmlType="submit" loading={isLoading} />,
  };

  return isModal ? (
    <SpeechToTextForm {...formProps} isModal={isModal} closeModal={closeModal} />
  ) : (
    <Content imgBg={false}>
      <SpeechToTextForm {...formProps} />
    </Content>
  );
};

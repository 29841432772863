import React, { useEffect, useState } from 'react';
import { Form, Input, Select, FormInstance, SelectProps } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { Space } from '../../../../../components/Space';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions, FormActionsModal } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ITTSValues, TTSSourceType } from '../types';
import { getGCPKeyfile } from '../../../../../utils/files';
import { getProviderName, getLocaleOptions, getVoiceOptions } from './utils/utils';
import { useAccount } from '../../../../../state/account';
import { GoogleFields } from './GoogleFields';
import { AmazonHBFields } from './AmazonHBFields';
import { useTTSVoices } from '../../../../../hooks/useTTSVoices';

interface Props {
  initialValues: ITTSValues;
  onSubmit: (
    values: ITTSValues,
    setFields: FormInstance['setFields'],
    resetFields: FormInstance['resetFields'],
  ) => void;
  submitButton: React.ReactElement;
  isModal?: boolean;
  closeModal?: () => void;
}

const providerOptions = [
  { label: 'Google', value: 'TtsSourceGoogle' },
  { label: 'Amazon', value: 'TtsSourceAmazon' },
  { label: 'Azure', value: 'TtsSourceAzure' },
];

export const TextToSpeechForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isModal,
  closeModal,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal: openDiscardModal, closeModal: closeDiscardModal } = useModal(false);
  const { isHumanbotProject } = useAccount();
  const [localeOptions, setLocaleOptions] = useState<SelectProps['options']>([]);
  const [voiceOptions, setVoiceOptions] = useState<SelectProps['options']>([]);
  const { data: voices } = useTTSVoices();

  useEffect(() => {
    if (initialValues.tts_source_attributes?.locale && initialValues.tts_source_type) {
      const initialProvider = getProviderName(initialValues.tts_source_type);
      setLocaleOptions(getLocaleOptions(initialProvider, voices));

      const initialLocale = initialValues.tts_source_attributes.locale;
      setVoiceOptions(getVoiceOptions(initialProvider, initialLocale, voices));
    }
  }, [initialValues, voices]);

  const handleCancel = () => {
    if (isModal) {
      closeModal?.();
      form.resetFields();
    } else {
      if (!equals(initialValues, form.getFieldsValue(true))) {
        openDiscardModal();
      } else {
        navigate(-1);
      }
    }
  };

  const handleProviderChange = (value: TTSSourceType) => {
    const provider = getProviderName(value);
    const localeOptions = getLocaleOptions(provider, voices);
    setLocaleOptions(localeOptions || []);

    const currentLocale = form.getFieldValue(['tts_source_attributes', 'locale']);
    const isCurrentLocaleExist = localeOptions?.some(option => option.value === currentLocale);
    if (currentLocale && provider) {
      const locale = isCurrentLocaleExist ? currentLocale : 'en-GB';
      const voicesOptions = getVoiceOptions(provider, locale, voices);
      setVoiceOptions(voicesOptions || []);
      form.setFieldsValue({
        tts_source_attributes: { locale, voice: undefined, region: undefined },
      });
    }
  };

  const handleLocaleChange = (value: string) => {
    const provider = getProviderName(form.getFieldValue('tts_source_type'));
    const voicesOptions = getVoiceOptions(provider, value, voices);
    setVoiceOptions(voicesOptions);
    form.setFieldValue(['tts_source_attributes', 'voice'], undefined);
  };

  const onFinish = async () => {
    const formData = form.getFieldsValue();
    if (formData.tts_source_type === 'TtsSourceGoogle' && isHumanbotProject) {
      if (formData.tts_source_attributes.gcp_keyfile) {
        formData.tts_source_attributes.gcp_keyfile = await getGCPKeyfile(
          formData.tts_source_attributes.gcp_keyfile,
        );
      } else {
        formData.tts_source_attributes.gcp_keyfile =
          initialValues.tts_source_attributes.gcp_keyfile;
      }
    }
    onSubmit(formData, form.setFields, form.resetFields);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
      name="tts-form"
    >
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('profile_name')} />
      </Form.Item>
      <Form.Item name="tts_source_type" label={t('provider')} className={formItemClass.base}>
        <Select
          options={providerOptions}
          disabled={!isHumanbotProject}
          onChange={handleProviderChange}
        />
      </Form.Item>
      <Space>
        <Form.Item
          name={['tts_source_attributes', 'locale']}
          label={t('locale')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            options={localeOptions}
            placeholder={t('select_locale')}
            onChange={handleLocaleChange}
          />
        </Form.Item>
        <Form.Item
          name={['tts_source_attributes', 'voice']}
          label={t('voice')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Select showSearch options={voiceOptions} placeholder={t('select_voice')} />
        </Form.Item>
      </Space>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.tts_source_type !== currentValues.tts_source_type
        }
      >
        {({ getFieldValue }) => {
          const provider = getFieldValue('tts_source_type');
          return (
            <>
              {provider === 'TtsSourceGoogle' && (
                <GoogleFields
                  isHBProject={isHumanbotProject}
                  initKeyfile={initialValues.tts_source_attributes.gcp_keyfile}
                />
              )}
              {(provider === 'TtsSourceAmazon' || provider === 'TtsSourceAzure') && (
                <AmazonHBFields provider={provider} data={voices} />
              )}
            </>
          );
        }}
      </Form.Item>
      {isModal ? (
        <FormActionsModal>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActionsModal>
      ) : (
        <>
          <FixedBottomBar>
            <FormActions>
              <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
              {submitButton}
            </FormActions>
          </FixedBottomBar>
          <DiscardChangesModal visible={visible} closeModal={closeDiscardModal} />
        </>
      )}
    </Form>
  );
};

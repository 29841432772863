import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { AgenticModelsService } from '../../services/ai/agentic-models';

export const AgenticModelSelector: React.FC<SelectProps> = props => {
  const { aiIntegration } = useIntegrations();
  const { data } = useQuery(
    [AgenticModelsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      AgenticModelsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const options = data?.map(item => ({ value: item.id, label: item.name }));

  return <Select {...props} options={options} />;
};

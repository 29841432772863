import { Translation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Ai } from './index';
import { AIIntro } from './Intro';
import { Automation } from './Automation';
import { AutomationList } from './Automation/AutomationList';
import { ContextsList } from './Automation/Contexts/ContextsList';
import { ContextNew } from './Automation/Contexts/ContextNew';
import { ContextEdit } from './Automation/Contexts/ContextEdit';
import { NLUProvidersList } from './Automation/NLUProviders/NLUProvidersList';
import { NLUProvidersNew } from './Automation/NLUProviders/NLUProvidersNew';
import { NLUProvidersEdit } from './Automation/NLUProviders/NLUProvidersEdit';
import { TextToSpeechList } from './Automation/TextToSpeech/TextToSpeechList';
import { TextToSpeechNew } from './Automation/TextToSpeech/TextToSpeechNew';
import { TextToSpeechEdit } from './Automation/TextToSpeech/TextToSpeechEdit';
import { SpeechToTextList } from './Automation/SpeechToText/SpeechToTextList';
import { SpeechToTextNew } from './Automation/SpeechToText/SpeechToTextNew';
import { SpeechToTextEdit } from './Automation/SpeechToText/SpeechToTextEdit';
import { AutomatedResponseTemplatesList } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesList';
import { AutomatedResponseTemplatesNew } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesNew';
import { AutomatedResponseTemplatesEdit } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesEdit';
import { ConversationProfilesList } from './Automation/ConversationProfiles/ConversationProfilesList';
import { ConversationProfilesNew } from './Automation/ConversationProfiles/ConversationProfilesNew';
import { ConversationProfilesEdit } from './Automation/ConversationProfiles/ConversationProfilesEdit';
import { AutomationProfilesList } from './Automation/AutomationProfiles/AutomationProfilesList';
import { AutomationProfilesNew } from './Automation/AutomationProfiles/AutomationProfilesNew';
import { AutomationProfilesEdit } from './Automation/AutomationProfiles/AutomationProfilesEdit';
import { AgenticModelsList } from './Automation/AgenticModels/AgenticModelsList';
import { AgenticModelsNew } from './Automation/AgenticModels/AgenticModelsNew';
import { AgenticModelsEdit } from './Automation/AgenticModels/AgenticModelsEdit';
import { Channels } from './Channels';
import { ChannelsList } from './Channels/ChannelsList';
import { ChannelList } from './Channels/Channel/ChannelList';
import { ChannelNew } from './Channels/Channel/ChannelNew';
import { ChannelEdit } from './Channels/Channel/ChannelEdit';
import { Configuration } from './Configuration';
import { ConfigurationList } from './Configuration/ConfigurationList';
import { ConfigsList } from './Configuration/Design/ConfigsList';
import { VirtualAssistant } from './Configuration/Design/VirtualAssistant';
import { FallbackBehaviourList } from './Configuration/FallbackBehaviour/FallbackBehaviourList';
import { FallbackBehaviourNew } from './Configuration/FallbackBehaviour/FallbackBehaviourNew';
import { FallbackBehaviourEdit } from './Configuration/FallbackBehaviour/FallbackBehaviourEdit';
import { KnowledgeBasesList } from './Configuration/KnowledgeBases/KnowledgeBasesList';
import { KnowledgeBasesNew } from './Configuration/KnowledgeBases/KnowledgeBasesNew';
import { KnowledgeBasesEdit } from './Configuration/KnowledgeBases/KnowledgeBasesEdit';
import { KnowledgeBaseDocumentList } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentList';
import { KnowledgeBaseDocumentNew } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentNew';
import { KnowledgeBaseDocumentEdit } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentEdit';
import { Routing } from './Routing';
import { RoutingList } from './Routing/RoutingList';
import { ExternalEndpointsList } from './Routing/ExternalEndpoints/ExternalEnpointsList';
import { ExternalEndpointsNew } from './Routing/ExternalEndpoints/ExternalEndpointsNew';
import { ExternalEndpointsEdit } from './Routing/ExternalEndpoints/ExternalEndpointsEdit';
import { TeamsList } from './Routing/Teams/TeamsList';
import { TeamsNew } from './Routing/Teams/TeamsNew';
import { TeamsEdit } from './Routing/Teams/TeamsEdit';
import { RoutersList } from './Routing/Routers/RoutersList';
import { RoutersNew } from './Routing/Routers/RoutersNew';
import { RoutersEdit } from './Routing/Routers/RoutersEdit';
import { RestrictedPage } from '../../components/RestrictedPage';

export const aiRoutes = () => ({
  path: Paths.ai(),
  element: <Ai />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.aiAutomation()} />,
      breadcrumb: null,
    },
    {
      path: Paths.aiIntro(),
      element: <AIIntro />,
    },
    {
      path: Paths.aiAutomation(),
      element: <Automation />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('automation')}</Translation>,
      children: [
        { index: true, element: <AutomationList /> },
        {
          path: Paths.aiAutoRespTemplates(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiAutomation()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('auto_resp_templates')}</Translation>,
          children: [
            { index: true, element: <AutomatedResponseTemplatesList /> },
            {
              path: Paths.aiAutoRespTemplatesCreate(),
              element: <AutomatedResponseTemplatesNew />,
              breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiAutoRespTemplatesEdit(),
              element: <AutomatedResponseTemplatesEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiTextToSpeech(),
          element: <Outlet />,
          breadcrumb: () => (
            <Translation ns={'ai'}>{t => t('text_to_speech_profiles')}</Translation>
          ),
          children: [
            { index: true, element: <TextToSpeechList /> },
            {
              path: Paths.aiTextToSpeechCreate(),
              element: <TextToSpeechNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiTextToSpeechEdit(),
              element: <TextToSpeechEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiSpeechToText(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !isHumanbotProject}
              redirectTo={Paths.aiAutomation()}
            />
          ),
          breadcrumb: () => (
            <Translation ns={'ai'}>{t => t('speech_to_text_profiles')}</Translation>
          ),
          children: [
            { index: true, element: <SpeechToTextList /> },
            {
              path: Paths.aiSpeechToTextCreate(),
              element: <SpeechToTextNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiSpeechToTextEdit(),
              element: <SpeechToTextEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiNLUProviders(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('nlu_providers')}</Translation>,
          children: [
            { index: true, element: <NLUProvidersList /> },
            {
              path: Paths.aiNLUProvidersCreate(),
              element: <NLUProvidersNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiNLUProvidersEdit(),
              element: <NLUProvidersEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiContexts(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiAutomation()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('contexts')}</Translation>,
          children: [
            { index: true, element: <ContextsList /> },
            {
              path: Paths.aiContextCreate(),
              element: <ContextNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiContextEdit(),
              element: <ContextEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiConversationProfiles(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiAutomation()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('conversation_profiles')}</Translation>,
          children: [
            { index: true, element: <ConversationProfilesList /> },
            {
              path: Paths.aiConversationProfilesCreate(),
              element: <ConversationProfilesNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiConversationProfilesEdit(),
              element: <ConversationProfilesEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiAutomationProfiles(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('automation_profiles')}</Translation>,
          children: [
            { index: true, element: <AutomationProfilesList /> },
            {
              path: Paths.aiAutomationProfilesCreate(),
              element: <AutomationProfilesNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiAutomationProfilesEdit(),
              element: <AutomationProfilesEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiAgenticModels(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiAutomation()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('agentic_models')}</Translation>,
          children: [
            { index: true, element: <AgenticModelsList /> },
            {
              path: Paths.aiAgenticModelsCreate(),
              element: <AgenticModelsNew />,
              breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiAgenticModelsEdit(),
              element: <AgenticModelsEdit />,
              breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
            },
          ],
        },
      ],
    },
    {
      path: Paths.aiChannels(),
      element: <Channels />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('channels')}</Translation>,
      children: [
        { index: true, element: <ChannelsList /> },
        {
          path: Paths.aiChannelList(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject, params }) =>
                !!isHumanbotProject && !!params?.channel && params.channel !== 'voice'
              }
              redirectTo={Paths.aiChannels()}
            />
          ),
          children: [
            { index: true, element: <ChannelList /> },
            {
              path: Paths.aiChannelCreate(),
              element: <ChannelNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiChannelEdit(),
              element: <ChannelEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
      ],
    },
    {
      path: Paths.aiConfiguration(),
      element: <Configuration />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configuration')}</Translation>,
      children: [
        { index: true, element: <ConfigurationList /> },
        {
          path: Paths.aiDesign(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiConfiguration()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('design_va')}</Translation>,
          children: [
            { index: true, element: <ConfigsList /> },
            {
              path: Paths.aiDesignEdit(),
              element: <VirtualAssistant />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiFallbackBehaviour(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('fallback_behav')}</Translation>,
          children: [
            { index: true, element: <FallbackBehaviourList /> },
            {
              path: Paths.aiFallbackBehaviourCreate(),
              element: <FallbackBehaviourNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiFallbackBehaviourEdit(),
              element: <FallbackBehaviourEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiKnowledgeBases(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiConfiguration()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('knowledge_bases')}</Translation>,
          children: [
            { index: true, element: <KnowledgeBasesList /> },
            {
              path: Paths.aiKnowledgeBasesCreate(),
              element: <KnowledgeBasesNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiKnowledgeBasesEdit(),
              element: <KnowledgeBasesEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
            { path: Paths.aiKnowledgeBaseDocument(), breadcrumb: null },
            {
              path: Paths.aiKnowledgeBaseDocumentList(),
              element: <Outlet />,
              children: [
                { index: true, element: <KnowledgeBaseDocumentList /> },
                {
                  path: Paths.aiKnowledgeBaseDocumentNew(),
                  element: <KnowledgeBaseDocumentNew />,
                  breadcrumb: () => (
                    <Translation ns={'ai'}>{t => t('create_document')}</Translation>
                  ),
                },
                {
                  path: Paths.aiKnowledgeBaseDocumentEdit(),
                  element: <KnowledgeBaseDocumentEdit />,
                  breadcrumb: () => <Translation ns={'ai'}>{t => t('edit_document')}</Translation>,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: Paths.aiRouting(),
      element: <Routing />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('routing')}</Translation>,
      children: [
        { index: true, element: <RoutingList /> },
        {
          path: Paths.aiExternalEndpoints(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiRouting()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('external_endpoints')}</Translation>,
          children: [
            { index: true, element: <ExternalEndpointsList /> },
            {
              path: Paths.aiExternalEndpointsCreate(),
              element: <ExternalEndpointsNew />,
              breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiExternalEndpointsEdit(),
              element: <ExternalEndpointsEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiTeams(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.aiRouting()}
            />
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('teams')}</Translation>,
          children: [
            { index: true, element: <TeamsList /> },
            {
              path: Paths.aiTeamsCreate(),
              element: <TeamsNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiTeamsEdit(),
              element: <TeamsEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.aiRouters(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('routers')}</Translation>,
          children: [
            { index: true, element: <RoutersList /> },
            {
              path: Paths.aiRoutersCreate(),
              element: <RoutersNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.aiRoutersEdit(),
              element: <RoutersEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
      ],
    },
  ],
});

import React from 'react';
import { Select, SelectProps } from 'antd';
import { DFAgentType } from '../../pages/AI/Automation/NLUProviders/types';

type CXLocationType =
  | 'global'
  | 'europe-west1'
  | 'europe-west2'
  | 'europe-west3'
  | 'australia-southeast1'
  | 'asia-northeast1'
  | 'asia-southeast1'
  | 'asia-south1'
  | 'northamerica-northeast1'
  | 'us'
  | 'us-central1'
  | 'us-east1'
  | 'us-west1';

type ESLocationType =
  | 'global'
  | 'europe-west1'
  | 'europe-west2'
  | 'australia-southeast1'
  | 'asia-northeast1';

export const cxlocations: CXLocationType[] = [
  'global',
  'asia-northeast1',
  'asia-southeast1',
  'asia-south1',
  'australia-southeast1',
  'europe-west1',
  'europe-west2',
  'europe-west3',
  'northamerica-northeast1',
  'us',
  'us-central1',
  'us-east1',
  'us-west1',
];

export const eslocations: ESLocationType[] = [
  'global',
  'asia-northeast1',
  'australia-southeast1',
  'europe-west1',
  'europe-west2',
];

interface Props extends SelectProps {
  platform: Exclude<DFAgentType, 'HB'>;
  amazonLocations?: string[];
}

const getLocationOptions = (
  platformEdition: Exclude<DFAgentType, 'HB'>,
  amazonLocations?: string[],
): { label: string; value: string }[] => {
  const locationMap: Record<Exclude<DFAgentType, 'HB'>, string[]> = {
    ES: eslocations,
    CX: cxlocations,
    AgentSourceLex: amazonLocations || [],
  };
  const locationArray = locationMap[platformEdition] || [];
  return locationArray.map(item => ({ label: item, value: item }));
};

export const LocationSelect: React.FC<Props> = ({ platform, amazonLocations, ...rest }) => (
  <Select {...rest} options={getLocationOptions(platform, amazonLocations)} />
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends ThemeType {}
}

export const theme = {
  fontFamilyBase: 'Atyp BL Text, sans-serif',
  fontFamilyAccent: 'Atyp BL Display, sans-serif',
  fontFamilyAdditional: 'Roboto, sans-serif',
  textColor: '#333333',
  textColorDisabled: '#cfcce1',
  primaryColor: '#10006b',
  secondaryColor: '#f4d70e',
  secondaryBg: '#e2e0f0',
  lightBg: '#f2f2fa',
  errorColor: '#e65041',
  errorBGColor: '#f8cbc6',
  successColor: '#1bb21b',
  successBGColor: '#bce9bc',
  warningColor: '#d9bd00',
  warningBgColor: '#fdfae9',
  progressColor: '#e57d2e',
  progressBGColor: '#f2ddc2',
  draftColor: '#808080',
  draftBGColor: '#e6e6e6',
  loginLinkColor: '#9fc6f6',
  linkColor: '#009efc',
  lightBlue: '#9fc7f7',
  lightGreen: '#d2d49b',
  greenGraph: '#34b396',
  brownGraph: '#b97470',
  goldGraph: '#af9b46',
  blackGraph: '#1f1300',
  mistyGraph: '#a6a867',
  bgColor: '#fafaff',
  bgDark: 'rgba(0, 0, 0, 0.3)',
  primaryColor10: 'rgba(16, 0, 107, .1)',
  primaryColor5: 'rgba(16, 0, 107, .05)',
  successColor10: 'rgba(27, 178, 27, .1)',
  textColor50: 'rgba(51, 51, 51, .5)',
  futureColumn: 'rgba(230,230,230, 0.3)',
  iconColor: '#5e1bb3',
  infoAlertColor: '#e5f5fe',
  typography: {
    h1: {
      fontSize: '20px',
      lineHeight: '1.4',
    },
    h2: {
      fontSize: '28px',
      lineHeight: '1.28',
    },
    h3: {
      fontSize: '20px',
      lineHeight: '1.3',
    },
    bodyLarge: {
      fontSize: '16px',
      lineHeight: '1.5',
    },
    body: {
      fontSize: '14px',
      lineHeight: '1.4155',
    },
    bodySmall: {
      fontSize: '12px',
      lineHeight: '1.5',
    },
    bodyExtraSmall: {
      fontSize: '9px',
      lineHeight: '1.1',
    },
  },
  headerHeight: '64px',
  navWidth: '64px',
  inputHeight: '36px',
  labelFontSize: '13px',
  labelPadding: '0 0 4px',
  inputPaddingHorizontal: '16px',
  inputPlaceholderColor: '#b3b3b3',
  menuItemHeight: '36px',
  menuItemPadding: '0 20px',
  dropdownShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
  tableHeaderBg: '#f2f2fa',
  tableHeaderColor: '#333333',
  tableHeaderBorderColor: '#e2e0f0',
  tableColorDisabled: '#c8c7ca',
  paginationBg: 'transparent',
  paginationBgActive: '#e2e0f0',
  paginationHoverBg: '#f2f2fa',
  pickerCell: '36px',
  formItemMargin: '20px',
  orangeColor: '#FAB559',
  purpleColor: '#C773DE',
  lightPurpleColor: '#766bb3',
  greenColor: '#64daab',
  yellowColor: '#ffeb3b',
  grayCollor: '#9e9e9e',
  redCollor: '#f44336',
  markColor: '#9cd9fd',
  scrollColor: '#cfcce1',
  formWidth: '760px',
  subNavHeight: '35px',
  transitionFn: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
};

export type ThemeType = typeof theme;

export const formItemClass = {
  base: 'item-base',
  small: 'item-sm',
  xsmall: 'item-xsm',
  xxsmall: 'item-xxsm',
  full: 'item-full',
  hidden: 'item-hidden',
};

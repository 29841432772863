import { Form, FormItemProps, Input } from 'antd';
import { RuleType } from 'rc-field-form/lib/interface';
import { ReactComponent as MinusCircle } from '../../assets/icons/minus-circle.svg';
import { formItemClass, theme } from '../../theme';
import { ChannelType, ChannelTypes } from '../../pages/AI/Channels/Channel/types';
import { IncomingPhoneNumberSelector } from '../IncomingPhoneNumberSelector';
import { useAccount } from '../../state/account';
import { MinusContainer, StyledInputWithRemove } from './InputWithRemove.styles';

interface Props {
  placeholder: string;
  label: string;
  name: (string | number)[];
  onClick: () => void;
  onChange?: () => void;
  onBlur?: () => void;
  required?: boolean;
  tooltip?: string;
  channelType?: ChannelType;
  isShifted?: boolean;
  showRemove?: boolean;
  children?: React.ReactNode;
  validateTrigger?: FormItemProps['validateTrigger'];
}

export const InputWithRemove = ({
  placeholder,
  label,
  name,
  onClick,
  onChange,
  onBlur,
  required,
  tooltip,
  channelType,
  isShifted,
  showRemove = true,
  children,
  validateTrigger = 'onBlur',
}: Props) => {
  const { isHumanbotProject } = useAccount();
  const isPhoneNumberSelectorRequired =
    channelType &&
    [ChannelTypes.voice, ChannelTypes.sms, ChannelTypes.teams, ChannelTypes.whatsapp].includes(
      channelType as ChannelTypes,
    ) &&
    !isHumanbotProject;

  return (
    <StyledInputWithRemove>
      <Form.Item
        name={name}
        label={label}
        className={formItemClass.base}
        rules={[
          { required: required },
          ...(channelType === ChannelTypes.email ? [{ type: 'email' as RuleType }] : []),
        ]}
        validateTrigger={validateTrigger}
        tooltip={tooltip}
        {...(isShifted && { rootClassName: 'is-shifted-sm' })}
      >
        {children ? (
          children
        ) : isPhoneNumberSelectorRequired ? (
          <IncomingPhoneNumberSelector
            placeholder={placeholder}
            channelType={channelType}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : (
          <Input placeholder={placeholder} onChange={onChange} onBlur={onBlur} />
        )}
      </Form.Item>
      {showRemove && (
        <MinusContainer onClick={onClick} role="img" aria-label="minus">
          <MinusCircle color={theme.primaryColor} width={16} height={16} />
        </MinusContainer>
      )}
    </StyledInputWithRemove>
  );
};

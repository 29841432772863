import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IAutomationProfile,
  IAutomationProfileValues,
} from '../../pages/AI/Automation/AutomationProfiles/types';
import { IAiIntegrationUrl } from '../../state/integrations';
import { sortById } from '../../utils/sorting';

const queryKey = 'automation-profiles';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomationProfile[]> => {
  const response = await integrationQuery('/api/automation_profile_configs', {
    apiData,
    signal,
  });
  return sortById(response.automation_profile_configs);
};

const create = async ({
  data,
  apiData,
}: { data: IAutomationProfileValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/automation_profile_configs`, {
    method: 'POST',
    data: { data: { type: 'automation_profile_configs', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomationProfile> => {
  const response = await integrationQuery(`/api/automation_profile_configs/${id}`, {
    apiData,
    signal,
  });
  return response?.automation_profile_config;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IAutomationProfileValues>;
}): Promise<IAutomationProfile> => {
  return integrationQuery(`/api/automation_profile_configs/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'automation_profile_configs',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/automation_profile_configs/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const AutomationProfileService = {
  queryKey,
  get,
  create,
  find,
  update,
  remove,
};

import { useQuery } from '@tanstack/react-query';
import { useIntegrations } from '../../state/integrations';
import { TextToSpeechService } from '../../services/ai/text-to-speech';
import { AiIntegrationParams } from '../../services/api';

export const useTTSVoices = () => {
  const { aiIntegration } = useIntegrations();
  return useQuery(
    [TextToSpeechService.voiceQueryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      TextToSpeechService.getVoices({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
};

import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextToSpeechService } from '../../services/ai/text-to-speech';
import { AiIntegrationParams } from '../../services/api';
import { useIntegrations } from '../../state/integrations';
import { SelectWithFooter } from '../SelectWithFooter';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface Props extends SelectProps {
  openModal: () => void;
  locale?: string;
}

export const TextToSpeechSelector: React.FC<Props> = ({ openModal, locale, ...other }) => {
  const { t } = useTranslation('ai');
  const { aiIntegration } = useIntegrations();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data } = useQuery(
    [TextToSpeechService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      TextToSpeechService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const options = useMemo(() => {
    const source = locale ? data?.filter(item => locale === item.locale) : data;
    return source?.map(item => ({ value: item.id, label: item.name }));
  }, [data, locale]);

  const handleNewTTS = () => {
    setIsOpen(false);
    openModal();
  };

  const dropdownRender = (menu: React.ReactNode) => (
    <>
      <SelectWithFooter.Items maxHeight={150}>{menu}</SelectWithFooter.Items>
      <SelectWithFooter.Footer onClick={handleNewTTS}>
        <PlusIcon width={10} height={10} /> {t('new_tts_profile')}
      </SelectWithFooter.Footer>
    </>
  );

  return (
    <Select
      open={isOpen}
      showSearch
      optionFilterProp="label"
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      dropdownRender={dropdownRender}
      options={options}
      {...other}
    />
  );
};

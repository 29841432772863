import React from 'react';
import { Button, Form, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Space } from '../../../../../../components/Space';
import { UploadButton } from '../../../../../../components/UploadButton';
import { formItemClass } from '../../../../../../theme';
import { normalizeFile } from '../../../../../../utils/files';
import { ReactComponent as Trash } from '../../../../../../assets/icons/trash.svg';

type Props = { isHBProject?: boolean; initKeyfile?: string | null };

export const GoogleFields: React.FC<Props> = ({ isHBProject, initKeyfile }): JSX.Element => {
  const { t } = useTranslation(['ai', 'project_settings']);

  return (
    <Space>
      <Form.Item
        name={['tts_source_attributes', 'speaking_rate']}
        label={t('voice_speed')}
        className={formItemClass.base}
      >
        <Slider
          min={0.25}
          max={4.0}
          step={0.01}
          tooltip={{
            open: true,
            getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
          }}
        />
      </Form.Item>
      <Form.Item
        name={['tts_source_attributes', 'pitch']}
        label={t('pitch')}
        className={formItemClass.base}
      >
        <Slider
          min={-20}
          max={20}
          step={0.1}
          tooltip={{
            open: true,
            getPopupContainer: triggerNode => triggerNode.parentElement as HTMLElement,
          }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.tts_source_attributes.gcp_keyfile !==
          currentValues.tts_source_attributes.gcp_keyfile
        }
      >
        {({ getFieldValue, setFieldValue }) => {
          const gcpKey = getFieldValue(['tts_source_attributes', 'gcp_keyfile']);
          return (
            isHBProject &&
            (gcpKey && gcpKey === initKeyfile ? (
              <Form.Item
                label={t('gcp_keyfile', { ns: 'project_settings' })}
                tooltip={t('gcp_keyfile_tooltip', { ns: 'project_settings' })}
                className={formItemClass.base}
                required
              >
                <Button
                  type="default"
                  icon={<Trash width={13} height={14} />}
                  onClick={() => setFieldValue(['tts_source_attributes', 'gcp_keyfile'], null)}
                >
                  {t('remove_file', { ns: 'ai' })}
                </Button>
              </Form.Item>
            ) : (
              <Form.Item
                name={['tts_source_attributes', 'gcp_keyfile']}
                label={t('gcp_keyfile', { ns: 'project_settings' })}
                tooltip={t('gcp_keyfile_tooltip', { ns: 'project_settings' })}
                className={formItemClass.base}
                valuePropName="fileList"
                getValueFromEvent={normalizeFile}
                rules={[{ required: true }]}
              >
                <UploadButton accept=".json" />
              </Form.Item>
            ))
          );
        }}
      </Form.Item>
    </Space>
  );
};

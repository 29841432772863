import React from 'react';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { eslocations } from '../../../../../../components/LocationSelect';
import { IVoices } from '../../../TextToSpeech/types';

type Props = {
  isEditing?: boolean;
  isHBProject?: boolean;
  data?: IVoices;
};

export const PlatformEditionSwitch: React.FC<Props> = ({ isEditing, isHBProject, data }) => {
  const { t } = useTranslation('ai');
  const { setFieldsValue, getFieldValue } = Form.useFormInstance();
  const agentSourceType = getFieldValue('agent_source_type');
  const isRadioGroupDisabled = isEditing && agentSourceType !== 'CX' && agentSourceType !== 'ES';

  return (
    <Form.Item
      name="agent_source_type"
      label={t('platform_edition')}
      className={formItemClass.base}
    >
      <Radio.Group
        buttonStyle="solid"
        disabled={isRadioGroupDisabled}
        onChange={e => {
          const value = e.target.value;
          if (value === 'AgentSourceLex') {
            setFieldsValue({
              agent_source_type: value,
              agent_source: { location: data?.amazon.regions[0] },
            });
          } else {
            setFieldsValue({
              agent_source_type: value,
              agent_source: { location: eslocations[0] },
            });
          }
        }}
      >
        <Radio.Button value="ES">ES</Radio.Button>
        <Radio.Button value="CX">CX</Radio.Button>
        {isHBProject && (
          <>
            <Radio.Button disabled={isEditing} value="AgentSourceLex">
              LEX
            </Radio.Button>
            <Radio.Button disabled={isEditing} value="AgentSourceHumanbot">
              HBOT
            </Radio.Button>
          </>
        )}
      </Radio.Group>
    </Form.Item>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { NLUProvidersService } from '../../../../../services/ai/nlu-providers';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { ProviderTag } from '../../../../../components/ProviderTag';
import { Paths } from '../../../../../types/paths';
import { INLUAgent } from '../types';
import { convertPlatformName } from '../utils';

export const NLUProvidersList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () => navigate(Paths.aiNLUProvidersEdit({ id })),
  });

  const columns: ColumnProps<INLUAgent>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '63%',
    },
    {
      title: t('ccai_enabled'),
      onCell: onCellHandler,
      dataIndex: ['agent_source', 'ccai_enabled'],
      width: '17%',
      render: record => <IconIndicator enabled={record} />,
    },
    {
      title: t('platform_edition'),
      onCell: onCellHandler,
      dataIndex: 'agent_source_type',
      width: '17%',
      render: (_, record) => {
        const tag = convertPlatformName(record);
        return tag && <ProviderTag type={tag}>{tag}</ProviderTag>;
      },
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<INLUAgent>
      service={NLUProvidersService}
      columns={columns}
      getCreatePath={Paths.aiNLUProvidersCreate}
      deleteConfirmationTitle={t('delete_nlu_provider')}
      getDisplayName={record => record.name}
    />
  );
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/es/interface';
import { DataRetentionPolicyForm } from '../DataRetentionPolicyForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { IDataRetentionPolicyValues, IDataRetentionPolicy } from '../types';
import { DataRententionPolicyService } from '../../../../../services/ai/data-retention-policy';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { snakeToCapitalCase } from '../../../../../utils/string';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { SaveConfirmation } from '../../../../../components/SaveConfirmation';

export const DataRetentionPolicyEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IDataRetentionPolicy>(
    [DataRententionPolicyService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      DataRententionPolicyService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [DataRententionPolicyService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    DataRententionPolicyService.update,
  );

  const onSubmit = async (
    data: Partial<IDataRetentionPolicyValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: response => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('data_retention_policy_updated_notify', {
                    name: snakeToCapitalCase(response?.data_retention_policy.name),
                  })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IDataRetentionPolicyValues = {
    is_enabled: data.is_enabled,
    name: data.name,
    time_amount: data.time_amount,
    time_unit: data.time_unit,
    remove_from_twilio: data.remove_from_twilio,
  };

  return (
    <Content imgBg={false}>
      <DataRetentionPolicyForm
        initialValues={initialValues}
        submitButton={
          <SaveConfirmation onSubmit={onSubmit} loading={isEditLoading} isEdit>
            <Trans>
              {t(
                data.name === 'voice_recordings'
                  ? 'save_recording_confirmation'
                  : 'save_int_metrics_confirmation',
              )}
            </Trans>
          </SaveConfirmation>
        }
      />
    </Content>
  );
};

import React, { useState } from 'react';
import { Divider, Form, Radio, Space, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { IntegrationType } from '../../types';
import { AutomationConfig } from '../AutomationConfig';
import { ConversationSummary } from '../ConversationSummary';
import { AudioConfig } from '../AudioConfig';
import { VoiceRecordingConfig } from '../VoiceRecordingConfig';
import { DFAgentType } from '../../../NLUProviders/types';

interface Props {
  integrationType: IntegrationType;
  platform: DFAgentType;
  locale?: string;
}

export const DialPlanConfig = ({ integrationType, platform, locale }: Props): JSX.Element => {
  const { t } = useTranslation('ai');
  const [type, setType] = useState<IntegrationType>(integrationType);
  const [selectedLocales, setSelectedLocales] = useState<{ ES?: string; CX?: string }>({
    ES: platform === 'ES' ? locale : 'en-GB',
    CX: platform === 'CX' ? locale : 'en-GB',
  });

  const { setFieldValue, setFieldsValue } = Form.useFormInstance();

  const handleTypeChange = (e: RadioChangeEvent): void => {
    const val = e.target.value;
    setType(val);
    val === 'ccai'
      ? setFieldValue(['automation_profile_attributes', 'dialogflow_agent_id'], null)
      : setFieldValue(
          ['automation_profile_attributes', 'dialogflow_conversation_profile_id'],
          null,
        );
  };

  const handlePlatformChange = (e: RadioChangeEvent): void => {
    const val: 'ES' | 'CX' = e.target.value;
    setFieldsValue({
      automation_profile_attributes: {
        platform_edition: val,
        dialogflow_agent_id: null,
        locale: selectedLocales[val],
      },
    });
  };

  return (
    <>
      <Space>
        <Form.Item
          name={['automation_profile_attributes', 'integration_type']}
          label={t('integration_type')}
          className={formItemClass.base}
        >
          <Radio.Group buttonStyle="solid" onChange={handleTypeChange} value={type}>
            <Radio.Button value="standard">{t('standard')}</Radio.Button>
            <Radio.Button value="ccai">CCAI</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={['automation_profile_attributes', 'analyze_query_text_sentiment']}
          valuePropName="checked"
          label={t('sentiment_analysis')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Form.Item
        name={['automation_profile_attributes', 'platform_edition']}
        label={t('platform_edition')}
        className={formItemClass.base}
      >
        <Radio.Group buttonStyle="solid" onChange={handlePlatformChange} disabled={type === 'ccai'}>
          <Radio.Button value="ES">ES</Radio.Button>
          <Radio.Button value="CX">CX</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) =>
          prev.automation_profile_attributes?.platform_edition !==
          curr.automation_profile_attributes?.platform_edition
        }
      >
        {({ getFieldValue }) => {
          const currentPlatform = getFieldValue([
            'automation_profile_attributes',
            'platform_edition',
          ]);
          return (
            <AutomationConfig
              type={type}
              platform={currentPlatform}
              selectedLocales={selectedLocales}
            />
          );
        }}
      </Form.Item>
      <ConversationSummary />
      <Divider className={formItemClass.full} />
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) =>
          prev.automation_profile_attributes?.platform_edition !==
          curr.automation_profile_attributes?.platform_edition
        }
      >
        {({ getFieldValue }) => {
          const currentPlatform = getFieldValue([
            'automation_profile_attributes',
            'platform_edition',
          ]);
          return <AudioConfig platform={currentPlatform} setSelectedLocales={setSelectedLocales} />;
        }}
      </Form.Item>

      <VoiceRecordingConfig />
    </>
  );
};

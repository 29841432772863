import { useLocation } from 'react-router-dom';
import { ReportType } from '../../Reports/types';
import { AnalyticsService } from '../../../../services/analytics';
import { Paths } from '../../../../types/paths';

export const useCurrentReport = (): { type: ReportType; queryKey: string } => {
  const location = useLocation();

  const reportMap: Record<string, { type: ReportType; queryKey: string }> = {
    [Paths.analyticDashboards()]: {
      type: 'dashboards',
      queryKey: AnalyticsService.queryKeyDashboards,
    },
    [Paths.analyticLooks()]: {
      type: 'looks',
      queryKey: AnalyticsService.queryKeyLooks,
    },
    [Paths.analyticExplore()]: {
      type: 'explore',
      queryKey: AnalyticsService.queryKeyExplores,
    },
    [Paths.analyticFAReports()]: {
      type: 'fAReports',
      queryKey: AnalyticsService.queryKeyFAReports,
    },
  };

  return reportMap[location.pathname];
};

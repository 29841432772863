import { useFlags } from 'launchdarkly-react-client-sdk';
import { Divider, Form, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../../theme';
import { StyledFormItem } from '../../../../AI.styles';
import { Space } from '../../../../../../components/Space';

const { Title } = Typography;

export const ConversationSummary = (): JSX.Element => {
  const { t } = useTranslation('ai');
  const { openAiSummary, ccaiSummary } = useFlags();

  return (
    <>
      {(openAiSummary || ccaiSummary) && (
        <Space direction="vertical" size={0} style={{ marginBottom: 20 }}>
          <Divider className={formItemClass.full} />
          <Title level={5} type="secondary">
            {t('conversation_summary')}
          </Title>
          {openAiSummary && (
            <>
              <StyledFormItem
                name={['automation_profile_attributes', 'analytic_openai_summary_enabled']}
                valuePropName="checked"
                label={t('openai_analytic_summary')}
                tooltip={t('openai_analytic_summary_tooltip')}
              >
                <Switch />
              </StyledFormItem>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.automation_profile_attributes?.ccai_summary_enabled !==
                  currentValues.automation_profile_attributes?.ccai_summary_enabled
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <StyledFormItem
                      name={['automation_profile_attributes', 'openai_summary_enabled']}
                      valuePropName="checked"
                      label={t('openai_conversation_summary')}
                      tooltip={t('openai_conversation_summary_tooltip')}
                    >
                      <Switch
                        disabled={getFieldValue([
                          'automation_profile_attributes',
                          'ccai_summary_enabled',
                        ])}
                      />
                    </StyledFormItem>
                  );
                }}
              </Form.Item>
            </>
          )}
          {ccaiSummary && (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.automation_profile_attributes?.openai_summary_enabled !==
                currentValues.automation_profile_attributes?.openai_summary_enabled
              }
            >
              {({ getFieldValue }) => {
                return (
                  <StyledFormItem
                    name={['automation_profile_attributes', 'ccai_summary_enabled']}
                    valuePropName="checked"
                    label={t('openai_ccai_summary')}
                    tooltip={t('openai_ccai_summary_tooltip')}
                  >
                    <Switch
                      disabled={getFieldValue([
                        'automation_profile_attributes',
                        'openai_summary_enabled',
                      ])}
                    />
                  </StyledFormItem>
                );
              }}
            </Form.Item>
          )}
        </Space>
      )}
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import singleSourceImg from '../../../assets/images/single-source.png';
import brandsManagementImg from '../../../assets/images/brands-management.png';
import multiLanguageImg from '../../../assets/images/insights-glance.png';
import { Content } from '../../../components/Content';
import { StyledIntroCard } from '../../../components/StyledIntroCard';

export const KnowledgeIntro: React.FC = () => {
  const { t } = useTranslation('knowledge');

  return (
    <Content imgBg={false} header={false}>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, 20]}>
          <StyledIntroCard.MainSection
            img={singleSourceImg}
            title={t('intro_singlesource_title')}
            description={t('intro_singlesource_description')}
            maxHeight={354}
          />
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[0, 20]}>
          <Col span={24}>
            <h3>{t('intro_content_title')}</h3>
            <StyledIntroCard.Paragraph
              maxWidth={820}
              description={t('intro_content_description')}
            />
          </Col>
          <Col span={24}>
            <img
              alt={t('intro_content_title')}
              src={brandsManagementImg}
              style={{ maxHeight: 460 }}
            />
          </Col>
        </Row>
      </StyledIntroCard.Section>
      <StyledIntroCard.Section>
        <Row align="middle" gutter={[40, { xs: 20, sm: 20, md: 0 }]}>
          <StyledIntroCard.SubSection
            left
            img={multiLanguageImg}
            title={t('intro_multi_title')}
            description={t('intro_multi_description')}
            maxHeight={240}
          />
        </Row>
      </StyledIntroCard.Section>
    </Content>
  );
};
